import styled from 'styled-components';

export const MarginTop = styled.div`
margin-top: 50px;

`;
export const TextElement = styled.div`
margin-top: 20px;
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
overflow: hidden;
margin-bottom: 50px;
`;
export const Inline = styled.div` 
display: inline; 
`;
export const Section = styled.div`
width: 70%;
margin-left: 15%;
@media only screen and (max-width: 850px) {
    width: 95%;
    margin-left: 2.5%;
    /* text-align: left; */
}
@media only screen and (max-width: 520px) {
    display: block;
    text-align: center;
}
`;
export const Paragraph = styled.p`
font-size: 16px;
font-family: "Open Sans";
width: 50%;
/* background-color: red; */
margin-left: 25%;
word-break: break-word;
/* white-space: pre-line; */
/* display: flex; */
/* align-self: center; */
@media only screen and (max-width: 1200px) {
    width: 70%;
    margin-left: 15%;
}
@media only screen and (max-width: 1000px) {
    width: 90%;
    margin-left: 5%;
}
`;

export const Text = styled.p`
font-family: "Open Sans";
font-size: 17px; 
color: #57695a;
display: block; 
/* display: flex; */
flex-direction: row;
float: ${ props => { switch (props.align) { case 'left': { return 'left'; } case 'right': { return 'right'; } default: { return 'left'; } } }};
transition: color 0.2s;
:hover{
    color: #64A70A;
    cursor: pointer;
}
@media only screen and (max-width: 520px) {
    font-size: 14px;
    float: left;
    display: inline-block;
    width: 100%;
}
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
align-self: center;
width: 640px;
@media only screen and (max-width: 850px) {
    width: 400px;
    /* text-align: left; */
}
@media only screen and (max-width: 510px) {
    width: 350px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}

`;