import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
height: 120px;

@media only screen and (max-width: 1263px) {
    
    height: 95px;

}
@media only screen and (max-width: 850px) {
    height: 45px;
}
`;

class FixedSpacing extends React.Component {
    render() {
        return (
            <Container>

            </Container>
        )
    }
}

export default FixedSpacing;