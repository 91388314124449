import React from 'react';
import { Container, Section, Section2, Image, Title, Padding, Header, Paragraph, Hack } from './styled';
import Config from '../../config';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        text: state.language.about
    }
}

class AboutSection extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // alert(this.props.image);
    }
    render() {
        return (

            <Container reverse={this.props.reverse} >
                {
                    this.props.text ?
                        <>
                            <Hack>

                            </Hack>
                            <Section2 reverse={this.props.reverse} first>
                                <Image src={this.props.roundImage} />
                            </Section2>
                            <Section graySpace={true} reverse={this.props.reverse}>
                                <Padding>
                                    <Header>
                                        <Title reverse={this.props.reverse}>{this.props.title}</Title>
                                    </Header>
                                    <Paragraph small={true}>{this.props.words}</Paragraph>
                                </Padding>
                            </Section>
                            <Hack />
                        </>
                        :
                        <>
                        </>
                }
            </Container>

        );
    }
}

export default connect(mapStateToProps, null)(AboutSection);