import React from 'react';
import { Content, SubSection, Image, ImageContainer, TouchableOpacity, Paragraph } from './styled';
import AddElement from '../AddElement';

class ContentCreator extends React.Component {

    state = {
        hoverId: 0
    }

    handleChange(data) {
        this.props.handleChange(data);
    }

    newChange(object, index) {
        this.props.newChange(object, index);
    }
    setHoverId(hoverId) {
        this.setState({ hoverId });
    }
    resetHoverId() {
        this.setState({ hoverId: 0 });
    }

    returnType(element, index) {
        let result = (<div></div>);
        switch (element.type) {
            case 1:
                result = (
                    <div onMouseEnter={() => this.setHoverId(element.id)} onMouseLeave={() => this.resetHoverId()}>
                        <TouchableOpacity onClick={() => this.handleChange({ type: 1, text: element.text, index, id: element.id })}>
                            <Paragraph>
                                {
                                    element.text
                                }
                            </Paragraph>
                        </TouchableOpacity>
                        <AddElement
                            embeded={true}
                            hoverMagic={{ id: element.id, hoverId: this.state.hoverId }}
                            handleClick={object => this.newChange(object, index)}
                        />
                    </div>
                );
                break;
            case 2:
                result = (
                    <div onMouseEnter={() => this.setHoverId(element.id)} onMouseLeave={() => this.resetHoverId()}>
                        <TouchableOpacity onClick={() => this.handleChange({ type: 2, index, id: element.id })}>
                            <p>↑</p>
                            <p>↓</p>
                        </TouchableOpacity>
                        <AddElement
                            embeded={true}
                            hoverMagic={{ id: element.id, hoverId: this.state.hoverId }}
                            handleClick={object => this.newChange(object, index)}
                        />
                    </div>
                );
                break;
            case 3:
                result = (
                    <div onMouseEnter={() => this.setHoverId(element.id)} onMouseLeave={() => this.resetHoverId()}>
                        <SubSection onClick={() => this.handleChange({ type: 3, text: element.text, index, id: element.id })} >{element.text}</SubSection>
                        <AddElement
                            embeded={true}
                            hoverMagic={{ id: element.id, hoverId: this.state.hoverId }}
                            handleClick={object => this.newChange(object, index)}
                        />
                    </div>
                )
                break;
            case 4:
                result = (
                    <div onMouseEnter={() => this.setHoverId(element.id)} onMouseLeave={() => this.resetHoverId()}>
                        <ImageContainer>
                            <Image src={element.url} alt='' onClick={() => this.handleChange({ type: 4, url: element.url, index, id: element.id })} />
                            <br />
                            <br />
                        </ImageContainer>
                        <AddElement
                            embeded={true}
                            hoverMagic={{ id: element.id, hoverId: this.state.hoverId }}
                            handleClick={object => this.newChange(object, index)}
                        />
                    </div>
                )
                break;
            default:
                break;
        }
        return result;
    }

    render() {
        let result = (<Content></Content>);
        if (this.props.data) {
            result = (
                <Content>
                    <AddElement handleClick={object => this.newChange(object, -1)} />
                    {
                        this.props.data.map((element, index) => {
                            return this.returnType(element, index);
                        })
                    }
                </Content>);
        }
        return result;
    }
}

export default ContentCreator;