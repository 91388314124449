import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
margin-top: 150px;
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 30px;
overflow: hidden;
`;
const Image = styled.div`
margin-right: 15%;
border-radius: 10px;
display: flex;
align-self: flex-end;
width: auto;
height: auto;
color: #FFFFFF;
background-color: #64A70A;
padding-left: 30px;
padding-right: 30px;
padding-top: 15px;
padding-bottom: 15px;
font-size: 40px;
cursor: pointer;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}
@media only screen and (max-width: 850px) {
    margin-right: 15px;
}
`;

class ScrollToTop extends React.Component {

    render() {
        return (
            <Container onClick={() => this.props.onClick()}>
                <Image><i class="fas fa-long-arrow-alt-up"></i></Image>
            </Container>
        )
    }
}

export default ScrollToTop;