import React from 'react';
import { Container, Element } from './styled';
import Config from '../../config';
import ProductCard from '../ProductCard';
import ContactCard from '../ContactCard';
class ContactContent extends React.Component {
    render() {
        return (
            <Container>
                <Element>
                    <ContactCard
                        title={'ZA SRBIJU:'}
                        text={"''PHOENIX'' Beograd \n''VELEXFARM'' Beograd\n''SOPHARMA'' ﻿Beograd\n''MEDICOM'' Šabac\n''VEGA'' Valjevo\n''FARMALOGIST'' Beograd\n''NS PHARM'' Novi Sad\n''LIN'' Šabac"} />
                </Element>
                <Element>
                    <ContactCard
                        title={'ZA HRVATSKU:'}
                        text={"DARRER D.O.O. \nKrajiška 18\n 10000 Zagreb, Hrvatska \nTel. (+385) 91 5730 798"} />
                </Element>
                <Element>
                    <ContactCard
                        title={'ZA MAKEDONIJU:'}
                        text={"EVROPA LEK PHARMA DOOEL\n Jadranska Magistrala 31\n 1000 Skopje\n Теl. +389 2 278 60 74 \n* Trenutno se u Makedoniji može kupiti\n samo Femisan A"} />
                </Element>
                <Element>
                    <ContactCard
                        title={'ZA CRNU GORU:'}
                        text={"FARMEGRA D.O.O.\n Kritskog odreda 4/1\n 81110 Podgorica \nTel. (+382) 20 621 776"} />
                </Element>
                <Element>
                    <ContactCard
                        title={'ZA KOSOVO:'}
                        text={"EXCLUSIVE 1 Sh.p.k\n Veternik p.n\n 10000 Priština\n Tel. (+383) 44 153 850 "} />
                </Element>
                <Element>
                    <ContactCard
                        title={'ZA BIH:'}
                        text={"'ICM' D.O.O.\n Vojvode Putnika 12\n 76300 Bijeljina\n Tel. (+387) 55 209 799,\n (+387) 55 419 734"} />
                </Element>
            </Container>
        )
    }
}

export default ContactContent;