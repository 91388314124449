
import { createStore } from 'redux';
import { applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import languages from '../locales';

let initState = {
    language: languages.eng,
    scroll: false,
    firstTime: true
};


function setState(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {

    }
}
function loadState() {
    try {
        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {
            return initState;
        }
        else {
            let lang = JSON.parse(serializedState);
            let newState = lang;
            switch (lang.language.lang) {
                case 'en':
                    newState.language = languages.eng;
                    break;
                case 'de':
                    newState.language = languages.deu;
                    break;
                case 'sr':
                    newState.language = languages.srb;
                    break;
                default:
                    newState.language = languages.eng;
                    break;
            }
            return newState;
        }
    } catch (err) {
        return initState;
    }
}

let rootReducer = (state = loadState(), action) => {

    switch (action.type) {
        case 'LANGUAGE_CHANGE_ENGLISH':
            setState({ ...state, language: action.text });
            return { ...state, language: action.text };
            break;
        case 'LANGUAGE_CHANGE_SERBIAN':
            setState({ ...state, language: action.text });
            return { ...state, language: action.text };
            break;
        case 'LANGUAGE_CHANGE_GERMAN':
            setState({ ...state, language: action.text });
            return { ...state, language: action.text };
            break;
        case 'START_SCROLL':
            setState({ ...state, scroll: action.scroll })
            return { ...state, scroll: action.scroll };
            break;
        case 'END_SCROLL':
            setState({ ...state, scroll: action.scroll })
            return { ...state, scroll: action.scroll };
            break;
        case 'FIRST_TIME':
            setState({ ...state, firstTime: false })
            return { ...state, firstTime: false };
            break;
        case 'LOAD_PAGES_ID':
            setState({ ...state, pages: action.payload });
            alert(JSON.stringify(action.payload));
            return { ...state, pages: action.payload };
            break;
        case 'LOGIN_SUCCESS':
            setState({ ...state, adminToken: action.payload });
            window.location.replace('/#/admin/blog/1');
            return { ...state, adminToken: action.payload };
        case 'LOGIN_FAILED':
            setState({ ...state, adminToken: {} });
            return { ...state, adminToken: {} };
        default:
            return state;
            break;

    }
};


const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;