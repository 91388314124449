import React from 'react';
import { SubTitle, Title, FlexRow, LeftGreenText, RightGreenText } from './styled'
import { PageContainer, Inline } from '../../styles';
import ContentGenerator from '../../components/ContentGenerator';
import Button from '../../components/Button';
import { history } from '../../utils/index';
import Actions from '../../store/Actions';
import Config from '../../config';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import axios from 'axios';
import FixedSpacing from '../../components/FixedSpacing';
import ScrollToTop from '../../components/ScrollToTop';

import { connect } from 'react-redux';
// import actions from '../../store/actions'; 

const mockData = [
    { id: 0, type: 4, url: 'https://webmedicina.rs/wp-content/uploads/2015/11/Macina-trava.png' },
    { type: 3, text: 'Valerijana' },
    { type: 1, text: 'Valerijana je samonikla biljka koja voli visinu i sunce. U starom Rimu često je koristio Dioskurid iz Anzaraba, vojni lekar u službi Neronove vojske i vrsni poznavalac lekovitog bilja. U svom delu, De materia medica, opisao je načine primene preko 1000 prirodnih supstanci, a valerijanu je svrstao u grupu aromatičnog bilja. Ova zbirka smatra se fundamentom svih modernih farmakopeja. Dioskurid je poznat i po preteči anestetika, posebnom vinu koje je pravio od opijuma i mandragore kako bi uspavao pacijente pre operacije. Za to stanje nesvesti koristio je grčku reč anestezija, što je značilo ’bez osećaja’.' },
    { id: 0, type: 4, url: 'https://www.zenasamja.me/images/full/0825.jpg' },
    { type: 1, text: 'Valerijana ima sedativno, anksiolitičko, antidepresivno, antikonvulzivno i spazmolitičko dejstvo. Jednostavno rečeno, ublažava agresiju, tenzionu glavobolju, grčenje mišića, depresiju i teskobu. Valerijana je dobra i kod srčane aritmije i osećaja teskobe u grudima. Pomaže kod odvikavanja od pušenja, kao i kod išijasa, neuralgije, periferne neuropatije, nervoznog creva, grčeva u stomaku, menstrualnih bolova, osećaja uznemirenosti u menopauzi, pa čak i kod hipohondrije.' },


]

const test = { "id": 69, "url": "Mimovi", "date": "2020-07-03 05:27:04", "title": "Jako dobri mimovi", "description": "Umire mi se :)", "category": "seppuku", "lang": 0, "Content": [{ "id": 413415, "page_id": 69, "index": 1, "type": 1, "text": "yes" }, { "id": 413416, "page_id": 69, "index": 2, "type": 2, "text": "no" }] };

const mapStateToProps = (state) => {
    return {
        // articles: [...state.posts.articles, ...state.posts.announcements]
        data: mockData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // loadPage: async () => dispatch(await actions.Blog.loadPages())
    }
}

class Article extends React.Component {

    state = {
        loading: true,
        data: '',
        pages: {
            nextPage: -1,
            hasNext: false,
            prevPage: -1,
            hasPrev: false
        }
    }
    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    findPage(ids) {
        let thisId = this.props.match.params.id;
        let pages = {
            nextPage: thisId,
            hasNext: false,
            prevPage: thisId,
            hasPrev: false
        };
        for (let i = 0; i < ids.length; i++) {
            if (ids[i] == thisId) {
                if (i === 0 && i + 1 < ids.length) {
                    pages.hasNext = true;
                    pages.nextPage = ids[i + 1];
                }
                else {
                    if (i !== 0 && i + 1 < ids.length) {
                        pages.hasNext = true;
                        pages.nextPage = ids[i + 1];
                        pages.prevPage = ids[i - 1];
                        pages.hasPrev = true;
                    }
                    else {
                        pages.hasPrev = true;
                        pages.prevPage = ids[i - 1];
                    }
                }
            }
        }
        return pages;
    }

    componentDidUpdate(prevProps) {
        if (this.prevProps !== this.props && this.props.article) {
            this.setState({ loading: false });
        }
    }
    loadNext() {
        axios.get(Config.Api.blog_loadPagesId)
            .then((response) => {
                this.setState({ ...this.state, pages: this.findPage(response.data.ids) })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {

        axios.post(Config.Api.blog_load, {
            id: this.props.match.params.id
        })
            .then((response) => {
                let data = response.data;
                for (let i = 0; i < data.Content.length; i++) {
                    if (data.Content[i].type === 4) {
                        data.Content[i].url = data.Content[i].text;
                        delete data.Content[i].text;
                    }
                }
                this.setState({ ...this.state, data });
                this.loadNext();
            })
            .catch((error) => {
                // alert(error);
            })
            ;

        this.setState({ loading: false });
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer regular scroll>
                <FixedSpacing />
                <div ref={this.topRef} />
                <FlexRow >
                    <div style={{ width: '100%', display: 'inline' }}>
                        {this.state.pages.hasPrev ?
                            <LeftGreenText onClick={() => { window.location.replace('/#/blog/article/' + this.state.pages.prevPage); window.location.reload(); }}>{'< Predhodni članak'} </LeftGreenText>
                            :
                            <></>
                        }
                        {this.state.pages.hasNext ?
                            <RightGreenText onClick={() => { window.location.replace('/#/blog/article/' + this.state.pages.nextPage); window.location.reload(); }}>{'Sledeći članak >'}</RightGreenText>
                            :
                            <></>
                        }
                    </div>
                </FlexRow >
                {
                    !this.state.loading || this.state.data !== '' ?

                        <>
                            <Title>
                                {this.state.data.title}
                            </Title>
                            <SubTitle>
                                {this.state.data.date}
                            </SubTitle>
                            <SubTitle>
                                {this.state.data.category}
                            </SubTitle>
                            <ContentGenerator data={this.state.data.Content} />
                        </>
                        :
                        <></>
                }

                <FlexRow >
                    <div style={{ width: '100%', display: 'inline' }}>
                        {this.state.pages.hasPrev ?
                            <LeftGreenText onClick={() => { window.location.replace('/#/blog/article/' + this.state.pages.prevPage); window.location.reload(); }}>{'< Predhodni članak'} </LeftGreenText>
                            :
                            <></>
                        }
                        {this.state.pages.hasNext ?
                            <RightGreenText onClick={() => { window.location.replace('/#/blog/article/' + this.state.pages.nextPage); window.location.reload(); }}>{'Sledeći članak >'}</RightGreenText>
                            :
                            <></>
                        }
                    </div>
                </FlexRow >
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);