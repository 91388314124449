import React from 'react';
import { Container, Line, Row, Question, RowReveal, Answer, Arrow, SubRow } from './styled';
import Config from '../../config';

class QASection extends React.PureComponent {
    state = {
        active: false,
        selectedId: [],
    }

    handleClick(index) {
        let newSelectedId = [];
        if (this.state.selectedId.includes(index)) {
            for (let i = 0; i < this.state.selectedId.length; i++) {
                if (index !== this.state.selectedId[i]) {
                    newSelectedId.push(this.state.selectedId[i]);
                }
            }
        }
        else {
            newSelectedId.push(index);
        }
        this.setState({ ...this.state, selectedId: newSelectedId });
    }
    render() {
        return (
            <Container>
                {
                    this.props.questions ?
                        <>
                            {
                                this.props.questions.map((element, index) => {
                                    return (
                                        <Row>
                                            <Line />
                                            <SubRow onClick={() => this.handleClick(index)} className='row_selector'>
                                                <Question>{this.props.lang === 'sr' ? element.title_sr : element.title_en}</Question>
                                                <Arrow src={this.state.selectedId.includes(index) ? Config.images.icon.ArrowTopGreen : Config.images.icon.ArrowBottomGreen} />
                                            </SubRow>
                                            <RowReveal active={this.state.selectedId.includes(index)}>
                                                <Answer>{this.props.lang === 'sr' ? element.text_sr : element.text_en}</Answer>
                                            </RowReveal>
                                        </Row>
                                    );
                                })
                            }
                        </>
                        :
                        <>
                        </>
                }
            </Container >
        )
    }
}

export default QASection;