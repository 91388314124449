import React from 'react';
import {
    Container,
    FlexContainer,
    ProductImage,
    ImageView,
    Title,
    TextSection,
    SubTitle,
    Paragraph,
    Bottom,
} from './styled';
import { GreenText } from '../_components'
import Button from '../Button';
import { shortenString } from '../../utils';

class BlogCard extends React.PureComponent {
    state = {
        adminMode: false,
    }
    handleRead() {
        if (this.state.adminMode) {
            window.location.replace('/#/admin/blog/article/' + this.props.index);
        }
        else {
            window.location.replace('/#/blog/article/' + this.props.index);
        }
    }
    componentDidMount() {
        let url = window.location.href;
        if (url.includes('admin')) {
            this.setState({ ...this.state, adminMode: true })
        }
    }

    render() {
        return (
            <Container admin={this.state.adminMode} onClick={() => this.state.adminMode ? this.handleRead() : this.handleRead()}>
                <FlexContainer>
                    <ImageView onClick={() => this.handleRead()}>
                        <ProductImage src={this.props.productImage} />
                    </ImageView>
                    <TextSection>
                        <SubTitle>{this.props.category}</SubTitle>
                        <Title>{this.props.productName}</Title>
                        <Paragraph>{shortenString(this.props.productDescription, 200) + '...'}</Paragraph>
                    </TextSection>
                </FlexContainer>
            </Container>
        );
    }
}

export default BlogCard;