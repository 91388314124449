import femisan_a_large_image from '../assets/products/large_image/femisan-a-kapi-desktop.jpg';
import femisan_a_kapi_large_image from '../assets/products/large_image/femisan-a-kapsule-desktop.jpg';
import nefrovit_large_image from '../assets/products/large_image/nefrovit-kapi-desktop.jpg';
import alfaaktiv_large_image from '../assets/products/large_image/alfaaktiv-desktop.jpg';
import femisan_b_large_image from '../assets/products/large_image/femisan-b-kapi-desktop.jpg';
// mobile
import femisan_a_large_image_mobile from '../assets/products/large_image/femisan-a-kapi-mobilni.jpg';
import femisan_a_kapi_large_image_mobile from '../assets/products/large_image/femisan-a-kapsule-mobilni.jpg';
import nefrovit_large_image_mobile from '../assets/products/large_image/nefrovit-kapi-mobilni.jpg';
import alfaaktiv_large_image_mobile from '../assets/products/large_image/alfaaktiv-mobilni.jpg';
import femisan_b_large_image_mobile from '../assets/products/large_image/femisan-b-kapi-mobilni.jpg';



import ArrowLeft from '../assets/icon/arrow_left.png';
import ArrowRight from '../assets/icon/arrow_right.png';
import ArrowLeftGreen from '../assets/icon/arrow_left_green.png';
import ArrowRightGreen from '../assets/icon/arrow_right_green.png';
import ArrowTopGreen from '../assets/icon/arrow_top_green.png';
import ArrowBottomGreen from '../assets/icon/arrow_bottom_green.png';
import Facebook from '../assets/logo/social-media/facebook.png';
import Instagram from '../assets/logo/social-media/instagram.png';
import Youtube from '../assets/logo/social-media/youtube.png';
import Download from '../assets/icon/download.png';
import PhoneIcon from '../assets/icon/phone.png';
import Plus from '../assets/icon/plus.png';

import MobileMenu from '../assets/icon/MobileMenu.png';
import MobileLogo from '../assets/logo/logo_mobile.png';


import About_Image_01 from '../assets/pages/about/image1.png';
import About_Image_02 from '../assets/pages/about/image2.png';
import About_Image_03 from '../assets/pages/about/image3.png';
import About_Image_04 from '../assets/pages/about/image4.png';
import About_Image_05 from '../assets/pages/about/image5.png';
import About_Image_06 from '../assets/pages/about/image6.png';

import Certificate_Trophy from '../assets/pages/certificate/trophy.png';
import Certificate_ISO from '../assets/pages/certificate/iso.png';
import Certificate_stand_cert from '../assets/pages/certificate/stand-cert.png';
import Certificate_aaa from '../assets/pages/certificate/aaa.png';
import Certificate_excelent from '../assets/pages/certificate/excellent.png';
import Certificate_fda from '../assets/pages/certificate/fda.png';
import Certificate_haccp from '../assets/pages/certificate/haccp.png';
import Certificate_halal from '../assets/pages/certificate/halal.png';

import about_background from '../assets/pages/about/background.png';
import herbs_background from '../assets/pages/herbs/background.png';
import product_background from '../assets/pages/product/background.png';
import certificate_background from '../assets/pages/certificate/background.png';
import QA_background from '../assets/pages/QA/background.png';
import blog_background from '../assets/pages/blog/background.png';
import contact_background from '../assets/pages/contact/background.png';


// herbs import ////////////////////////////////////////////////////////////////////////////////
import virak from '../assets/herbs/virak.jpg';
import hajducka_trava from '../assets/herbs/hajducka-trava.jpg';
import crveni_zdravac from '../assets/herbs/crveni-zdravac.jpg';
import bela_imela from '../assets/herbs/bela-imela.jpg';
import maticnjak from '../assets/herbs/maticnjak.jpg';
import neven from '../assets/herbs/neven.jpg';
import srdacica from '../assets/herbs/srdacica.jpg';
import cestoslavica from '../assets/herbs/cestoslavica.jpg';
import koren_maslacka from '../assets/herbs/maslacak.jpg';
import rastavic from '../assets/herbs/rastavic.jpg';
import bokvica from '../assets/herbs/bokvica.jpg';
import kopriva from '../assets/herbs/kopriva.jpg';
import valerijana from '../assets/herbs/valerijana.jpg';
import andjelika from '../assets/herbs/andjelika.jpg';
import zlatna_maka from '../assets/herbs/zlatna-maka.jpg';
import damjana from '../assets/herbs/damjana.jpg';
import cileanska_ruza from '../assets/herbs/cileanska-ruza.jpg';
import avokado from '../assets/herbs/avokado.jpg';
import jojoba from '../assets/herbs/jojoba.jpg';
import ravensara from '../assets/herbs/ravensara.jpg';
import cajevac from '../assets/herbs/cajevac.jpg';
import hmelj from '../assets/herbs/hmelj.jpg';
import maline from '../assets/herbs/malina.jpg';
import zlatnica from '../assets/herbs/zlatnica.jpg';

///////
import virak_main from '../assets/herbs/main/virak.png';
///Products import ////////////////////////////////////////////////////////////////////////////
import femisan_a_kapi from '../assets/products/femisan_A_kapi.png';
import femisan_b_kapi from '../assets/products/femisan_B_kapi.png';
import femisan_a_kapsule from '../assets/products/femisan_A_kapule_04.png';
import alfa_aktiv from '../assets/products/alfaaktiv_kapsule.png';
import nefrovit from '../assets/products/nefrovit_kapi.png';
import leocardin from '../assets/products/leocardin_kapi.png';
import hipoprostat from '../assets/products/hipoprostat_kapi.png';
import equigal from '../assets/products/equigal_kapi.png';
import optima_forma from '../assets/products/optima_forma_kapi.png';
import disan from '../assets/products/disan_kapi.png';
import devi_peta from '../assets/products/DEVI_pete_2-3.png';
import devi_koza from '../assets/products/DEVI_KOZA_2-3.png';
import devi_strija from '../assets/products/DEVI_strije_2-3.png';
import devi_bradavica from '../assets/products/DEVI_bradavice_2-3.png';
import devi_grudi from '../assets/products/DEVI-GRUDI 1.png';
//////
import femisan_a_kapi_2 from '../assets/products/femisan_A_kapi.png';
import femisan_b_kapi_2 from '../assets/products/second_image/femisan_b.jpg';
import femisan_a_kapsule_2 from '../assets/products/second_image/femisan-a-kapsule.jpg';
import alfa_aktiv_2 from '../assets/products/second_image/alfaaktiv.jpg';
import nefrovit_2 from '../assets/products/second_image/nevrovit.jpg';
import leocardin_2 from '../assets/products/second_image/leokardin.jpg';
import hipoprostat_2 from '../assets/products/second_image/hipoprostat.jpg';
import equigal_2 from '../assets/products/second_image/equigal.jpg';
import optima_forma_2 from '../assets/products/second_image/optima-forma.jpg';
import disan_2 from '../assets/products/second_image/disan.jpg';
import devi_peta_2 from '../assets/products/second_image/devi-pete.jpg';
import devi_koza_2 from '../assets/products/second_image/devi-koza.jpg';
import devi_strija_2 from '../assets/products/second_image/devi-strije.jpg';
import devi_bradavica_2 from '../assets/products/second_image/devi-bradavice.jpg';
import devi_grudi_2 from '../assets/products/second_image/devi-grudi.jpg';
//////
import femisan_a_kapi_3 from '../assets/products/third_image/femisan-a-2.jpg';
import femisan_b_kapi_3 from '../assets/products/third_image/femisan-b-2.jpg';
import femisan_a_kapsule_3 from '../assets/products/third_image/femisan-a-kapsule-2.jpg';
import alfa_aktiv_3 from '../assets/products/third_image/alfaaktiv-2.jpg';
import nefrovit_3 from '../assets/products/third_image/nefrovit-2.jpg';
import leocardin_3 from '../assets/products/third_image/leokardin-2.jpg';
import hipoprostat_3 from '../assets/products/third_image/hipoprostat-2.jpg';
import equigal_3 from '../assets/products/third_image/equigal-2.jpg';
import optima_forma_3 from '../assets/products/third_image/optima-forma-2.jpg';
import disan_3 from '../assets/products/third_image/disan-2.jpg';
import devi_peta_3 from '../assets/products/third_image/devi-melem-za-pete.jpg';
import devi_koza_3 from '../assets/products/third_image/devi-melem-za-regeneraciju-koze.jpg';
import devi_strija_3 from '../assets/products/third_image/devi-protiv-strija-2.jpg';
import devi_bradavica_3 from '../assets/products/third_image/devi-melem-protiv-bradavica.jpg';
import devi_grudi_3 from '../assets/products/third_image/devi-melem-za-dekolte.jpg';
///////////////////////////////////////////////////////////////////////////////////////////////
import nikolina_crnogorac from '../assets/other/review/nikolina-crnogorac.png';
import snezana_review from '../assets/other/review/snezana.jpg';
import gordana_review from '../assets/other/review/gordana.jpg';
import marija_review from '../assets/other/review/marija.jpg';
import tomas_voz from '../assets/other/review/sandra.jpg';
import suzana_review from '../assets/other/review/suzana.jpg';
////////////////////////////////////////////////////////////////
import aaa_2017 from '../assets/pages/certificate/troph/aaa-2017.jpg';
import aaa_2018 from '../assets/pages/certificate/troph/aaa-2018.jpg';
import aaa_2019 from '../assets/pages/certificate/troph/aaa-2019.jpg';
import aaa_herba_svet from '../assets/pages/certificate/troph/aaa-herba-svet.jpg';
import balkan_awards_2018 from '../assets/pages/certificate/troph/balkan-awards-2018.jpg';
import balkan_awards_2020 from '../assets/pages/certificate/troph/balkan-awards-2020.jpg';
import beogradski_pobednik_2018 from '../assets/pages/certificate/troph/beogradski-pobednik-2018.jpg';
import medalja_2 from '../assets/pages/certificate/troph/medalja-2.jpg';
import medalja_3 from '../assets/pages/certificate/troph/medalja-3.jpg';
import medalja_4 from '../assets/pages/certificate/troph/medalja-4.jpg';
import medalja_6 from '../assets/pages/certificate/troph/medalja-6.jpg';
import medalja_7 from '../assets/pages/certificate/troph/medalja-7.jpg';
import medalja_novosadski_sajam from '../assets/pages/certificate/troph/medalja-novosadski-sajam-1.jpg';
import medalje_herbasvet from '../assets/pages/certificate/troph/medalje-herbasvet.jpg';
import novosadski_sajam_2007 from '../assets/pages/certificate/troph/novosadski-sajam-2007.jpg';
import novosadski_sajam_2009 from '../assets/pages/certificate/troph/novosadski-sajam-2009.jpg';
import novosadski_sajam_2010 from '../assets/pages/certificate/troph/novosadski-sajam-2010.jpg';
import novosadski_sajam_2011 from '../assets/pages/certificate/troph/novosadski-sajam-2011.jpg';
import novosadski_sajam_2018 from '../assets/pages/certificate/troph/novosadski-sajam-2018.jpg';
import prvi_sokar_srb from '../assets/pages/certificate/troph/prvi-oskar-srbije-2019.jpg';



const images = {
    navigation: {
        PhoneIcon
    },
    home: {
        mobile: {
            logo: MobileLogo,
        }
    },
    about: {
        background: about_background,
        image1: About_Image_01,
        image2: About_Image_02,
        image3: About_Image_03,
        image4: About_Image_04,
        image5: About_Image_05,
        image6: About_Image_06,
        people: {
            nikolina_crnogorac,
            snezana: snezana_review,
            gordana: gordana_review,
            marija: marija_review,
            sandra: tomas_voz,
            suzana: suzana_review,
        }
    },
    herbs: {
        background: herbs_background,
    },
    product: {
        background: product_background
    },
    certificate: {
        background: certificate_background,
        trophy: Certificate_Trophy,
        iso: Certificate_ISO,
        stand: Certificate_stand_cert,
        aaa: Certificate_aaa,
        excellent: Certificate_excelent,
        fda: Certificate_fda,
        haccp: Certificate_haccp,
        halal: Certificate_halal,
        trophy: [
            aaa_2017,
            aaa_2018,
            aaa_2019,
            aaa_herba_svet,
            balkan_awards_2018,
            balkan_awards_2020,
            beogradski_pobednik_2018,
            medalja_2,
            medalja_3,
            medalja_4,
            medalja_6,
            medalja_7,
            medalja_novosadski_sajam,
            medalje_herbasvet,
            novosadski_sajam_2007,
            novosadski_sajam_2009,
            novosadski_sajam_2010,
            novosadski_sajam_2011,
            novosadski_sajam_2018,
            prvi_sokar_srb,
        ],
    },

    QA: {
        background: QA_background,
    },
    blog: {
        background: blog_background,
    },
    contact: {
        background: contact_background,
    },
    localdata: {
        herbs: {
            icon: {
                virak,
                hajducka_trava,
                crveni_zdravac,
                bela_imela,
                maticnjak,
                neven,
                srdacica,
                cestoslavica,
                koren_maslacka,
                rastavic,
                bokvica,
                kopriva,
                valerijana,
                andjelika,
                zlatna_maka,
                damjana,
                cileanska_ruza,
                avokado,
                jojoba,
                ravensara,
                cajevac,
                hmelj,
                maline,
                zlatnica,
            },
            main: {
                virak: virak_main,
            }
        },
        products: {
            icon: {
                femisan_a_kapi,
                femisan_b_kapi,
                femisan_a_kapsule,
                alfa_aktiv,
                nefrovit,
                leocardin,
                hipoprostat,
                equigal,
                optima_forma,
                disan,
                devi_bradavica,
                devi_koza,
                devi_grudi,
                devi_strija,
                devi_peta
            },
            large_image: {
                dekstop: {
                    femisan_a_kapi: femisan_a_large_image,
                    femisan_b_kapi: femisan_b_large_image,
                    femisan_a_kapsule: femisan_a_kapi_large_image,
                    alfa_aktiv: alfaaktiv_large_image,
                    nefrovit: nefrovit_large_image,
                },
                mobile: {
                    femisan_a_kapi: femisan_a_large_image_mobile,
                    femisan_b_kapi: femisan_b_large_image_mobile,
                    femisan_a_kapsule: femisan_a_kapi_large_image_mobile,
                    alfa_aktiv: alfaaktiv_large_image_mobile,
                    nefrovit: nefrovit_large_image_mobile,
                },
            },
            second_image: {
                femisan_a_kapi_2: product_background,
                femisan_b_kapi_2,
                femisan_a_kapsule_2,
                alfa_aktiv_2,
                nefrovit_2,
                leocardin_2,
                hipoprostat_2,
                equigal_2,
                optima_forma_2,
                disan_2,
                devi_bradavica_2,
                devi_koza_2,
                devi_grudi_2,
                devi_strija_2,
                devi_peta_2
            },
            third_image: {
                femisan_a_kapi_3,
                femisan_b_kapi_3,
                femisan_a_kapsule_3,
                alfa_aktiv_3,
                nefrovit_3,
                leocardin_3,
                hipoprostat_3,
                equigal_3,
                optima_forma_3,
                disan_3,
                devi_bradavica_3,
                devi_koza_3,
                devi_grudi_3,
                devi_strija_3,
                devi_peta_3
            }
        }
    },
    icon: {
        Mobile: {
            MobileMenu,
        },
        ArrowLeft,
        ArrowRight,
        ArrowTopGreen,
        ArrowBottomGreen,
        ArrowLeftGreen,
        ArrowRightGreen,
        SocialMedia: {
            Facebook,
            Youtube,
            Instagram,
        },
        Download,
        Plus
    },
}

export default images;