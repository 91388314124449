import React from 'react';
import { Container, Element } from './styled';
import Config from '../../config';
import ProductCard from '../ProductCard'
class ProductContent extends React.Component {
    render() {
        return (
            <Container>
                {
                    Config.localdata.products.map((element, index) => {
                        return (
                            <Element>
                                <ProductCard
                                    productImage={
                                        Config.localdata.products[index].product_image
                                    }
                                    productName={
                                        this.props.lang === 'sr' ?
                                            Config.localdata.products[index].name_sr
                                            :
                                            Config.localdata.products[index].name_en
                                    }
                                    productDescription={
                                        this.props.lang === 'sr' ?
                                            Config.localdata.products[index].shortDescription_sr
                                            :
                                            Config.localdata.products[index].shortDescription_en
                                    }
                                    productSubtitle={
                                        this.props.lang === 'sr' ?
                                            Config.localdata.products[index].subtitle_sr
                                            :
                                            Config.localdata.products[index].subtitle_en
                                    }
                                    lang={this.props.lang}
                                    index={index}
                                />
                            </Element>
                        );
                    })
                }
            </Container>
        )
    }
}

export default ProductContent;