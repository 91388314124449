import images from '../config';

const eng = {
    lang: 'en',
    version: 2,
    navigation: {
        home: 'HOME',
        about: 'ABOUT',
        plants: 'OUR HERBS',
        products: 'PRODUCTS',
        certificate: 'CERTIFICATE',
        questions: 'QUESTIONS',
        blog: 'BLOG',
        contact: 'CONTACT',
        english: 'EN',
        serbian: 'SR',
        phone: '+381 11 239 00 86',
    },
    about: {
        partners_title: 'IZJAVE KORISNIKA',
        partners_content: [
            {
                name: 'Nikolina Crnogorac',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
            {
                name: 'Nikolina Crnogorac 2',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
            {
                name: 'Nikolina Crnogorac 3',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
            {
                name: 'Nikolina Crnogorac 4',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
            {
                name: 'Nikolina Crnogorac 5',
                title: 'Export Department\nHermann Pfanner Getränke GmbH',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
        ]
    },
    certificate: {
        awards: '',
        chronology: '1996. Osnovan „Herba Svet D.O.O.“ \n2004 - 2010. Osvojene ukupno 21 zlatne medalje za kvalitet proizvoda \n2004. FemiSan A je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja \n2005. Nefrovit je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja \n2006. Prelazak u novi proizvodni prostor n\ 2007. Specijalna povelja za visok kvalitet proizvoda (Sajam ekologije, NS) \n2009. Prvi zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2010. Veliki šampionski zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2011. Treći zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2013. Sertifikacija Sistema upravljanja kvalitetom: ISO 9001:2008 i HACCP \n2014. Excellent SME sertifikat Privredne komore Srbije \n2014. Finalisti izbora „Najbolje iz Srbije“ PKS \n2014. Zahvalnica Humanitarne fondacije Novi Beograd „Budi human“ \n2015. „Vip BizPartner 2014“ za najbolju porodičnu firmu Privredna komora Srbije \n2015. Sertifikat A za izvrstan bonitet poslovanja - BISNODE SRBIJA \n2015, 2016, 2017, 2018, 2019. Sertifikat AAA za izvrstan bonitet poslovanja - BISNODE SRBIJA \n2015, 2016, 2017, 2018, 2019. Sertifikat Excellent SME za izvrsno poslovanje - Privredna komora Srbije \n2018. FEMISAN A - Balkan Awards za najbolji regionalni brend u 2018. godini \n2018. Beogradski Pobednik Herba Svetu kao uspešnoj kompaniji \n2018. Veliki zlatni pehar Novosadskog Sajma za kvalitet proizvoda',
        certificate: "",

    },
    QA: {
        questions: [
            {
                title_sr: "Koliko dugo smem da koristim vaše preparate?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
            {
                title_sr: "Da li smem da koristim vaše preparate iako već koristim terapiju koju mi je lekar prepisao?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
            {
                title_sr: "Da li vaše preparate mogu da koriste dijabetičari?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
            {
                title_sr: "Kada će se videti prvi rezultati?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
            {
                title_sr: "Da li mogu da koristim terapiju preventivno?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
        ]
    },
    contact: {
        orangeBox: 'WHEN TRANSPORT\n IS REQUIRED,\n CONTACT MEGGA',
        title: 'CONTACT',
        name: 'Name and surname',
        email: 'E mail',
        message: 'Message',
        send: 'SEND',
        info: "Megga doo\nVojvode Mišića 7\nPožega 31210\n\nE-mail: office@megga.pro",
        telephone: "Phone: +381 31 715 690\n     +381 31 715 729",
        success: 'Your message was sent successfully!',
        close: 'Close',
        failed: 'Failed to send a message',
        captcha: 'Incorrect captcha',
        messageError: 'Message is to short',
        nameError: 'The name field cannot be left empty',
        emailError: 'Please include an @ in the email address',
        textError: 'Text should be minimum 50 characters',
    },
    trademark: {
        text: '© 2020 HERBASVET DOO. All rights reserved.\n',
        sponsors: 'www.femisan.info www.devi.rs www.alfaaktiv.rs ',
    },
    gdpr: {
        cookies: "COOKIE NOTIFICATION",
        privacy: "PRIVACY POLICY",
    },
}

export default eng;

