import React from 'react';
import { Container, Column, InlineIcon, Icon } from './styled';
import Config from '../../config';
import { connect } from 'react-redux';

const mapDispatchToProps = (state) => {
    return {
        lang: state.language.lang
    }
}
class CertificateList extends React.Component {


    render() {
        return (
            <Container>
                <Column>
                    <InlineIcon>
                        <Icon src={Config.images.certificate.iso} />
                        <Icon src={Config.images.certificate.stand} />
                        <Icon src={Config.images.certificate.aaa} />
                        <Icon src={Config.images.certificate.excellent} />
                        <Icon src={Config.images.certificate.fda} />
                        <Icon src={Config.images.certificate.haccp} />
                        <Icon src={Config.images.certificate.halal} />

                    </InlineIcon>
                </Column>
            </Container>
        )
    }
}

export default connect(mapDispatchToProps, null)(CertificateList);