import styled from 'styled-components';
import Config from '../../config';

export const Container = styled.div`
margin-top: 30px;
width: 100%;
display: flex;
flex-direction: column;
align-self: center;
background-color: #005B30;
padding-top: 90px;
padding-bottom: 90px;
`;
export const Column = styled.div`
display: flex;
flex-direction: column; 
align-self: center;
width: auto;
min-height: 40px;
justify-content: space-between;
@media only screen and (max-width: 850px) {
    margin-top: 20px;
    width: 90px;
}
@media only screen and (max-width: 510px) {
    width: 90px;
}
@media only screen and (max-width: 400px) {
    width: 90px;
}
`;

export const InlineIcon = styled.div`
/* display: flex; */
/* flex-direction: column;
flex-wrap: wrap;
overflow: hidden; */
@media only screen and (max-width: 1250px) {
    margin-top: 0px;
}
@media only screen and (max-width: 850px) {
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;

export const Icon = styled.div`
width: 100px;
height: 100px;
border-radius: 0px;
display: inline-block;
/* display: flex; */
/* flex-direction: row; */
margin-right: 35px;
background-image: url(${props => props.src});
background-size: 100% 100%;
background-repeat: no-repeat;
/* -webkit-transition: background-image 0.3s ease-in-out; */
/* transition: background-image 0.3s ease-in-out; */
:hover{
  /* background-image: url(${Config.images.icon.Download}); */
  cursor: pointer;
}
@media only screen and (max-width: 1400px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 850px) {
width: 150px;
height: 150px;
margin-top: 50px;
margin-right: 0px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;