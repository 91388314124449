import React from 'react';
import MainSection from '../MainSectionClassic';
import { InputField, Border, TextArea } from './styled';
import { connect } from 'react-redux';
import Button from '../Button';
import { Paragraph, RedText } from '../../styles';
import ReCAPTCHA from 'react-google-recaptcha';
import EmailValidator from 'email-validator';
import Config from '../../config';
import axios from 'axios';

const mapStateToProps = (state) => {
    return {
        text: state.language.contact
    }
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitCaptcha = this.submitCaptcha.bind(this);
        this.emailRef = React.createRef();
        this.formRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    state = {
        recaptcha: false,
        validated: false,
        email: '',
        fullName: '',
        text: '',
        validEmail: true,
        validName: true,
        validText: true,
        browser: ''
    }
    submitCaptcha(response) {
        if (response) {
            this.setState({ ...this.state, recaptcha: true });
        }
    }
    onExpiredCaptcha() {
        this.setState({ ...this.state, recaptcha: false });
    }

    onChange(event) {
        let text = event.target.value
        let id = event.target.id;

        if (id === 'Email') {
            this.setState({ email: text });
        }
        if (id === 'Name') {
            this.setState({ fullName: text });

        }
        if (id === 'Text') {
            this.setState({ text: text });

        }
    }
    validateFunction() {
        let adjustments = {};
        // if (id === 'Email') {
        let valid = EmailValidator.validate(this.state.email);
        let finalValid = true;
        if (valid === true) {
            adjustments = { ...adjustments, validEmail: true };
        }
        else {
            adjustments = { ...adjustments, validEmail: false };
            finalValid = false;
        }
        // }
        // if (id === 'Name') {
        if (this.state.fullName.length > 2) {
            adjustments = { ...adjustments, validName: true };
        }
        else {
            adjustments = { ...adjustments, validName: false };
            finalValid = false;
        }
        // }
        // if (id === 'Text') {
        if (this.state.text.length > 50) {
            adjustments = { ...adjustments, validText: true };
        }
        else {
            adjustments = { ...adjustments, validText: false };
            finalValid = false;
        }
        // }

        this.setState({ ...this.state, ...adjustments });
        return finalValid;
    }


    submit() {
        if (this.validateFunction() === true) {
            try {
                const data = {
                    name: this.state.fullName,
                    email: this.state.email,
                    text: this.state.text,
                    proizvod: 'Kontakt strane'
                }
                axios.post(Config.Api.contact, {
                    data,
                })
                    .then((response) => {
                        alert('Poruka je uspešno poslata!')
                        this.setState({
                            ...this.state,
                            fullName: '',
                            email: '',
                            text: '',
                        })
                    });
            }
            catch (err) {
                // alert('');
            }
        }
        else {
        }
    }

    render() {
        return (
            <>
                {
                    this.props.text ?
                        <>

                            <MainSection title={this.props.text.title} noBottom={true} stretch={this.props.stretch}>
                                <p style={{ display: 'flex', overflowWrap: 'break-word' }}>Sve što vas zanima u vezi ovog proizvoda možete da nas pitate. Neko od naših zaposlenih će Vam odgovoriti u najkraćem mogućem roku</p>
                                <form>
                                    <InputField type='text' name='name' oninvalid='invalid' placeholder={this.props.text.name} required onChange={this.onChange} id="Name" value={this.state.fullName} />
                                    {/* <Paragraph>Invalid email address</Paragraph> */}
                                    <InputField type='text' name='email' oninvalid='invalid 2' placeholder={this.props.text.email} required onChange={this.onChange} id="Email" value={this.state.email} />
                                    {/* <Paragraph>Invalid email address</Paragraph> */}
                                    <TextArea type='text' name='message' oninvalid='invalid 3' placeholder={this.props.text.message} required onChange={this.onChange} id="Text" value={this.state.text} />
                                    <Paragraph style={{ marginTop: '5px' }}>{this.state.status ? this.state.status : ''}</Paragraph>
                                    <Paragraph><RedText>{!this.state.validName ? this.props.text.nameError : ''} </RedText></Paragraph>
                                    <Paragraph><RedText>{!this.state.validEmail ? this.props.text.emailError : ''}</RedText></Paragraph>
                                    <Paragraph><RedText>{!this.state.validText ? this.props.text.textError : ''}</RedText></Paragraph>
                                    {
                                        this.state.error !== 1 && this.state.recaptcha ?
                                            <Button text={this.props.text.send} onClick={() => this.submit()} type='button' />
                                            :
                                            <></>
                                    }
                                </form>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '60px' }}>
                                    <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <ReCAPTCHA
                                            render="explicit"
                                            sitekey="6LcmprQZAAAAAGdULX4jtfXkfRDyRdAeV7IzLjwc"
                                            onVerifyCallBack={this.submitCaptcha}
                                            onChange={this.submitCaptcha}
                                            onExpired={this.onExpiredCaptcha}
                                        />
                                    </div>
                                </div>
                            </MainSection>
                        </>
                        :
                        <></>
                }
            </>
        );
    }
}


export default connect(mapStateToProps, null)(ContactForm);