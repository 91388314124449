import React from 'react';
import styled from 'styled-components';

const FlexRow = styled.div`
margin-top: 20px;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-self: center;
width: 740px;

@media only screen and ( max-width: 1112px ){
    width: 663px;
};
@media only screen and ( max-width: 834px ){
    width: 92%;
};
`;

const LeftGreenText = styled.p`
display: inline-block;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 18px;
align-self: flex-start;

color: #005B30;
transition: color 0.2s;
:hover{
    cursor: pointer;
    color:#64A70A;
}
`;
const RightGreenText = styled.p`
display: inline-block;
float: right;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 18px;
align-self: flex-start;

color: #005B30;
transition: color 0.2s;
:hover{
    cursor: pointer;
    color:#64A70A;
}
`;
class PageNavigationArrow extends React.PureComponent {
    render() {
        return (
            <FlexRow >
                {
                    this.props.adminMode === false ?
                        <div style={{ width: '100%', display: 'inline' }}>
                            {this.props.prevPage === true ?
                                <LeftGreenText onClick={() => { window.location.replace('/#/blog/' + ((parseInt(this.props.pageIndex) - 1))); window.location.reload(); }}>{'< Predhodna strana'} </LeftGreenText>
                                :
                                <></>
                            }
                            {this.props.nextPage === true ?
                                <RightGreenText onClick={() => { window.location.replace('/#/blog/' + (parseInt(this.props.pageIndex) + 1)); window.location.reload(); }}>{'Sledeća strana >'}</RightGreenText>
                                :
                                <></>
                            }
                        </div>
                        :
                        <div style={{ width: '100%', display: 'inline' }}>
                            {this.props.prevPage === true ?
                                <LeftGreenText onClick={() => { window.location.replace('/#/admin/blog/' + ((parseInt(this.props.pageIndex) - 1))); window.location.reload(); }}>{'< Predhodna strana'} </LeftGreenText>
                                :
                                <></>
                            }
                            {this.props.nextPage === true ?
                                <RightGreenText onClick={() => { window.location.replace('/#/admin/blog/' + (parseInt(this.props.pageIndex) + 1)); window.location.reload(); }}>{'Sledeća strana >'}</RightGreenText>
                                :
                                <></>
                            }
                        </div>
                }
            </FlexRow >
        );
    }
}

export default PageNavigationArrow;