import images from '../../images';

const products = [
    {
        id: 1,
        large_image: images.localdata.products.large_image.dekstop.femisan_a_kapi,
        large_image_mobile: images.localdata.products.large_image.mobile.femisan_a_kapi,
        product_image: images.localdata.products.icon.femisan_a_kapi,
        product_second_image: images.localdata.products.second_image.femisan_a_kapi_2,
        product_third_image: images.localdata.products.third_image.femisan_a_kapi_3,
        subtitle_sr: "Doprinosi hormonskom\nbalansu i plodnosti",
        subtitle_en: "Contributes to hormonal\nbalance and fertility",
        shortDescription_sr: "Biljne kapi koje pomažu kod cista, mioma, bolnih i neurednih menstruacija, steriliteta, upala jajnika i infekcija.",
        shortDescription_en: "Herbal drops that help with cysts, fibroids, painful and irregular menstruation, sterility, ovarian inflammation and infections.",
        description_sr: "FEMISAN A koristi se kao dopuna ishrani na bazi vodeno-alkoholnih ekstrakata herbe virka, herba hajdučke trave, cveta nevena, herbe crveng zdravca, lista maline i lista peršuna. Herba cvenog zdravca pomaže očuvanju dobrih polnih funkcija. Herba virka pomaže očuvanju komfora pre i tokom menstrualnog ciklusa. Herba hajdučke trave smiruje abdominalne grčeve kod žena",
        description_en: "Description",
        composition_sr: "Vodeno etanolskog rastvora herbe virka (Alchemilla vulgaris herba) 30%, vodeno etanolskog rastvora herbe hajdučke trave (Achillea millefolium herba) 20%, vodeno etanolskog rastvora cveta nevena (Calendulae flos) 15%, vodeno etanolskog rastvora herbe zdravca (Geranium robertianum herba) 15%, vodeno etanolskog rastvora ekstrakt lista maline (Rubus idaeus) 10%, vodeno etanolskog rastvora lista peršuna (Petroselini folium) 10%",
        composition_en: "Composition",
        name_sr: "Femisan A",
        name_en: "Femisan A ",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 12 godina.Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. Sadrži alkohol.",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "8607000018107",
                text_sr: "8607000018107",
                text_en: "8607000018107"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u orginalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 2,
        large_image: images.localdata.products.large_image.dekstop.femisan_b_kapi,
        large_image_mobile: images.localdata.products.large_image.mobile.femisan_b_kapi,
        product_image: images.localdata.products.icon.femisan_b_kapi,
        product_second_image: images.localdata.products.second_image.femisan_b_kapi_2,
        product_third_image: images.localdata.products.third_image.femisan_b_kapi_3,
        subtitle_sr: "Neprijatni simptomi menopauze",
        subtitle_en: "",
        shortDescription_sr: "Biljne kapi koje pomažu kod problema u menopauzi.",
        shortDescription_en: "no description",
        description_sr: "FEMISAN B se koristi kao dodatak ishrani na bazi mešavine biljnih sastojaka koji potencijalno  doprinose očuvanju normalnog fiziološkog stanja žena u periodu srednje životne dobi.",
        description_en: "",
        composition_sr: "Vodeno etanolskog rastvora herbe bele imele (Viscum album) 30%,  vodeno etanolskog rastvora lista matičnjaka (Mellisae folium) 20%, vodeno etanolskog rastvora herbe hajdučke trave (Achillea millefolium herba) 20%, vodeno etanolskog rastvora cveta nevena (Calendulae flos) 15%, vodeno etanolskog rastvora korena valerijane (Valeriana officinalis radix) 15%.   Sadržaj alkohola: 40-45% poreklom iz tečnih biljnih ekstrakata. ",
        composition_en: "",
        name_sr: "Femisan B",
        name_en: "Femisan B ",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Preparat nije namenjen deci, trudnicama, dojiljama i osobama osetljivim na sastojke preparata. Ovaj proizvod nije zamena za zdravu i uravnoteženu ishranu.  Proizvod nije namenjen za dijagnozu, tretman ili lečenje bilo koje bolesti. Preporučena dnevna doza se ne sme prekoračiti. Alkohol prisutan u kapima može uticati na psihofizičke i motorne funkcije i sposobnost upravljanja vozilom ili rada na mašinama. Preparat ne treba da uzimaju osobe na terapiji sedativima. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8607000018114",
                text_en: "8607000018114"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u orginalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 3,
        large_image: images.localdata.products.large_image.dekstop.femisan_a_kapsule,
        large_image_mobile: images.localdata.products.large_image.mobile.femisan_a_kapsule,
        product_image: images.localdata.products.icon.femisan_a_kapsule,
        product_second_image: images.localdata.products.second_image.femisan_a_kapsule_2,
        product_third_image: images.localdata.products.third_image.femisan_a_kapsule_3,
        subtitle_sr: "Prirodna formula za žene",
        subtitle_en: "",
        shortDescription_sr: "FEMISAN A se koristi kao dodatak ishrani za žene sa ekstraktom virka, hajdučke trave, nevena, crvenog zdravca, rusomače, korena zlatne make i cinka.",
        shortDescription_en: "",
        description_sr: "Femisan A  doprinosi održavanju normalnih fizioloških funkcija reproduktivnih organa kod žena. Sadrži cink koji doprinosi normalnoj plodnosti i reprodukciji, normalnoj sintezi DNK i proteina, kao i ulogu u procesu deljenja ćelija. Sadrži maku koja doprinosi regulaciji hormonske aktivnosti i zdravlju reproduktivnih organa. Sadrži virak koja doprinosi smanjenju menstrualnih nelagodnosti.",
        description_en: "",
        composition_sr: "Suvi ekstrakt herbe virka (Alchemilla vulgaris L.),  4:1; suvi  ekstrakt herbe hajdučke trave (Achillea millefolium L.), 4:1; suvi ekstrakt cveta nevena (Calendula officinalis L.), 4:1; suvi ekstrakt herbe zdravca (Geranium macrorrhizum L.), odnos ekstrakt:biljna droga 4:1; suvi ekstrakt korena zlatne make (Lepidium meyenii ) 4:1 (0,6% macamides & maceanes); suvi ekstrakt herba rusomače (Capsella bursa-pastoris L.), odnos ekstrakt:biljna droga 4:1; zink (zinc citrate trihydrate)",
        composition_en: "",
        name_sr: "Femisan A kapsule",
        name_en: "Femisan A",
        special: true,
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju (2 kapsule) popiti jednom do dva puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Preparat nije namenjen deci do 16 godina, trudnicama, dojiljama i osobama osetljivim na sastojke preparata. Ovaj proizvod nije zamena za zdravu i uravnoteženu ishranu.  Proizvod nije namenjen za dijagnozu, tretman ili lečenje bilo koje bolesti. Preporučena dnevna doza se ne sme prekoračiti. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Plastična kutija sa 60 komada kapsula.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "8606106734188",
                text_sr: "8606106734188",
                text_en: ""
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 4,
        large_image: images.localdata.products.large_image.dekstop.alfa_aktiv,
        large_image_mobile: images.localdata.products.large_image.mobile.alfa_aktiv,
        product_image: images.localdata.products.icon.alfa_aktiv,
        product_second_image: images.localdata.products.second_image.alfa_aktiv_2,
        product_third_image: images.localdata.products.third_image.alfa_aktiv_3,
        subtitle_sr: "Prirodna pomoć za muški sterilitet\n i erektilnu disfunkciju",
        subtitle_en: "",
        shortDescription_sr: "ALFA AKTIV je nova jedinstvena formula koja sadrži visok nivo prirodnih sastojaka koji pomažu kod erektilne disfunkcije i muškog steriliteta.",
        shortDescription_en: "",
        description_sr: "ALFA AKTIV HS se koristi kao dodatak ishrani za odrasle muškarce sa ekstraktom damiane, cinkom, L-karnitinom i acetil L-karnitinom. Sadrži cink koji doprinosi normalnoj plodnosti i reprodukciji, kao i održavanju normalnog nivoa testosterona u krvi.",
        description_en: "",
        composition_sr: "Suvi ekstrakt lista Damiane (Turnera diffusa) 4:1 1000mg, Acetil-L-karnitin 390mg, L-karnitin 400mg, Zink 10mg*100%PDU (*PDU = preporučeni dnevni unos)",
        composition_en: "",
        name_sr: "Alfa aktiv kapsule",
        name_en: "Alfa aktiv",
        special: true,
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Odrasli muškarci - jednu porciju (2 kapsule) popiti jednom do dva puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Preparat nije namenjen deci i osobama mlađim od 18 godina, trudnicama, dojiljama, osobama sa dijabetesom i osobama osetljivim na sastojke preparata. Ovaj proizvod nije zamena za zdravu i uravnoteženu ishranu. Proizvod nije namenjen za dijagnozu, tretman ili lečenje bilo koje bolesti. Preporučena dnevna doza se ne sme prekoračiti. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Plastična kutija sa 60 komada kapsula.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "8606106734072",
                text_sr: "8606106734072",
                text_en: ""
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 5,
        large_image: images.localdata.products.large_image.dekstop.nefrovit,
        large_image_mobile: images.localdata.products.large_image.mobile.nefrovit,
        product_image: images.localdata.products.icon.nefrovit,
        product_second_image: images.localdata.products.second_image.nefrovit_2,
        product_third_image: images.localdata.products.third_image.nefrovit_3,
        subtitle_sr: "Infekcije urinarnog trakta",
        subtitle_en: "",
        shortDescription_sr: "Biljne kapi koje pomažu kod bolesti bubrega i mokraćnih kanala.",
        shortDescription_en: "",
        description_sr: "NEFROVIT se koristi kao dopuna ishrani na bazi mešavine biljnih sastojaka koji doprinosi očuvanju normalnog funkcionisanja organa urinarnog trakta. Veronika doprinosi funkciji bubrega i bešike. Breza doprinosi normalnim funkcijama eliminacije urina.Zlatnica pomaže u održavanju zdravog urinarnog trakta i doprinosi normalnim funkcijama eliminacije urina.Rastavić podržava ekskretornu funkciju bubrega.",
        description_en: "",
        composition_sr: "Vodeno etanolskog rastvora herbe veronike (Veronicae herba) 20%, vodeno etanolskog rastvora korena maslačka (Taraxaci radix) 20%, vodeno etanolskog rastvora herbe zlatnice (Solidago virgaurea herba) 20%, vodeno etanolskog rastvora lista breze (Betulae pendulae folium) 20%, vodeno etanolskog rastvora herbe rastavića (Equisetum herba) 20%. Sadržaj alkohola: 40-45% poreklom iz tečnih biljnih ekstrakata.",
        composition_en: "",
        name_sr: "Nefrovit",
        name_en: "NEFROVIT",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju (30 kapi/0,75 g) rastvoriti u čaši vode i popiti tri puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Preparat nije namenjen deci do 16 godina, trudnicama  i osobama osetljivim na sastojke preparata.  Ovaj proizvod nije zamena za zdravu i uravnoteženu ishranu.  Proizvod nije namenjen za dijagnozu, tretman ili lečenje bilo koje bolesti. Preporučena dnevna doza se ne sme prekoračiti. Alkohol prisutan u kapima može uticati na psihofizičke i motorne funkcije. Ne preporučuje se pacijentima sa stomačnim ulkusom i gastritisom, kod staze žučnih puteva i kod ileusa. Ne sme se koristiti kod edema uzrokovanih poremećajem rada bubrega.",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8607000018121",
                text_en: "8607000018121"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 6,
        large_image: 0,
        product_image: images.localdata.products.icon.leocardin,
        product_second_image: images.localdata.products.second_image.leocardin_2,
        product_third_image: images.localdata.products.third_image.leocardin_3,
        subtitle_sr: "Hrani vaše srce",
        subtitle_en: "",
        shortDescription_sr: "LEOCARDIN su potpuno prirodne kapi koje dovode u ravnotežu krvni pritisak.",
        shortDescription_en: "",
        description_sr: "Leocardin se koristi kao dodatak ishrani na bazi mešavine biljnih ekstrakata. Herbe srdačice doprinose normalnom funkcionisanju kardiovaskularnog sistema.",
        description_en: "",
        composition_sr: "Vodeno-etanolni ekstrakt herbe matičnjaka (Melissa officinalis L.) 30%, vodeno-etanolni ekstrakt herbe srdačca (Leonurus cardiaca L.) 20%, vodeno-etanolni ekstrakt herbe veronike (Veronica officinalis L.) 20%, vodeno-etanolni ekstrakt herbe bele imele (Viscum album L.) 20%, vodeno-etanolni ekstrakt herbe rastavića (Equisetum arvense L.) 10%.",
        composition_en: "",
        name_sr: "LEOCARDIN",
        name_en: "LEOCARDIN",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 16 godina.Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8606106734089",
                text_en: "8606106734089"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 7,
        large_image: 0,
        product_image: images.localdata.products.icon.hipoprostat,
        product_second_image: images.localdata.products.second_image.hipoprostat_2,
        product_third_image: images.localdata.products.third_image.hipoprostat_3,
        subtitle_sr: "Infekcije prostate",
        subtitle_en: "",
        shortDescription_sr: "Biljne kapi za akutni i hronični prostatitis, benignu hipertrofiju prostate i adenom prostate.",
        shortDescription_en: "",
        description_sr: "HIPOPROSTAT se koristi kao dodatak ishrani na bazi mešavine biljnih sastojaka za normalnu funkciju prostate. Sadrži koprivu koja pomaze kod zdravlja prostate i poremećaja mokrenja u benignoj hiperplaziji prostate u fazi I i II.",
        description_en: "",
        composition_sr: "Epilobii herba, Taraxacii radix, Urticae radix, Equiseti herba, Tropeolum herba.",
        composition_en: "",
        name_sr: "HIPOPROSTAT",
        name_en: "HIPOPROSTAT",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 16 godina. Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8606106734102",
                text_en: "8606106734102"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 8,
        large_image: 0,
        product_image: images.localdata.products.icon.equigal,
        product_second_image: images.localdata.products.second_image.equigal_2,
        product_third_image: images.localdata.products.third_image.equigal_3,
        subtitle_sr: "Za čišćenje organizma",
        subtitle_en: "",
        shortDescription_sr: "Equigal je prirodna formula namenjena ljudima sa problemom prekomerne težine.",
        shortDescription_en: "",
        description_sr: "Koristi se kao dodatak ishrani na bazi mešavine biljnih ekstrakata. Koren angelike olakšava  varenje. List nane doprinosi normalnom varenju, očuvanju zdravog želuca i normalnoj funkciji intestinalnog trakta. Cvet nevena doprinosi normalnoj funkciji jetre.",
        description_en: "",
        composition_sr: "Vodeno-etanolni ekstrakt korena angelike (Angelica archangelica L.) 25%, vodeno-etanolni ekstrakt ivanjskog cveća (Galium verum L.) 25%, vodeno-etanolni ekstrakt lista nane (Mentha piperita L.) 20%, vodeno-etanolni ekstrakt cveta nevena (Calendula officinalis L.) 10%, vodeno-etanolni ekstrakt korena maslačka (Taraxacum officinalis L.) 10%, vodeno-etanolni ekstrakt herbe rastavića (Equisetum arvense L.) 10%.",
        composition_en: "",
        name_sr: "EQUIGAL",
        name_en: "EQUIGAL",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 16 godina. Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8606106734096",
                text_en: "8606106734096"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 9,
        large_image: 0,
        product_image: images.localdata.products.icon.optima_forma,
        product_second_image: images.localdata.products.second_image.optima_forma_2,
        product_third_image: images.localdata.products.third_image.optima_forma_3,
        subtitle_sr: "Protiv fizičke i mentalne iscrpljenosti",
        subtitle_en: "",
        shortDescription_sr: "OPTIMA FORMA vraća vedrinu, eliminiše nervozu, napetost, otklanja slabost živaca i depresiju, normalizuje san.",
        shortDescription_en: "",
        description_sr: "Koristi se kao dodatak ishrani na bazi mešavine biljnih ekstrakata. Koren valerijane i list matičnjaka olakšavaju nastupanje sna i doprinose očuvanju normalnog sna. Koren valerijane doprinosi dobrom psihičkom stanju u slučaju napetosti i stresa. List matičnjaka doprinosi smanjenju uzemirenosti.",
        description_en: "",
        composition_sr: "Vodeno-etanolni ekstrakt herbe matičnjaka (Melissa officinalis L.) 40%, vodeno-etanolni ekstrakt herbe hmelja (Humulus lupulus L.) 30%, vodeno-etanolni ekstrakt korena valerijane (Valeriana officinalis L.) 30%",
        composition_en: "",
        name_sr: "OPTIMA FORMA",
        name_en: "OPTIMA FORMA",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Jednu porciju(60kapi/1,5g) rastvoriti u čaši vode i popiti 2-4 puta dnevno pre jela.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 16 godina.Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8606106734065",
                text_en: "8606106734065"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 10,
        large_image: 0,
        product_image: images.localdata.products.icon.disan,
        product_second_image: images.localdata.products.second_image.disan_2,
        product_third_image: images.localdata.products.third_image.disan_3,
        subtitle_sr: "štiti disajne organe i jača imunitet organizma",
        subtitle_en: "",
        shortDescription_sr: "DISAN sadrži 8 biljnih vrsta, uz dodatak meda i vitamina C.",
        shortDescription_en: "",
        description_sr: "Dodatak ishrani na bazi mešavine biljnih ekstrakata i vitamina C. List muške bokvice, herba veronike i mentol imaju umirujuće dejstvo za usta i grlo, doprinose olakšanju u slučaju iritacije grla i ždrela, imaju umirujuće i prijatno dejstvo na grlo, ždrelo i glasne žice. Vitamin C doprinosi smanjenju umora i iscrpljenosti.",
        description_en: "",
        composition_sr: "Vodeno-etanolni ekstrakt lista muške bokvice (Plantago lanceolata L.) 15%, vodeno-etanolni ekstrakt herbe ehinacee (Echinacea purpurea L.) 10%, vodeno-etanolni ekstrakt herbe matičnjaka (Melissa officinalis L.) 10%, vodeno-etanolni ekstrakt korena maslačka (Taraxacum officinalis Weber) 10%, vodeno-etanolni ekstrakt korena angelike (Angelica archangelica L.) 10%, odeno-etanolni ekstrakt herbe veronike (Veronica officinalis L.) 10%, vodeno-etanolni ekstrakt lista koprive (Urtica dioica L.) 6.9%, vodeno-etanolni ekstrakt herbe bele imele (Viscum album L.) 5%, vitamin C (L-askorbinska kiselina) 200mg u dnevnoj dozi*, med 20%, menthol 1mg u dnevnoj dozi* *dnevna doza 18ml",
        composition_en: "",
        name_sr: "DISAN",
        name_en: "DISAN",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "90 kapi (4,5ml) rastvoriti u čaši vode i popiti dva do četiri puta dnevno pre jela. Pre upotrebe promućkati.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Proizvod nije namenjen osobama preosetljivim na neki od sastojaka, trudnicama, dojiljama i osobama mlađim od 16 godina.Osobama koje boluju od drugih bolesti ili kororiste lekove savetuje se pre primene ovog proizvoda konsultacija sa lekarom. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "Staklena bočica sa 30 ml rastvora.",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8607000018138",
                text_en: "8607000018138"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Preparat čuvati zatvoren, u originalnom pakovanju, na tamnom mestu i sobnoj temperaturi, van domašaja dece.",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 11,
        large_image: 0,
        product_image: images.localdata.products.icon.devi_peta,
        product_second_image: images.localdata.products.second_image.devi_peta_2,
        product_third_image: images.localdata.products.third_image.devi_peta_3,
        subtitle_sr: "za negu peta",
        subtitle_en: "",
        shortDescription_sr: "Idealna biljna formula za negu hrapave i ispucale kože peta.",
        shortDescription_en: "",
        description_sr: "Devi melem za pete je posebna biljna formula, namenjena nezi kože na petama i stopalima. Rezultati su vidljivi već posle drugog nanošenja. Melem omekšava grubu, suvu i ispucalu kožu. Melem svojim specijalnim aktivnim sastojcima iz biljnih ulja vraća koži gipkost i elastičnost. Zahvaljujući visokom sadržaju ulja čileanske ruže, kakao butera i pčelinjeg voska, hidratacija kože je odmah povećana, umornim stopalima vraća se vitalnost, a prisustvo eteričnih ulja ruzmarina, žalfije, čajevca, lavande i syzygium-a sprečava infekcije. ",
        description_en: "",
        composition_sr: "Ulje semenki grožđa, ulje avokada, ulje jojobe, ulje nevena, ulje pšeničnih klica, ulje čileanske ruže, pčelinji vosak, kakao buter, eterična ulja: ruzmarina, lavande, žalfije, karanfilića, čajevca i izvorska voda.",
        composition_en: "",
        name_sr: "DEVI MELEM",
        name_en: "DEVI MELEM",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Na oprana i osušena stopala dva puta dnevno naneti melem, blago masirajući dok se ne upije. Kada se koža oporavi, nastaviti sa upotrebom po potrebi, da bi se takvo stanje održalo. Samo za spoljašnju upotrebu.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Samo za spoljnu upotrebu. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8607000018169",
                text_en: "8607000018169"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Čuvati na sobnoj temperaturi. ",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 12,
        large_image: 0,
        product_image: images.localdata.products.icon.devi_koza,
        product_second_image: images.localdata.products.second_image.devi_koza_2,
        product_third_image: images.localdata.products.third_image.devi_koza_3,
        subtitle_sr: "za regeneraciju kože",
        subtitle_en: "",
        shortDescription_sr: "Ovaj melem je jedinstven u lečenju rana i kožnih problema.",
        shortDescription_en: "",
        description_sr: "Devi melem za regeneraciju je prirodna biljna formula, bez kortikosteroida, sa izričitim zaštitnim i regenerativnim dejstvom kod niza kožnih problema, kao i za negu zdrave kože. Prisustvo najkvalitetnijih biljnih eteričnih ulja podstiče regeneraciju i podmlađivanje kože. Melem prodire do unutrašnjih slojeva dermisa, otklanja različite kožne probleme, stimuliše pravilno funkcionisanje zdrave i regeneraciju oštećene kože. Svakodnevnom redovnom primenom melema održava se dobar tonus i zdrav izgled kože. ",
        description_en: "",
        composition_sr: "Ulje avokada, ulje jojobe, ulje nevena, ulje pšeničnih klica, ulje čileanske ruže, ulje žutog noćurka, alantoin, bisabolol, pčelinji vosak, panthenol, eterična ulja: mandarine, ruzmarina, bergamota i lavande, izvorska voda.",
        composition_en: "",
        name_sr: "DEVI MELEM",
        name_en: "DEVI MELEM",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Dva puta u toku dana namazati problematične delove kože. ",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Samo za spoljnu upotrebu. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "8607000018145",
                text_en: "8607000018145"
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Čuvati na sobnoj temperaturi. ",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    // {
    //     id: 13,
    //     large_image: 0,
    //     product_image: images.localdata.products.icon.devi_strija,
    //     product_second_image: images.localdata.products.second_image.devi_strija_2,
    //     product_third_image: images.localdata.products.third_image.devi_strija_3,
    //     subtitle_sr: "melem protiv strija",
    //     subtitle_en: "",
    //     shortDescription_sr: "biljna formula koja pospešuje elstičnost kože",
    //     shortDescription_en: "",
    //     description_sr: "MELEM  PROTIV STRIJA  je delotvorna biljna formula koja pospešuje elstičnost kože, sprečava nastank strija i ublažava postojeće. Sadrži ulje avokada sa izvarednim sposobnostima regeneracije i podmlađivanja kože zbog prisustva visokih koncentracija esencijalnih masnih kiselina koje sprečavaju isušivanje i održavaju elastičnost kože. Ulje pšeničnih klica bogato vitaminima (B1,B2,B3 i B6) i mineralima (cink ,gvoždje, sumpor fosfor)  je i prirodni antioksidans koji stimuliše regeneraciju tkiva i idealno je u borbi protiv strija. Ulje čileanske ruže je izvor trans-retinske kiseline (prirodnog derivata vitamina A)  koja dovodi do bržeg obnavljanja oštećenog epidermisa kože, tako što ubrzava delenje kreatinina.Ovo ulje sadrži  i ntokoferol(vitaminom E) koji bogastvom hidratizirajućih sastojaka pomaže da se održi dobar tonus i zdrav izgled kože . Eterična ulja lavande, mandarine, ruzmarina i bergamota kompletiraju dejstvo  melema u borbi protiv strija. Kakao   buter je prirodni ovlaživač kože, koji ima veliku sposobnost zadržavanja vlage, istovremeno dopuštajući koži da diše. Neven omeksava, tonizira, regenerise i vlazi kozu. Melem protiv strija Devi zahvljujući svim ovim svojstvima izdašno vlaži kožu, na površini kože stvara polupropusni sloj koji sprečava gubitak vlažnosti, povećava elastičnost kože, što smanjuje mogućnost nastanka strija u trudnoći i pospešuje obnavljanje oštećenog tkiva i boju već postojećih strija približava prirodnoj boji kože.",
    //     description_en: "",
    //     composition_sr: "Ulje avokada, ulje jojobe, ulje nevena, ulje pšeničnih klica, ulje čileanske ruže, pčelinji vosak, kakao buter, eterična ulja: mandarine, ruzmarina, bergamota, lavande i izvorska voda.",
    //     composition_en: "",
    //     name_sr: "DEVI MELEM",
    //     name_en: "DEVI MELEM",
    //     subsection: [
    //         {
    //             title_sr: "Način primene",
    //             title_en: "",
    //             text_sr: "Melem je potpuno bezbedan i može se primenjivati do 9. meseca trudnoće,  kod naglog uvećanja telesne težine i za tretiranje već postojećih  strija. Nanosi se dva puta dnevno uz blagu masažu  na kritične delove tela.",
    //             text_en: ""
    //         },
    //         {
    //             title_sr: "Mere opreza",
    //             title_en: "Samo za spoljnu upotrebu. ",
    //             text_sr: "",
    //             text_en: ""
    //         },
    //         {
    //             title_sr: "Pakovanje",
    //             title_en: "",
    //             text_sr: "",
    //             text_en: ""
    //         },
    //         {
    //             title_sr: "Bar kod",
    //             title_en: "",
    //             text_sr: "",
    //             text_en: ""
    //         },
    //         {
    //             title_sr: "Način čuvanja",
    //             title_en: "",
    //             text_sr: "Čuvati na sobnoj temperaturi. ",
    //             text_en: ""
    //         },
    //         {
    //             title_sr: "Proizvođač",
    //             title_en: "",
    //             text_sr: "HerbaSvet d.o.o, Beograd",
    //             text_en: ""
    //         },
    //     ]
    // },
    {
        id: 13,
        large_image: 0,
        product_image: images.localdata.products.icon.devi_bradavica,
        product_second_image: images.localdata.products.second_image.devi_bradavica_2,
        product_third_image: images.localdata.products.third_image.devi_bradavica_3,
        subtitle_sr: "protiv virusnih bradavica",
        subtitle_en: "",
        shortDescription_sr: "Pet moćnih saveznika u borbi protiv virusnih bradavica.",
        shortDescription_en: "",
        description_sr: "DEVI MELEM PROTIV VIRUSNIH BRADAVICA sadrži eterična ulja čajevca, ravensare i divljeg origana, kao i biljni ekstrakt vrbine kore, koji imaju izraženo antivirusno i keratolitičko dejstvo. Melem na prirodan način i bez oštećenja zdravog tkiva efikasno uklanja bradavice i sprečava njihovo ponovno nastajanje. Esencijalno ulje ravensare je svetski poznato moćno ulje sa ostrva Madagaskar, najlepše tačke istočne obale Afrike. ",
        description_en: "",
        composition_sr: "Lanolin, ricinusovo ulje, pčelinji vosak, vazelin, eterična ulja: čajevca, ravensare, divljeg origana, ekstrakt: vrbine kore.",
        composition_en: "",
        name_sr: "DEVI MELEM",
        name_en: "DEVI MELEM",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Dva puta u toku dana namazati problematične delove kože. Tretman traje dva do tri meseca. Nakon nestanka, melem koristiti još tri nedelje. ",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Samo za spoljnu upotrebu. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "Čuvati na sobnoj temperaturi. ",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
    {
        id: 14,
        large_image: 0,
        product_image: images.localdata.products.icon.devi_grudi,
        product_second_image: images.localdata.products.second_image.devi_grudi_2,
        product_third_image: images.localdata.products.third_image.devi_grudi_3,
        subtitle_sr: "za dekolte",
        subtitle_en: "",
        shortDescription_sr: "Proizvod za negu grudi i dekoltea.",
        shortDescription_en: "",
        description_sr: "Prirodna biljna formula, bogata sastojcima za povećanje volumena i tonusa grudi. Sadrži piskavicu, koja u narodu ima vekovnu reputaciju sredstva za uvećanje grudi. Glavni sastojak piskavice je fitoestrogen (biljni estrogen). Estrogen ima višestruko dejstvo u organizmu – između ostalog, deluje i na uvećanje grudi. Ekstrakt hmelja pojačava ovo dejstvo i zajedno sa piskavicom bolje prokrvljuje, tj. proširuje mlečne žlezde, te na taj način čini grudi većim i čvršćim. Visoka koncentracija vitamina A i E u uljima avokada i čileanske ruže obezbeđuje dodatnu energiju kožnim tkivima, bori se protiv slobodnih radikala i usporava starenje. Ulje avokada ima izvanredne sposobnosti regeneracije i podmlađivanja zbog prisustva visokih koncentracija esencijalnih masnih kiselina, koje sprečavaju isušivanje i održavaju elastičnost kože. Ulje divlje ruže podstiče sintezu kolagena, što je dobra prevencija nastanku bora na osetljivom delu dekoltea, kao i nastanku strija na grudima. Redovnom upotrebom Devi gela za grudi, već nakon dva meseca grudi postaju veće i čvršće, dok prisustvo dragocenih ulja reaguje i čini kožu baršunastom. ",
        description_en: "",
        composition_sr: "Ulje avokada, ulje čileanske ruže, pčelinji vosak, ekstrakti: piskavice i hmelja.",
        composition_en: "",
        name_sr: "DEVI MELEM",
        name_en: "DEVI MELEM",
        subsection: [
            {
                title_sr: "Način primene",
                title_en: "",
                text_sr: "Namazati Devi gel na grudi dva puta dnevno i masirati po pet minuta kružnim pokretima u obliku osmice, izbegavajući bradavice. Dekolte masirati nežnim pokretima na gore.",
                text_en: ""
            },
            {
                title_sr: "Mere opreza",
                title_en: "",
                text_sr: "Samo za spoljnu upotrebu. ",
                text_en: ""
            },
            {
                title_sr: "Pakovanje",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Bar kod",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Način čuvanja",
                title_en: "",
                text_sr: "",
                text_en: ""
            },
            {
                title_sr: "Proizvođač",
                title_en: "",
                text_sr: "HerbaSvet d.o.o, Beograd",
                text_en: ""
            },
        ]
    },
]

export default products;