import styled from 'styled-components';

export const Container = styled.div`
margin-top: -21px;
flex-wrap: nowrap;
align-self: center;
width: 100%;
flex-direction: row;
display: flex;
white-space: nowrap;
height: auto;
overflow: hidden;

@media only screen and (max-width: 1250px) {
}
@media only screen and (max-width: 850px) {
    height: auto;
    flex-direction: ${ props => props.reversed === true ? 'column-reversed' : 'column'};
    
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
    width: 100%;
}
`;
export const Column = styled.div`
display: flex;
flex-direction: column;
white-space: nowrap;
vertical-align: top;
margin-bottom: 20px;
height: auto; background-color: #ebe8e8;
@media only screen and (max-width: 850px) {
    margin-bottom: 0px;
}
`;
export const Section = styled.div` 
background-color: #F1F1F1;
display: flex;
vertical-align: top;
width: 50%;
white-space: nowrap;
height: auto;


@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 850px) {
    height: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    width: 100%;
    margin-left: 0%;
    display: flex;
    align-self: center;

}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Section2 = styled.div` 
padding-top: 50px;
padding-bottom: 50px;
display: flex;
flex-direction: row;
vertical-align: center;
justify-content: center;
width: 50%;
white-space: nowrap;
height: auto;
background-color: white;

@media only screen and (max-width: 1250px) {

}
@media only screen and (max-width: 850px) {
    height: 100%;
    width: 100%;
    margin-left: 0%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Section3 = styled.div` 
display: flex;
align-self: flex-end;
vertical-align: top;
background-color: white;
width: 50%;

@media only screen and (max-width: 1250px) {
}
@media only screen and (max-width: 850px) {

    width: 100%;
    align-self: center;
    justify-content: center;
    border: 1px solid red;
    z-index: 20;
    margin-left: 0%;
    display: flex;
    align-self: center;
    background-color: red;
    
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Hack = styled.div`height:100%;`;
export const Inline = styled.div`
display: inline;
@media only screen and (max-width: 850px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
`;

export const InlineIcon = styled.div`
margin-top: 45px;
@media only screen and (max-width: 1250px) {
    margin-top: 0px;
}
@media only screen and (max-width: 850px) {
    margin-top: 10px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;

export const Paragraph2 = styled.p`
font-size: 15px;
color: #666666;              
white-space: pre-line;
font-family: "Open Sans";
margin-bottom: 50px;
line-height: 24px;
${ props => { if (props.keepLine === true) { return 'white-space: pre-wrap;'; } }};
@media only screen and (max-width: 1252px){
    ${ props => { if (props.small === true) { return 'font-size:11px; line-height:21px'; } }};
}
@media only screen and (max-width: 850px){
    font-size: 15px;
}
`;

export const Image = styled.img`
position: relative;
float: right;
width: auto;
align-self: center;
display: flex;
align-self: flex-center;
height: 450px;
width: auto;
max-width: 100%;
padding-bottom: 50px;
padding-top: 50px;
/* padding-right: 150px; */

@media only screen and (max-width: 1250px) {

    padding-right: 50px;
}
@media only screen and (max-width: 1000px) {
    height: 350px;
}
@media only screen and (max-width: 850px) {
    padding-bottom: 0px;
    width: ${ props => props.stretchImage === true ? '100%' : '50%'};
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    align-self: center;
    height: auto;
    ${ props => { if (props.stretchImage === true) { return 'padding-right: 0px; padding-left: 0px;' } }};
}
`;

export const ImageSpecial = styled.img`
position: relative;
float: right;
width: auto;
align-self: center;
/* background-color: red; */
height: 600px;
width: auto;
max-width: 100%;
padding-bottom: 50px;
padding-top: 50px;
padding-right: 50px;

@media only screen and (max-width: 1250px) {

    padding-right: 50px;
}
@media only screen and (max-width: 1000px) {
    height: 350px;
}
@media only screen and (max-width: 850px) {
    padding-bottom: 0px;
    width: ${ props => props.stretchImage === true ? '100%' : '50%'};
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    align-self: center;
    height: auto;
    ${ props => { if (props.stretchImage === true) { return 'padding-right: 0px; padding-left: 0px;' } }};
}
`;
export const Image2 = styled.img`
display: flex;
width: 100%;
height: auto;
@media only screen and (max-width: 1250px) {
}
@media only screen and (max-width: 850px) {
    width: 100%;
    height: 500px;
    z-index: 40;
    border: 1px solid red;
    display: flex;
    align-self: center;
}
`;

export const Content = styled.div`
width: 100%;
height: 150px;
`;
export const Header = styled.div`
width: 100%;
text-align: left;
margin-bottom: 20px;
@media only screen and (max-width: 850px) {
    text-align: center;
}
`;
export const Center = styled.div`

`;
export const Title = styled.h2`
/* display: inline-block; */
display: flex;
flex-direction: row;
color: #005B30;
font-size: 31px;
white-space: nowrap;
width: auto;
font-weight: normal;
font-family: "Lora";
margin-top: 100px;

@media only screen and (max-width: 850px) {
  align-self: center;
 font-size: 24px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;

export const TitleLesser = styled.h2`
margin-top: 100px;
display: inline-block;
display: flex;
flex-direction: row;
color: #005B30;
font-size: 18px;
white-space: nowrap;
width: auto;
font-weight: bold;
font-family: "Lora";
@media only screen and (max-width: 1000px) {
    margin-top: 25px;
}
@media only screen and (max-width: 850px) {
  align-self: center;
 font-size: 27px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;
export const Subtitle = styled.h3`
display: inline-block;
display: flex;
flex-direction: row;
color: #666666;
font-size: 15px;
margin-top: -15px;
margin-bottom: 50px;
white-space: nowrap;
width: auto;
font-family: "Open Sans";
font-weight: normal;
@media only screen and (max-width: 510px) {
  font-size: 13px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 400px) {
    font-size: 11px;
}
`;

export const Padding = styled.div`
width: 60%;
margin-left: 14%;
margin-top: 55px;
margin-bottom: 75px;

@media only screen and (max-width: 1252px) {
margin-top: 1%;
}
`;
export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 55px;
max-height: 3px;
border-bottom: 4px solid rgb(247,148,29);
color: black;
@media only screen and (max-width: 850px) {

}
`;
export const WrapperWrapper = styled.div`
width:100%;
display: flex;
flex-direction: column;
`;
export const Icon = styled.img`
width: 100px;
height: 100px;
border-radius: 25px;
display: inline-block;
margin-right: 20px;
@media only screen and (max-width: 1400px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 850px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;