import React from 'react';
import { GrayParagraphFlexCenter } from '../../_components';
import {
    Container,
    Content,
    RightArrowContainer,
    ProductText,
    Inline,
    LeftArrowContainer,
    LabelHolder,
    Arrow,
    ArrowRight,
    Slider,
    Fade,
    Offset,
    JumbotronContainer,
    NonFlexContainer,
    FadeLiterally,
    TextContainer,
    ReviewText,
    PersonIcon
} from './styled';
import Config from '../../../config';
import Button from '../../Button';

class Reviews extends React.PureComponent {

    state = {
        selectedIndex: 1,
        selectedIndexButton: 0,
        outDirection: 'right',
        animating: false,
        offset: 0,
        shift: false
    }

    changeToDirection(direction) {
        let index = 0;
        if (direction === 'right')
            index = (this.state.selectedIndex) < this.props.data.length ? (this.state.selectedIndex + 1) : 1;
        if (direction === 'left')
            index = (this.state.selectedIndex - 1) > 0 ? (this.state.selectedIndex - 1) : (this.props.data.length);
        this.handleClick(index, direction);
    }

    handleClick(index, direction) {
        this.setState({ ...this.state, selectedIndexButton: index, animating: true, outDirection: direction });
        window.setTimeout(() => {
            this.setState({
                ...this.state,
                selectedIndex: index,
                animating: false,
                outDirection: direction,
            })
        }, 500)
    }

    render() {
        return (
            <Container>
                <Slider >
                    <Fade
                        out={this.state.animating}
                        outDirection={this.state.outDirection}
                        index={this.state.selectedIndex}
                        shift={this.state.shift}
                    >
                        <NonFlexContainer>
                            <Offset offset={this.state.selectedIndex}>
                                {this.props.data ?
                                    <JumbotronContainer>
                                        {
                                            this.props.data.map((element, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <>
                                                            <TextContainer>
                                                                <ReviewText>{this.props.data[this.props.data.length - 1].text}</ReviewText>
                                                                <PersonIcon src={this.props.data[this.props.data.length - 1].image} />
                                                                <GrayParagraphFlexCenter>{this.props.data[this.props.data.length - 1].name}</GrayParagraphFlexCenter>
                                                            </TextContainer>
                                                            <TextContainer >
                                                                <ReviewText>{element.text}</ReviewText>
                                                                <PersonIcon src={element.image} />
                                                                <GrayParagraphFlexCenter>{element.name}</GrayParagraphFlexCenter>
                                                            </TextContainer>
                                                        </>
                                                    )
                                                }
                                                if (index === (this.props.data.length - 1)) {
                                                    return (
                                                        <>

                                                            <TextContainer>
                                                                <ReviewText>{element.text}</ReviewText>
                                                                <PersonIcon src={element.image} />
                                                                <GrayParagraphFlexCenter>{element.name}</GrayParagraphFlexCenter>
                                                            </TextContainer>
                                                            <TextContainer>
                                                                <ReviewText>{this.props.data[0].text}</ReviewText>
                                                                <PersonIcon src={this.props.data[0].image} />
                                                                <GrayParagraphFlexCenter>{this.props.data[0].name}</GrayParagraphFlexCenter>
                                                            </TextContainer>
                                                        </>
                                                    )
                                                }
                                                return (

                                                    <TextContainer>
                                                        <ReviewText>{element.text}</ReviewText>
                                                        <PersonIcon src={element.image} />
                                                        <GrayParagraphFlexCenter>{element.name}</GrayParagraphFlexCenter>
                                                    </TextContainer>
                                                );

                                            })
                                        }
                                    </JumbotronContainer>
                                    :
                                    <></>
                                }
                            </Offset>
                        </NonFlexContainer>
                    </Fade>
                </Slider>
                <Content>
                    <Inline>
                        <LeftArrowContainer>
                            <Arrow src={Config.images.icon.ArrowLeftGreen} onClick={() => this.changeToDirection('left')} />
                        </LeftArrowContainer>
                        <ProductText>
                            <FadeLiterally
                                out={this.state.animating}
                            >
                                <LabelHolder>
                                </LabelHolder>
                            </FadeLiterally>
                        </ProductText>
                        <RightArrowContainer>
                            <ArrowRight src={Config.images.icon.ArrowRightGreen} onClick={() => this.changeToDirection('right')} />
                        </RightArrowContainer>
                    </Inline>
                </Content>
            </Container>
        );
    }
}

export default Reviews;