import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import ProductSlider from '../../components/ProductSlider';
import ProductCatalog from '../../components/ProductCatalog';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import FixedSpacing from '../../components/FixedSpacing';
import ScrollToTop from '../../components/ScrollToTop';

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Home extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }

    filterProducts() {
        let data = []
        for (let i = 0; i < Config.localdata.products.length; i++) {
            if (Config.localdata.products[i].large_image !== 0) {
                data.push(Config.localdata.products[i]);
            }
        }
        return data;
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <ProductSlider
                    data={this.filterProducts()}
                    lang={this.props.text.lang}
                />
                <ProductCatalog />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);