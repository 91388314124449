// import firebase from '../config/Firebase/firebaseConfig';
import { createBrowserHistory } from 'history';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import Config from '../config';
import Actions from '../store/Actions';

import React from 'react';

export function shortenString(text, maxSize) {
    let result = text;
    if (text.length > maxSize) {
        result = text.substring(0, maxSize);
    }
    return result;
}

async function tokenValidationCall(token) {
    return await Actions.Auth.validateToken(token);
}
export const PrivateRoute = ({ component: Component, handleChildFunc, ...rest }) => {
    const user = "token from cookie";
    const valid = tokenValidationCall(rest.token);
    return <Route {...rest} render={(props) => (
        true === true
            ? <Component {...props} user={user} handleChildFunc={handleChildFunc} />
            : <Redirect to='/admin/login' />
    )}
    />

    // try {
    //     const token = rest.token;
    //     axios.post(Config.Api.validate_token, {
    //         data: { token },
    //     })
    //         .then((response) => {
    //             let data = response.data;
    //             data = data + '';
    //             if (data === 'true') {
    //                 // return response.data;
    //                 // return true;
    //                 return <Route {...rest} render={(props) => (
    //                     true === true
    //                         ? <Component {...props} user={user} handleChildFunc={handleChildFunc} />
    //                         : <Redirect to='/admin/login' />
    //                 )}
    //                 />
    //             }

    //             else {
    //                 return <Route {...rest} render={(props) => (
    //                     false === true
    //                         ? <Component {...props} user={user} handleChildFunc={handleChildFunc} />
    //                         : <Redirect to='/admin/login' />
    //                 )}
    //                 />
    //             }
    //         }).catch(err => {
    //             alert(err);
    //         })
    // }
    // catch (err) {
    //     alert(err);
    // }
}

// export async function isLogedIn() {
//     let userObject = {}
//     firebase.auth().onAuthStateChanged((user) => {
//         if (user) {
//             userObject = user;
//         } else {

//         }
//     });
//     return userObject;
// }

export const history = createBrowserHistory();