import React from 'react';
import { Page, Title, Options, Inline, ButtonWraper, Subtitle } from './styled'
import { PageContainer } from '../../styles';
import ContentCreator from '../../components/ContentCreator';
import EditorForm from '../../components/EditorForm';
import Button from '../../components/Button';
import Config from '../../config';
import Placeholder from '../../assets/other/editor_placeholder.png';
import FancyButton from '../../components/FancyButton';
import axios from 'axios';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import FixedSpacing from '../../components/FixedSpacing';

const mapDispatchToProps = (dispatch) => {
    return {
        createPage: async (page) => dispatch(await Actions.Blog.createPage(page)),
        deletePage: async (id, token) => dispatch(await Actions.Blog.deletePage(id, token)),
        updatePage: async (page, id, type) => dispatch(await Actions.Blog.updatePage(page, id, type)),
        // loadPage: async () => dispatch(await action.Blog.loadPages()),
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.adminToken,
    }
}

const emptyState = { title: 'Naslov', url: Placeholder, description: '', category: '', type: 1, date: new Date(), content: [] };

class ArticleEditor extends React.Component {

    constructor() {
        super();
        this.state = {
            data: {},
            initialData: emptyState,
            editor: {
                show: false,
                data: { type: 0, text: '' }
            },
            loading: true
        }
        this.topRef = React.createRef();
    }

    saveState() {
        try {
            const serializedState = JSON.stringify(this.state.data);
            localStorage.setItem('Editor', serializedState);
        } catch (err) {

        }
    }

    clearState() {
        try {
            const serializedState = JSON.stringify(emptyState);
            localStorage.setItem('Editor', serializedState);
        } catch (err) {

        }
    }

    loadState() {
        try {
            const serializedState = localStorage.getItem('Editor');
            if (serializedState === null) {
                return emptyState;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return emptyState;
        }
    }

    componentDidUpdate(prevState) {
        if (prevState.data !== this.state.data) { // dodato zbog baga gde kada udjes u new article ucitava prosli ne new article
            this.saveState();
        }
    }
    componentWillUnmount() {
        this.clearState();
    }

    componentDidMount() {

        let localdata = this.loadState();

        let url = window.location.href;
        if (!url.includes('new_article')) {
            if (this.props.match.params.id + '' !== localdata.id + '') {
                axios.post(Config.Api.blog_load, {
                    id: this.props.match.params.id
                })
                    .then((response) => {
                        let data = response.data;

                        for (let i = 0; i < data.Content.length; i++) {
                            if (data.Content[i].type === 4) {
                                data.Content[i].url = data.Content[i].text;
                                delete data.Content[i].text;
                            }
                        }
                        data = { ...data, content: data.Content };
                        delete data.Content;
                        this.setState({ ...this.state, data, loading: false, initialData: data });
                    })
                    .catch((error) => {
                        alert(error);
                    });
            }
            else {
                this.setState({ ...this.state, data: localdata, loading: false });
            }

        }
        else {
            this.setState({ ...this.state, data: localdata, loading: false });
        }

        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }

    }

    findArticle() // type is a hack 
    {
        let id = this.props.match.params.id;
        if (id !== 'new_article') {
            if (this.props.articles) {
                try {
                    for (let i = 0; i < this.props.articles.length; i++) {
                        if (this.props.articles[i].id === id) {
                            this.setState({ ...this.state, data: this.props.articles[i], loading: false });
                        }
                    }
                } catch (err) {
                    // alert(err);
                    window.location.replace('/archives/1')
                }
            }
            else {
                window.location.replace('/archives/1')
            }
        }
        else {
            this.setState({ ...this.state, data: emptyState, loading: false });
        }
    }

    closeEditor() {
        this.setState({ ...this.state, editor: { show: false, data: this.state.editor.data } });
    }
    assignIndexToData(data) {
        let newData = data.content;
        for (let i = 0; i < data.content.length; i++) {
            // let id = Math.floor(Math.random() * 1000000);
            newData.push({ ...data.content[i], id: 1 });
        }
        return newData;
    }

    handleChange(object) {
        let editor = { show: true, data: object }
        this.setState({ ...this.state, editor });
    }
    addItem(object, index) {
        let data = this.state.data;
        let newData = [];
        let miniHack = -1;

        for (let i = 0; i < this.state.data.content.length; i++) {
            if (index === miniHack) {
                newData.push(object);
                miniHack = -2;
            }

            newData.push(data.content[i]);

            if (index === i) {
                newData.push(object);
            }
        }
        if (this.state.data.content.length === 0) {
            newData.push(object);
        }


        data.content = newData;
        this.setState({ ...this.state, data, editor: { show: false, data: this.state.editor.data } });
    }
    updateItem(object) {
        let data = this.state.data;
        switch (object.type) {
            case -5:
                data.date = object.text;
                break;
            case -4:
                data.type = object.text;
                break;
            case -3:
                data.category = object.text;
                break;
            case -2:
                data.url = object.text;
                break;
            case -1:
                data.description = object.text;
                break;
            case 0:
                if (object.text != '') {
                    data.title = object.text;
                }
                else {
                    data.title = 'Naslov';
                }
                break;
            case 1:
                data.content[object.index].text = object.text;
            case 3:
                data.content[object.index].text = object.text;
            case 4:
                data.content[object.index].url = object.url;

        }
        this.setState({ ...this.state, data, editor: { show: false, data: this.state.editor.data } });

    }
    removeItem(id) {
        let data = this.state.data;
        let newData = [];
        for (let i = 0; i < this.state.data.content.length; i++) {
            if (this.state.data.content[i].id !== id) {
                newData.push(data.content[i]);
            }
        }
        data.content = newData;
        this.setState({ ...this.state, data, editor: { show: false, data: this.state.editor.data } });

    }
    filterUndefined() {
        let data = this.state.data;
        for (let i = 0; i < data.length; i++) {
            if (data.content[i].url) {
                data.content[i].url === undefined ? data.content[i].url = 'a' : data.content[i].url = data.content[i].url
            }
        }
        return data;
    }
    changesMade() {

        return true;

    }
    handleSubmit(type) {
        let arg = this.filterUndefined();
        arg = { ...arg, authKey: this.props.token };
        if (type === 'delete') {
            if (window.confirm('Da li ste sigurni da želite da izbrišete ovaj artikl?')) {
                this.setState({ ...this.state, data: emptyState, loading: true });
                this.props.deletePage(this.props.match.params.id, this.props.token);
            } else {
                // Do nothing!
            }

        }
        if (type === 'add') {
            if (this.props.match.params.id === 'new_article') {
                this.props.createPage(arg);
                this.setState({ ...this.state, data: emptyState, loading: true });
            }
            else {
                this.props.updatePage(arg);
                this.setState({ ...this.state, data: emptyState, loading: true });
            }
        }
        if (type === 'cancel') {
            if (this.changesMade()) {
                window.location.replace('/#/admin/blog/1');
            }
        }
    }

    render() {
        return (
            <PageContainer regular scroll>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                {

                    !this.props.loading ?
                        <>
                            <Page>


                                <Title onClick={() => this.handleChange({ type: 0, text: this.state.data.title })}>
                                    {this.state.data.title}

                                </Title>
                                <FancyButton
                                    url={this.state.data.url}
                                    description={this.state.data.description}
                                    category={this.state.data.category}
                                    date={this.state.data.date}
                                    value={this.state.data.type}
                                    handleClick={(object) => this.handleChange(object)}
                                />
                                <ContentCreator data={this.state.data.content} handleChange={object => this.handleChange(object)} newChange={(object, index) => this.addItem(object, index)} />

                                <Options>
                                    <Inline>
                                        <ButtonWraper>
                                            <Button text={'Nazad'} onClick={() => this.handleSubmit('cancel')} inline />
                                        </ButtonWraper>
                                        {
                                            !(this.props.match.params.id === 'new_article') ?
                                                <ButtonWraper>
                                                    <Button text={'Izbriši'} onClick={() => { this.handleSubmit('delete') }} inline />
                                                </ButtonWraper>
                                                :
                                                <></>
                                        }
                                        <ButtonWraper>
                                            <Button text={'Potvrdi'} onClick={() => { this.handleSubmit('add') }} inline />
                                            {/* <Button text={'Potvrdi'} onClick={() => { this.testCall() }} inline /> */}
                                        </ButtonWraper>
                                    </Inline>
                                </Options>

                            </Page>
                            <EditorForm
                                show={this.state.editor.show}
                                data={this.state.editor.data}
                                closeEditor={() => this.closeEditor()}
                                saveChange={object => this.updateItem(object)}
                                deleteChange={index => this.removeItem(index)}
                            />
                        </>
                        :
                        <></>
                }
            </PageContainer >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEditor);