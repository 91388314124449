import styled from 'styled-components';

export const Container = styled.div`
margin-top: 150px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-self: center;
width: 1200px;
height: auto; 
justify-content: flex-start;
@media only screen and (max-width: 1400px) {
    width: 900px;
    margin-top: 60px;
}
@media only screen and (max-width: 910px) {
    width: 700px;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
}
@media only screen and (max-width: 720px) {
    width: auto;
    justify-content: center;
    flex-direction: column;
}
`;
export const Element = styled.div`
width: 33.3%;
display: flex;
flex-direction: row;
justify-content: center;
@media only screen and (max-width: 910px) {
    width: auto;
    justify-content: center;
    flex-direction: column;
    align-self: center;
}
`;