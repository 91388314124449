import styled, { keyframes } from 'styled-components';


export const Container = styled.div`
position: relative;
width: 100%;
overflow: hidden;
`;
export const Placeholder = styled.img`

`;
export const TextContainer = styled.div`
width: 100%; 
height: auto;
min-width: 100%; 
max-width: 100%;
color: #FFFFFF;
display: flex;
flex-direction: column;
@media only screen and (max-width: 800px) {
    width: 80%; 
    max-width: 80%;
    min-width: 80%;
    margin-left: 10%;
    margin-right: 10%; 
}
@media only screen and (max-width: 630px) {
    width: 60%; 
    max-width: 60%;
    min-width: 60%;
    margin-left: 20%;
    margin-right: 20%; 
}
`;
export const ReviewText = styled.p`
font-family: Lora;
font-style: normal;
font-weight: normal;
font-size: 27px;
line-height: 46px;
text-align: center;
display: flex;
align-self: center;
padding-left: 20%;
padding-right: 20%;
color: #005B30;
@media only screen and (max-width: 630px) {
    font-size: 17px; 
    line-height: 30px;
}
`;
export const PersonIcon = styled.img`
align-self: center;
display: flex;
margin-top: 20px;
width: 120px;
height: 120px;
border-radius: 100%;
`;

export const Content = styled.div`
width: 100%;
height: 100%;
position: absolute;
z-index:2;
top: 0;
display: flex;
flex-direction: column;
white-space: nowrap;
overflow: hidden;
@media only screen and (max-width: 1200px) {
    justify-content: space-between;
}
`;
export const Slider = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-right: 100px;
`;
export const Inline = styled.div`
display: inline;
height: 100%;
`;
export const RightArrowContainer = styled.div`
width: 20%;
height: 100%;
position: absolute;
top: 0;
right: 0;
@media only screen and (max-width: 1300px) {
    width: 15%;
}
@media only screen and (max-width: 1300px) {
    width: 15%;
}
@media only screen and (max-width: 1080px) {
    width: 110px;
}
`;
export const ProductText = styled.div`
width: 100%;
/* background-color: red; */
height: 100%;
position: absolute;
top: 0;
right: 0;
margin-left: 0;
`;
export const LeftArrowContainer = styled.div`
width: 20%;
height: 100%;
position: absolute;
top: 0;
@media only screen and (max-width: 1300px) {
    width: 15%;
}
@media only screen and (max-width: 1300px) {
    width: 15%;
}
@media only screen and (max-width: 1080px) {
    width: 110px;
}
@media only screen and (max-width: 630px) {
    /* border-radius: 50%; */
    /* background-color: rgba(196, 186, 186, 0.5); */
}
`;
export const LabelHolder = styled.div`
width: auto;
height: 20px;
/* background-color: red; */
position: absolute;
left: 25%;
bottom: 55%;
@media only screen and (max-width: 1111px) {
    bottom: 300px;
}
@media only screen and (max-width: 900px) {
    bottom: 320px;
}
@media only screen and (max-width: 525px) {
    bottom: 220px;
}
@media only screen and (max-width: 370px) {
    bottom: 140px;
}
`;

export const ProductName = styled.h1`
margin-top: 0%;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 60px;
color: #FFFFFF;
/* color: #b2ffa6; */
@media only screen and (max-width: 900px) {
    font-size: 50px;
}
@media only screen and (max-width: 525px) {
    font-size: 40px;
}
@media only screen and (max-width: 370px) {
    font-size: 30px;
}
`;

export const ProductDescription = styled.h1`
margin-top: -20px;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 32px;
color: #FFFFFF;
/* color: #b2ffa6; */
white-space:pre-line;
@media only screen and (max-width: 900px) {
    font-size: 26px;
}
@media only screen and (max-width: 525px) {
    font-size: 22px;
}
@media only screen and (max-width: 370px) {
    font-size: 18px;
}
`;
export const Arrow = styled.img`
width: 30px;
height: 75px;
position: absolute;
bottom: 40%;
right: 50px;
z-index: 2;
:hover{
    cursor: pointer;
    height: 90px;
    width: 36px;
    bottom: 39%;
    @media only screen and (max-width: 630px) {
    width: 20px;
    height: 50px;
}

}
@media only screen and (max-width: 630px) {
    width: 20px;
    height: 50px;
}
`;
export const ArrowRight = styled.img`
width: 30px;
height: 75px;
position: absolute;
bottom: 40%;
left: 50px;
z-index: 2;
:hover{
    cursor: pointer;
    height: 90px;
    width: 36px;
    bottom: 39%;
    @media only screen and (max-width: 630px) {
    width: 20px;
    height: 50px;
}
}
@media only screen and (max-width: 630px) {
    width: 20px;
    height: 50px;
}
`;

export const PseudoFirst = styled.div`
width: 100%;
position: relative;
`;
export const PseudoLast = styled.div`
width: 100%;
position: relative;
right: -200%;
`;

////////////////////  Animations ///////////////////////

const fadeOut = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( 100%);
  }
`;

// const fadeIn2 = keyframes`

// 0% { 
//    opacity: 0;
//   }
//   80%{
//       opacity: 1;
//   }
//   100%{
//       opacity: 1;
//   }
// `;

// const fadeIn = keyframes`
//   0% { 
//    opacity: 1;
//   }
//   80%{
//       opacity: 0;
//   }
//   100%{
//       opacity: 0;
//   }
// `;


const fadeOut2 = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( -100%);
  }
`;

export const Offset = styled.div`
position: relative;
width: auto;
display: flex;
flex-direction: row;
margin-right: 0px;
left: ${ props => { return '-' + (props.offset * 100) + '%'; }};
`;
export const JumbotronContainer = styled.div`
display: flex;
flex-direction: row;
`;
export const NonFlexContainer = styled.div`
display: normal;
`;
export const Fade = styled.div`
  display: inherit;
  animation: ${props => {
        if (props.outDirection === 'left') {
            if (props.out === true) { return fadeOut; }
        }
        if (props.outDirection === 'right') {
            if (props.out === true) { return fadeOut2; } //Fadeout2 org
            // else { return fadeIn2; }
        }

    }}
      0.51s linear;
  transition: visibility 0.51s linear;
  
`;
export const FadeLiterally = styled.div`
opacity: 1;
  display: inherit;
  /* visibility: ${props => props.out ? 'hidden' : 'visible'}; */
  animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;
  -webkit-animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;
      -moz-animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;

      @keyframes fadeIn {
        from {  opacity: 1; }
  80%{    opacity: 0; }
  to{ opacity: 0; }
      }
      @-webkit-keyframes fadeIn {
        from { 
   opacity: 1;
  }
  80%{
      opacity: 0;
  }
  to{
      opacity: 0;
  }
      }
      @-moz-keyframes fadeIn {
        from { 
   opacity: 1;
  }
  80%{
      opacity: 0;
  }
  to{
      opacity: 0;
  }
      }
      
      @keyframes fadeIn2 {
        from { 
   opacity: 0;
  }
  80%{
      opacity: 1;
  }
  to{
      opacity: 1;
  }
      }
      @-webkit-keyframes fadeIn2 {
        from { 
   -webkit-opacity: 0;
  }
  80%{
      -webkit-opacity: 1;
  }
  to{
      -webkit-opacity: 1;
  }
      }
      @-moz-keyframes fadeIn2 {
        from { 
   -webkit-opacity: 0;
  }
  80%{
      -webkit-opacity: 1;
  }
  to{
      -webkit-opacity: 1;
  }
      }
  
   transition: opacity .5s ease-in-out;
   -moz-transition: opacity .5s ease-in-out;
   -webkit-transition: opacity .5s ease-in-out;
  
`;
