import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
overflow: hidden;
border: 1px solid rgba(30, 145, 156, 0.95);
transition: height 0.8s;
color: 1px solid rgba( 168, 59, 227, 0.95 );

overflow: scroll;
::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}
:hover{
height: ${ props => { if (props.clicked === true) { return '150px'; } else { return '45px' } }};
}
margin-bottom: ${ props => { if (props.embeded === true) { return '20px'; } else { return '20px'; } }};
height: ${ props => { if (props.embeded === true) { return '30px'; } else { return '45px'; } }};
display: ${ props => { if (props.embeded === true) { if (props.active === true) { return 'block'; } else { return 'none'; } } else { return 'block'; } }};
`;

export const Inline = styled.div`
display: inline-block; 
`;
export const AddElementButton = styled.p`
font-family: "Montserrat";
font-style: normal;

display: inline-block;

font-size: 16px;
color:${ props => { if (props.selected === true) { return 'rgba( 168, 59, 227, 0.95 )'; } else { return 'rgba(23, 57, 105,0.95)'; } }}; 
border: ${ props => { if (props.selected === true) { return '1px solid rgba( 168, 59, 227, 0.95 )'; } else { return '2px solid rgba(23, 57, 105,0.95)'; } }}; 

padding-top: 10px;
padding-bottom: 10px;
margin-left: 10px;
padding-right: 10px;

width: 150px;
text-align: center;
:hover{
    cursor: pointer;
}
`;
export const Label = styled.p`
margin-top: 2px;
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
color: red;
display: block;
margin-left: 10px;

font-size: 17px;
color: rgba( 168, 59, 227, 0.95 );

`;