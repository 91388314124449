import styled from 'styled-components'

// export const Container = styled.div`
// position:absolute;
// top:0;
// left:0;
// /* background-color: rgba(1, 1, 1, 0.9); */ background-color:rgba( 1,1,1, 0.87);
// width: 82vw;
// z-index: 0;
// text-align: center;
// `
export const Page = styled.div`
text-align: left;
position: relative;
left: 20%;
width: 43%; /*width: 60%; */
margin-top: 10%;
padding-bottom: 10%;

@media only screen and ( max-width: 450px ){
    left: 13%;
    width: 75%;
}
@media only screen and ( max-width: 1920px ){
    margin-bottom: 0%;
}
@media only screen and ( max-width: 1600px ){
    margin-bottom: 15%;
}
@media only screen and ( max-width: 1100px ){
    margin-bottom: 25%;
}
@media only screen and ( max-width: 700px ){
    margin-bottom: 40%;
}
`

export const Title = styled.h1`
margin-top: 83px;
font-family: "Open sans";
font-style: normal;
font-weight: 600;
text-align: left;
font-size: 38px;
color: #005B30;
align-self: center;
@media only screen and ( max-width: 1112px ){
    font-size: 33px;
}
@media only screen and ( max-width: 834px ){
    font-size: 27px;
}
@media only screen and ( max-width: 414px ){
    font-size: 24px;
    padding-left:15%;
}
`
export const SubTitle = styled.h1`
font-family: "Open sans";
font-style: normal;
font-weight: normal;
text-align: left;
font-size: 15px;
margin-bottom: 50px;
color: #005B30;
align-self: center;
@media only screen and ( max-width: 1112px ){
    font-size: 13px;
}
@media only screen and ( max-width: 834px ){
    font-size: 13px;
}
@media only screen and ( max-width: 414px ){
    font-size: 11px;
    padding-left:15%;
}
`


export const ButtonWraper = styled.p`

color: white;
text-decoration-line: none;
display:${ props => { if (props.hide === true) { return 'none'; } else { return 'inline-block'; } }};
width: 120px;
margin-left: 10px;
`;

export const Options = styled.div`
width: 100%;
height: 50px;
margin-top: 65px;
white-space: nowrap;
text-align: left;

`;
export const Inline = styled.div`
display: inline;
white-space: nowrap;
text-align: left;

`;

export const FlexRow = styled.div`
margin-top: 20px;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-self: center;
width: 740px;

@media only screen and ( max-width: 1112px ){
    width: 663px;
};
@media only screen and ( max-width: 834px ){
    width: 92%;
};
`;

export const LeftGreenText = styled.p`
display: inline-block;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 14px;
align-self: flex-start;

color: #005B30;
:hover{
    cursor: pointer;
}
`;
export const RightGreenText = styled.p`
display: inline-block;
float: right;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 14px;
align-self: flex-start;

color: #005B30;
:hover{
    cursor: pointer;
}
`;

