import styled from 'styled-components'


export const Page = styled.div`
align-self: center;

font-style: normal;
font-weight: normal;
width: 740px;
height: auto;
margin-top: 30px;
margin-bottom: 100px;
font-size:16px;
@media only screen and ( max-width: 1112px ){
    width: 663px;
    font-size: 14px;
};
@media only screen and ( max-width: 834px ){
    font-size: 14px;
    width: 92%;
};
@media only screen and ( max-width: 414px ){
    font-size: 13px;
};
`

export const Title = styled.h1`
margin-top: 83px;
font-family: "Open sans";
font-style: normal;
font-weight: 600;
text-align: left;
font-size: 38px;
color: #005B30;
align-self: center;
:hover{
border: 1px solid rgb(228, 232, 111);
cursor: pointer; 
}
@media only screen and ( max-width: 1112px ){
    font-size: 33px;
}
@media only screen and ( max-width: 834px ){
    font-size: 27px;
}
@media only screen and ( max-width: 414px ){
    font-size: 24px;
    padding-left:15%;
}

`

export const Subtitle = styled.p`
font-weight: normal;
margin-top: -4%;
font-size: 1px;
`
export const ButtonWraper = styled.p`

color: white;
text-decoration-line: none;
display:${ props => { if (props.hide === true) { return 'none'; } else { return 'inline-block'; } }};
width: 120px;
margin-left: 10px;
`;

export const Options = styled.div`
width: 100%;
height: 50px;
margin-top: 50px;
white-space: nowrap;
text-align: right;

`;
export const Inline = styled.div`
display: inline;
white-space: nowrap;
text-align: right;
`;



