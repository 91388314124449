import React from 'react';
import { Container, AddElementButton, Inline, Label } from './styled';
import Placeholder from '../../assets/other/editor_placeholder.png';

class AddElement extends React.Component {

    state = { expanded: false };

    handleClick(type) {
        let object = 0;
        let id = Math.floor(Math.random() * 1000000);
        switch (type) {
            case 1:
                object = { text: 'Text', type, id };
                break;
            case 2:
                object = { type, id };
                break;
            case 3:
                object = { text: 'Podsekcija', type, id };
                break;
            case 4:
                object = { url: Placeholder, type, id };
                break;
        }
        this.props.handleClick(object);
    }

    expand() {
        this.setState({ expanded: !this.state.expanded });
    }
    //
    render() {
        return (
            <Container
                embeded={this.props.embeded}
                active={this.props.hoverMagic ? this.props.hoverMagic.id === this.props.hoverMagic.hoverId : false}
                onClick={() => this.expand()}
                clicked={this.state.expanded}
            >
                <Label>+ Dodaj element</Label>
                <Inline>
                    <AddElementButton onClick={() => this.handleClick(1)}>Paragraf</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(2)}>Razmak</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(3)}>Podsekcija</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(4)}>Slika</AddElementButton>
                </Inline>
            </Container>
        );
    }
}

export default AddElement;