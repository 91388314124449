import React from 'react';
import { Container, Content, Title, Trigger, Wrapper } from './styled';
class MainSection extends React.PureComponent {

    render() {
        return (
            <Trigger>
                <Container noMargin={this.props.noMargin} noBottom={this.props.noBottom} stretch={this.props.stretch}>
                    <Title font={this.props.font} custom={this.props.custom} weight={this.props.weight}>{this.props.title}</Title>
                    <Content>
                        {this.props.children}
                    </Content>
                </Container>
            </Trigger>
        )
    }
}

export default MainSection;