import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import Jumbotron from '../../components/Jumbotron';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import DescriptionNew from '../../components/DescriptionNew/DescriptionNew';
import DescriptionReversedNew from '../../components/DescriptionNew/DescriptionReversedNew';
import QASection from '../../components/QASection';
import styled from 'styled-components';
import FixedSpacing from '../../components/FixedSpacing';
import ContactMini from '../../components/ContactMini';
import ScrollToTop from '../../components/ScrollToTop';

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime,
        lang: state.language.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
const Padding = styled.div`
display: inline-flex;
flex-wrap: nowrap;
align-self: flex-start;
width: 700px; 
flex-direction: column;
display: -webkit-flex;
display: -moz-flex;
white-space: nowrap;
height: auto;

/* new */
text-align: center;
margin-left: 150px;

@media only screen and (max-width: 1250px) {
    width: 500px;
    margin-left: 125px;
}
@media only screen and (max-width: 850px) {
    height: auto;
    margin-left: 0px;
    justify-content: center;
    width: 95%;
    align-self: center;
}

`;
const PaddingGeneral = styled.div`
margin-top: 70px;
display: inline-flex;
flex-wrap: nowrap;
align-self: center;
width: 1000px; 
flex-direction: column;
display: flex;
/* background-color: red; */
display: -webkit-flex;
display: -moz-flex;
white-space: nowrap;
height: auto;

@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 90%;
    justify-content:center;
    height: auto;
}

`;
class Product extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            if (this.topRef.current) {
                this.topRef.current.scrollIntoView(
                    {
                        behavior: "instant",
                        block: "end"
                    }
                )
            }
        }
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }
    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <Jumbotron
                    background={Config.localdata.products[this.props.match.params.id].product_second_image}
                    mobileBackground={Config.images.product.background}
                />
                <div ref={this.topRef}></div>

                <DescriptionNew
                    title={this.props.lang === 'sr' ?
                        Config.localdata.products[this.props.match.params.id].name_sr
                        :
                        Config.localdata.products[this.props.match.params.id].name_en
                    }
                    subtitle={this.props.lang === 'sr' ?
                        Config.localdata.products[this.props.match.params.id].subtitle_sr
                        :
                        Config.localdata.products[this.props.match.params.id].subtitle_en
                    }
                    text={this.props.lang === 'sr' ?
                        Config.localdata.products[this.props.match.params.id].description_sr
                        :
                        Config.localdata.products[this.props.match.params.id].description_en
                    }
                    windowImage={Config.localdata.products[this.props.match.params.id].product_image}
                    special={Config.localdata.products[this.props.match.params.id].special}
                />
                <DescriptionReversedNew
                    title={this.props.lang === 'sr' ? 'SASTAV:' : 'COMPOSITION:'}
                    text={this.props.lang === 'sr' ?
                        Config.localdata.products[this.props.match.params.id].composition_sr
                        :
                        Config.localdata.products[this.props.match.params.id].composition_en
                    }
                    windowImage={Config.localdata.products[this.props.match.params.id].product_third_image}
                />
                <PaddingGeneral>
                    <Padding>
                        <ContactMini product={Config.localdata.products[this.props.match.params.id].name_sr} />
                    </Padding>
                </PaddingGeneral>
                <PaddingGeneral>
                    <Padding>
                    </Padding>
                </PaddingGeneral>
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);

//                  ovo je bilo izmedju dva paddinga      {/* <QASection questions={Config.localdata.products[this.props.match.params.id].subsection} lang={this.props.lang} /> */}
