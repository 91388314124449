import Config from '../../../config';
import axios from 'axios';

const createPage = async (page) => {
    return async (dispatch, getState) => {
        try {
            // alert(JSON.stringify(page));
            let newPage = page;
            let pageId = Math.random() * 1000000;
            // let pageId = 317328;
            newPage = { ...newPage, id: pageId, lang: 0 };
            for (let i = 0; i < newPage.content.length; i++) {
                // if (newPage.content[i].url === undefined) {
                //     newPage.content[i].url = '!';
                // } else { }
                if (newPage.content[i].hasOwnProperty('url') && newPage.content[i].type === 4) {
                    newPage.content[i] = { ...newPage.content[i], text: newPage.content[i].url };
                    delete newPage.content[i].url;
                }
                newPage.content[i] = { ...newPage.content[i], index: i, id: Math.random() * 1000000, page_id: pageId };
            }
            /// lol
            newPage = { ...newPage, Content: newPage.content };
            delete newPage.content;
            /////////////////////////////////////////
            newPage.date = Math.round(new Date(newPage.date).getTime() / 1000);
            axios.post(Config.Api.blog_new, {
                data: newPage,
            })
                .then((response) => {
                    window.location.replace('/#/admin/blog/1');
                }).catch(err => {
                    alert(err);
                });


        }
        catch (err) {
            alert(err);
        }
    }
}

const updatePage = async (page) => {
    return async (dispatch, getState) => {
        try {
            let newPage = page;
            newPage = { ...newPage };
            for (let i = 0; i < newPage.content.length; i++) {
                // if (newPage.content[i].url === undefined) {
                //     newPage.content[i].url = '!';
                // } else { }
                if (newPage.content[i].hasOwnProperty('url') && newPage.content[i].type === 4) {
                    newPage.content[i] = { ...newPage.content[i], text: newPage.content[i].url };
                    delete newPage.content[i].url;
                }
                newPage.content[i] = { ...newPage.content[i], index: i, id: Math.random() * 1000000, page_id: page.id };
            }
            /// lol
            newPage = { ...newPage, Content: newPage.content };
            delete newPage.content;
            /////////////////////////////////////////
            newPage.date = Math.round(new Date(newPage.date).getTime() / 1000);
            axios.post(Config.Api.blog_update, {
                data: newPage,
            })
                .then((response) => {
                    window.location.replace('/#/admin/blog/1');
                }).catch(err => {
                    alert(err);
                })
        }
        catch (err) {
            alert(err);
        }
    }
}

const deletePage = async (id, token) => {
    return async (dispatch, getState) => {
        try {
            axios.post(Config.Api.blog_delete, {
                data: { id, authKey: token },
            })
                .then((response) => {
                    window.location.replace('/#/admin/blog/1');
                });
        }
        catch (err) {
            alert(err);
        }
    }
}

function loadPagesId() {
    try {
        axios.get(Config.Api.blog_loadPagesId)
            .then(async (response) => {
                // dispatch({ type: 'LOAD_PAGES_ID', payload: response.ids });
                return await response.data.ids;
            });
    }
    catch (err) {
        alert(err);
    }
}
// function loadPages()


const loadPages = async () => {
    return async (dispatch, getState) => {

        try {
            axios.post(Config.Api.blog_load, {
                id: 2,
            })
                .then((response) => {

                });

            // dispatch({ type: 'BLOG_CREATE_ARTICLE', payload: page });
        }
        catch (err) {
            alert(err);
        }
    }
}

// const Auth = { createPage, loadPages, updatePage, deletePage };
const Blog = { createPage, loadPages, loadPagesId, deletePage, updatePage };

export default Blog;