import React from 'react';
import { Container, AddElementButton, Inline, Label } from './styled';
import Placeholder from '../../assets/other/editor_placeholder.png';

class DropDown extends React.PureComponent {

    state = { expanded: false, selected: '' };

    handleClick(object) {
        this.props.onChange(object);
        this.setState({ ...this.state, selected: object })
    }

    expand() {
        this.setState({ expanded: !this.state.expanded });
    }

    //
    componentDidMount() {
        this.setState({ ...this.state, selected: this.props.selected ? this.props.selected : this.props.items[0] });
    }
    render() {
        return (
            <Container
                embeded={this.props.embeded}
                active={this.props.hoverMagic ? this.props.hoverMagic.id === this.props.hoverMagic.hoverId : false}
                onClick={() => this.expand()}
                clicked={this.state.expanded}
            >
                <Label>✎ : Podešavanje stranice</Label>
                <Inline>{
                    this.props.items.map(element => {
                        return (<AddElementButton selected={this.props.selected === element.value} onClick={() => this.handleClick(element.value)}>{element.text}</AddElementButton>);
                    })
                }
                </Inline>
            </Container>
        );
    }
}

export default DropDown;