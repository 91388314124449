const Api = {
    blog_new: 'http://new.herbasvet.com/php/addpost.php',
    blog_load: 'http://new.herbasvet.com/php/getpost.php',
    blog_loadPagesId: 'http://new.herbasvet.com/php/getposts.php',
    blog_loadPages: 'http://new.herbasvet.com/php/getpostsbyid.php',
    blog_delete: 'http://new.herbasvet.com/php/deletepost.php',
    blog_update: 'http://new.herbasvet.com/php/updatepost.php',
    contact: 'http://new.herbasvet.com/php/contact.php',
    login: 'http://new.herbasvet.com/php/login.php',
    validate_token: 'http://new.herbasvet.com/php/checkLogin.php',
}

export default Api;