import React from 'react';
import { Container, Title, InputField, TextArea, Options, Element, Hack, DropDownContainer, Border } from './styled';
import Button from '../Button';
import DropDown from '../../components/DropDown';

class EditorForm extends React.Component {


    state = {
        loading: true
    }

    textSwitch(type) {
        let label = '';

        switch (type) {
            case -5:
                label = 'date'
                break;
            case -4:
                label = 'type'
                break;
            case -3:
                label = 'Tag'
                break;
            case -2:
                label = 'placeholder'
                break;
            case -1:
                label = 'description'
                break;
            case 0:
                label = 'title';
                break;
            case 1:
                label = 'paragraph';
                break;
            case 2:
                label = 'spacing';
                break
            case 3:
                label = 'subsection';
                break;
            case 4:
                label = 'image';
                break;
        }
        return label;
    }

    handleBr() {
        this.setState({ ...this.state, object: { type: 2 } });
    }

    handleChange(e) {
        let object = { text: '', url: '', type: 0 };
        switch (this.props.data.type) {
            case -5:
                // alert(e.target.value);
                object = { text: e.target.value, type: -5 };
                break;
            case -4:
                object = { text: e, type: -4 };
                break;
            case -3:
                // object = { text: e, type: -3 };
                object = { text: e.target.value, type: -3 };
                break;
            case -2:
                object = { text: e.target.value, type: -2 };
                break;
            case -1:
                object = { text: e.target.value, type: -1 };
                break;
            case 0:
                object = { text: e.target.value, type: 0 };
                break;
            case 1:
                object = { text: e.target.value, type: 1 };
                break;
            case 3:
                object = { text: e.target.value, type: 3 };
                break;
            case 4:
                // alert(JSON.stringify(e.target.files ? JSON.stringify(e.target.files[0]) : 0));
                object = { url: e.target.value, type: 4 };
                break;
        }
        object = { ...object, index: this.props.data.index };
        this.setState({ ...this.state, object });
    }

    handleSave() {

        if (this.props.data.type === 0 || this.props.data.type === 1 || this.props.data.type === 3 || this.props.data.type === -1 || this.props.data.type === -2 || this.props.data.type === -3 || this.props.data.type === -4 || this.props.data.type === -5) {
            if (this.state.object.text !== '') {

                this.props.saveChange(this.state.object);
                this.setState({ ...this.state, object: { text: '', url: '', type: this.props.data.type, id: this.props.data.id } });
            }
            else {
                alert('Polje ne sme biti prazno');
            }
        }

        if (this.props.data.type === 4) {
            if (this.state.object.url !== '') {
                this.props.saveChange(this.state.object);
                this.setState({ ...this.state, object: { text: '', url: '', type: this.props.data.type, id: this.props.data.id } });

            }
            else {
                alert('Polje ne sme biti prazno');
            }
        }
    }
    handleDelete() {
        this.props.deleteChange(this.props.data.id);
        this.setState({ ...this.state, object: { text: '', url: '', type: this.props.data.type, id: this.props.data.id } });
    }

    typeSwitch() {
        let editor = (<div></div>);
        let object = {};
        switch (this.props.data.type) {
            case -5:
                Date.prototype.addDays = function (days) {
                    var date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                }
                let dateCalculator = (dateParam) => {
                    let date = new Date(dateParam);
                    let dateValue = (date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2));
                    return dateValue;
                };

                let dateMax = new Date();
                dateMax = dateMax.addDays(10);
                let dateMaxValue = (dateMax.getFullYear() + '-' + ("0" + (dateMax.getMonth() + 1)).slice(-2) + '-' + ("0" + dateMax.getDate()).slice(-2));

                editor = (
                    <InputField type="date" id="date" name="datepicker"
                        value={dateCalculator(this.state.object.text)}
                        min="2017-01-01"
                        max={dateMaxValue}
                        onChange={(e) => this.handleChange(e)}
                    ></InputField>
                );
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case -4:
                editor = (
                    <DropDownContainer>
                        <DropDown items={[
                            { text: 'Engleski', value: 1 },
                            { text: 'Srpski', value: 2 }]}
                            onChange={(value) => this.handleChange(value)}
                            selected={this.state.object.text}
                        />
                    </DropDownContainer>
                )
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case -3:
                editor = (
                    <InputField value={this.state.object.text} placeholder={'TAG'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.url, type: this.props.data.type };
                break;
            case -2:
                editor = (
                    <InputField value={this.state.object.text} placeholder={'URL'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.url, type: this.props.data.type };
                break;
            case -1:
                editor = (
                    <TextArea value={this.state.object.text} placeholder={'Description'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case 0:
                editor = (
                    <InputField value={this.state.object.text} placeholder={'Title'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case 1:
                editor = (
                    <TextArea value={this.state.object.text} placeholder={'Paragraph'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case 3:
                editor = (
                    <InputField value={this.state.object.text} placeholder={'Subsection'} onChange={(e) => this.handleChange(e)} />
                );
                object = { id: this.props.data.id, text: this.props.data.text, type: this.props.data.type };
                break;
            case 4:
                editor = (
                    <div>
                        <InputField value={this.state.object.url} placeholder={'URL'} onChange={(e) => this.handleChange(e)} />
                        {/* <input type='file' onChange={(e) => this.handleChange(e)} /> */}
                    </div>
                );
                object = { id: this.props.data.id, url: this.props.data.url, type: this.props.data.type };
                break;
        }

        return editor;

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.state.object) {
            this.setState({ object: nextProps.data });
        }
        else {
            // alert('nece!')
        }
    }
    componentDidMount() {
        this.setState({ ...this.state, loading: false, object: this.props.data });
    }

    render() {
        return (
            <Container show={this.props.show}>
                <Title>Edit {this.textSwitch(this.props.data.type)}</Title>
                {
                    this.state.loading ? <div></div> : this.typeSwitch()
                }
                <Hack>
                    <Options>
                        <Element onClick={() => { this.props.closeEditor(); this.setState({ ...this.state, object: { text: '', url: '', type: this.props.data.type, id: this.props.data.id } }); }}>
                            <Button text={'Cancel'} />
                        </Element>
                        {Math.sign(this.props.data.type) === 1 ?
                            <Element onClick={() => this.props.data.type === 0 || this.props.data.type === -1 ? console.log('') : this.handleDelete()}>
                                <Button text={'Delete'} />
                            </Element>
                            :
                            <></>

                        }
                        {
                            this.props.data.type != 2 ?
                                < Element onClick={() => this.handleSave()}>
                                    <Button text={'Save'} />
                                </Element>
                                :
                                <div></div>
                        }
                    </Options>
                </Hack>
            </Container >
        );
    }
}

export default EditorForm;