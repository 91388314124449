import React from 'react';
import { Content, SubSection, Image, ImageContainer, Paragraph } from './styled';

class ContentGenerator extends React.Component {

    returnType(element) {
        console.log('ulazim  - ' + JSON.stringify(element));
        let result = (<div></div>);
        switch (element.type) {
            case 1:
                result = (<Paragraph style={{ whiteSpace: 'pre-line' }}>{element.text}</Paragraph>);
                break;
            case 2:
                result = (
                    <div>
                        <br />
                        <br />
                    </div>
                );
                break;
            case 3:
                result = (
                    <div>
                        <SubSection>{element.text}</SubSection>
                    </div>
                )
                break;
            case 4:
                result = (
                    <ImageContainer>
                        <Image src={element.url} alt='' />
                        <br />
                        <br />
                    </ImageContainer>
                )
                break;
            default:
                break;
        }
        return result;
    }

    render() {
        let result = (<Content></Content>);
        // alert(this.props.data);
        if (this.props.data) {
            result = (
                <Content>
                    {
                        this.props.data.map(element => {
                            return this.returnType(element);
                        })
                    }
                </Content>);
        }
        return result;
    }
}

export default ContentGenerator;