import React from 'react';
import Jumbotron from '../../components/Jumbotron';
import {
    Container,
    Placeholder,
    Content,
    RightArrowContainer,
    ProductText,
    Inline,
    LeftArrowContainer,
    LabelHolder,
    ProductName,
    ProductDescription,
    Arrow,
    ArrowRight,
    Slider,
    Fade,
    Offset,
    JumbotronContainer,
    NonFlexContainer,
    PseudoFirst,
    PseudoLast,
    FadeLiterally
} from './styled';
import Config from '../../config';
import Button from '../Button';
import FixedSpacing from '../FixedSpacing';

class ProductSlider extends React.PureComponent {

    state = {
        selectedIndex: 1,
        selectedIndexButton: 0,
        outDirection: 'right',
        animating: false,
        offset: 0,
        shift: false
    }
    componentDidMount() {
        window.setInterval(() => {
            this.changeToDirection('right');
        }, 5000);
    }

    changeToDirection(direction) {
        let index = 0;
        if (direction === 'right')
            index = (this.state.selectedIndex) < this.props.data.length ? (this.state.selectedIndex + 1) : 1;
        if (direction === 'left')
            index = (this.state.selectedIndex - 1) > 0 ? (this.state.selectedIndex - 1) : (this.props.data.length);
        this.handleClick(index, direction);
    } //i quit

    handleClick(index, direction) {
        this.setState({ ...this.state, selectedIndexButton: index, animating: true, outDirection: direction });
        window.setTimeout(() => {
            this.setState({
                ...this.state,
                selectedIndex: index,
                animating: false,
                outDirection: direction,
            })
            // alert(this.state.selectedIndex)
        }, 500)
    }

    render() {
        return (
            <Container>
                <Slider >
                    <Fade
                        out={this.state.animating}
                        outDirection={this.state.outDirection}
                        index={this.state.selectedIndex}
                        shift={this.state.shift}
                    >
                        <NonFlexContainer>
                            <Offset offset={this.state.selectedIndex}>
                                <JumbotronContainer>
                                    {
                                        this.props.data.map((element, index) => {
                                            if (index === 0) {
                                                return (
                                                    <>
                                                        <Jumbotron
                                                            background={this.props.data[this.props.data.length - 1].large_image}
                                                            backgroundMobile={this.props.data[this.props.data.length - 1].large_image_mobile}
                                                        />
                                                        <Jumbotron
                                                            background={element.large_image}
                                                            backgroundMobile={element.large_image_mobile}
                                                        />
                                                    </>
                                                )
                                            }
                                            if (index === (this.props.data.length - 1)) {
                                                return (
                                                    <>
                                                        <Jumbotron
                                                            background={element.large_image}
                                                            backgroundMobile={element.large_image_mobile}
                                                        />
                                                        <Jumbotron
                                                            background={this.props.data[0].large_image}
                                                            backgroundMobile={this.props.data[0].large_image_mobile}
                                                        />
                                                    </>
                                                )
                                            }
                                            return (
                                                <Jumbotron
                                                    background={element.large_image}
                                                    backgroundMobile={element.large_image_mobile}
                                                />
                                            );

                                        })
                                    }
                                </JumbotronContainer>
                            </Offset>
                        </NonFlexContainer>
                    </Fade>
                </Slider>
                <Content>
                    <Inline>
                        <LeftArrowContainer>
                            <Arrow src={Config.images.icon.ArrowLeft} onClick={() => this.changeToDirection('left')} />
                        </LeftArrowContainer>
                        <ProductText>
                            {/* <FixedSpacing /> */}
                            <FadeLiterally
                                out={this.state.animating}
                            >
                                <LabelHolder>
                                    <ProductName>{this.state.selectedIndex === 0 ? this.props.lang === 'sr' ? this.props.data[this.props.data.length - 1].name_sr : this.props.data[this.props.data.length - 1].name_en : this.props.lang === 'sr' ? this.props.data[this.state.selectedIndex - 1].name_sr : this.props.data[this.state.selectedIndex - 1].name_en}</ProductName>
                                    {/* <ProductName>{this.props.data[3].name_sr}</ProductName> */}
                                    <ProductDescription>{this.state.selectedIndex === 0 ? this.props.lang === 'sr' ? this.props.data[this.props.data.length - 1].subtitle_sr : this.props.data[this.props.data.length - 1].subtitle_en : this.props.lang === 'sr' ? this.props.data[this.state.selectedIndex - 1].subtitle_sr : this.props.data[this.state.selectedIndex - 1].subtitle_en}</ProductDescription>
                                    <Button
                                        text={this.props.lang === 'sr' ? 'Saznajte više' : 'Learn more'}
                                        // onClick={() => window.location.replace('/#/products/' + this.props.index)}
                                        onClick={() => this.state.selectedIndex === 0 ? window.location.replace('/#/products/' + 0) : window.location.replace('/#/products/' + (parseInt(this.state.selectedIndex) - 1))}
                                    />
                                </LabelHolder>
                            </FadeLiterally>
                        </ProductText>
                        <RightArrowContainer>
                            <ArrowRight src={Config.images.icon.ArrowRight} onClick={() => this.changeToDirection('right')} />
                        </RightArrowContainer>
                    </Inline>
                </Content>
            </Container>
        );
    }
}

export default ProductSlider;