import styled, { keyframes } from 'styled-components';


export const Container = styled.div`
position: relative;
`;
export const Placeholder = styled.img`

`;

export const Content = styled.div`
width: 100%;
height: 100%;
position: absolute;
z-index:2;
top: 0;
display: flex;
flex-direction: column;
white-space: nowrap;
overflow: hidden;
`;
export const Slider = styled.div`
display: flex;
flex-direction: row;
width: 100%;
margin-right: 100px;
`;
export const Inline = styled.div`
display: inline;
height: 100%;
`;
export const RightArrowContainer = styled.div`
width: 20%;
height: 100%;
position: absolute;
top: 0;
right: 0;
`;
export const ProductText = styled.div`
width: 100%;
/* background-color: red; */
height: 100%;
position: absolute;
top: 0;
right: 0;
margin-left: 0;
`;
export const LeftArrowContainer = styled.div`
width: 20%;
height: 100%;
position: absolute;
top: 0;
`;
export const LabelHolder = styled.div`
width: auto;
height: 310px;
background-color: rgba(0, 91, 48, 0.6);
/* background: rgba(100,167,10, 0.6); */
/* background: linear-gradient(90deg, rgba(100,167,10,1) 0%, rgba(0,91,48,1) 35%); */
border-radius: 1%;
position: absolute;
left: 25%;
bottom: 55%;
padding-right: 300px;
padding-left: 90px;
padding-top: 30px;
/* padding-bottom: 200px; */
/* transition: background-color 0.2s; */
top: 10%;
:hover{
    /* background-color: linear-gradient(90deg, rgba(100,167,10,1) 0%, rgba(0,91,48,1) 35%); */
}
@media only screen and (max-width: 1720px) {
    height: 220px;
    padding-right: 200px;
    padding-left: 30px;
    height: 210px;
}
@media only screen and (max-width: 900px) {
    height: 150px;
    padding-right: 140px;
}
@media only screen and (max-width: 525px) {
    width: 70%;
    left: 5%;
    padding-right: 30px;
    padding-left: 30px;
    
}
@media only screen and (max-width: 370px) {
    /* bottom: 140px; */
}
`;

export const ProductName = styled.h1`
margin-top: 0%;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 60px;
color: #FFFFFF;
/* color: #b2ffa6; */
@media only screen and (max-width: 1720px) {
    font-size: 37px;
}
@media only screen and (max-width: 900px) {
    font-size: 20px;
}
@media only screen and (max-width: 525px) {
    font-size: 19px;
}
@media only screen and (max-width: 370px) {
    font-size: 18px;
}
`;

export const ProductDescription = styled.h1`
margin-top: -20px;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 32px;
color: #FFFFFF;
/* color: #b2ffa6; */
white-space:pre-line;
@media only screen and (max-width: 1720px) {
    font-size: 24px;
}
@media only screen and (max-width: 900px) {
    font-size: 14px;
}
@media only screen and (max-width: 525px) {
    font-size: 13px;
}
@media only screen and (max-width: 370px) {
    font-size: 12px;
}
`;
export const Arrow = styled.img`
width: 30px;
height: 75px;
position: absolute;
bottom: 40%;
right: 50px;
z-index: 2;
:hover{
    cursor: pointer;
    height: 90px;
    width: 36px;
    bottom: 39%;

}
`;
export const ArrowRight = styled.img`
width: 30px;
height: 75px;
position: absolute;
bottom: 40%;
left: 50px;
z-index: 2;
:hover{
    cursor: pointer;
    height: 90px;
    width: 36px;
    bottom: 39%;
}
`;

export const PseudoFirst = styled.div`
width: 100%;
position: relative;
`;
export const PseudoLast = styled.div`
width: 100%;
position: relative;
right: -200%;
`;

////////////////////  Animations ///////////////////////

const fadeOut = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( 100%);
  }
`;

// const fadeIn2 = keyframes`

// 0% { 
//    opacity: 0;
//   }
//   80%{
//       opacity: 1;
//   }
//   100%{
//       opacity: 1;
//   }
// `;

// const fadeIn = keyframes`
//   0% { 
//    opacity: 1;
//   }
//   80%{
//       opacity: 0;
//   }
//   100%{
//       opacity: 0;
//   }
// `;


const fadeOut2 = keyframes`
/*  from {
    transform: scale(1);

    opacity: 0;
  }

  to {
    transform: scale(.9);

    opacity: 1;
  } */
  from { 
   transform: translateX( 0%);
  }
  to{
    transform: translateX( -100%);
  }
`;

export const Offset = styled.div`
position: relative;
width: auto;
display: flex;
flex-direction: row;
margin-right: 0px;
/* height: 780px; */
left: ${ props => { return '-' + (props.offset * 100) + '%'; }};
`;
export const JumbotronContainer = styled.div`
display: flex;
flex-direction: row;
/* height: 780px; */
/* width: 100%; */
`;
export const NonFlexContainer = styled.div`
display: normal;
/* height: 780px; */
/* width: 10000px; */
/* height: 780px; */
/* border: 1px solid red; */
/* overflow: hidden; */
`;
export const Fade = styled.div`
  display: inherit;
  /* visibility: ${props => props.out ? 'hidden' : 'visible'}; */
  animation: ${props => {
        if (props.outDirection === 'left') {
            if (props.out === true) { return fadeOut; }
        }
        if (props.outDirection === 'right') {
            if (props.out === true) { return fadeOut2; } //Fadeout2 org
            // else { return fadeIn2; }
        }

    }}
      0.51s linear;
  transition: visibility 0.51s linear;
  
`;
export const FadeLiterally = styled.div`
opacity: 1;
/* background-color: red; */
  display: inherit;
  /* visibility: ${props => props.out ? 'hidden' : 'visible'}; */
  animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;
  -webkit-animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;
      -moz-animation: ${props => {
        if (props.out === true) { return 'fadeIn'; } else { return 'fadeIn2' }
    }}
      0.5s linear;

      @keyframes fadeIn {
        from {  opacity: 1; }
  80%{    opacity: 0; }
  to{ opacity: 0; }
      }
      @-webkit-keyframes fadeIn {
        from { 
   opacity: 1;
  }
  80%{
      opacity: 0;
  }
  to{
      opacity: 0;
  }
      }
      @-moz-keyframes fadeIn {
        from { 
   opacity: 1;
  }
  80%{
      opacity: 0;
  }
  to{
      opacity: 0;
  }
      }
      
      @keyframes fadeIn2 {
        from { 
   opacity: 0;
  }
  80%{
      opacity: 1;
  }
  to{
      opacity: 1;
  }
      }
      @-webkit-keyframes fadeIn2 {
        from { 
   -webkit-opacity: 0;
  }
  80%{
      -webkit-opacity: 1;
  }
  to{
      -webkit-opacity: 1;
  }
      }
      @-moz-keyframes fadeIn2 {
        from { 
   -webkit-opacity: 0;
  }
  80%{
      -webkit-opacity: 1;
  }
  to{
      -webkit-opacity: 1;
  }
      }
  
   transition: opacity .5s ease-in-out;
   -moz-transition: opacity .5s ease-in-out;
   -webkit-transition: opacity .5s ease-in-out;
  
`;
