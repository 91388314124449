import React from 'react';
import {
    Container,
    FlexContainer,
    ProductImage,
    ImageView,
    Title,
    TextSection,
    SubTitle,
    Paragraph,
    BottomusAlignus
} from './styled';
import { GreenText } from '../_components'
import Button from '../Button';
import { shortenString } from '../../utils';

class ProductCard extends React.Component {
    state = {
        hover: true,
    }
    experimentalChange(changeTo) {

        this.setState({ hover: changeTo });
    }
    strToLowerCase(text) {
        if (text !== undefined && text !== null && text !== '') {
            if (text.length > 0) {
                return text.toLowerCase();
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }
    render() {
        return (
            <Container
                onMouseEnter={() => this.experimentalChange(false)}
                onMouseLeave={() => this.experimentalChange(true)}
            >
                <FlexContainer>
                    <ImageView>
                        <ProductImage src={this.props.productImage} />
                    </ImageView>
                    <TextSection>
                        <Title>{this.props.productName}</Title>
                        <SubTitle>{this.strToLowerCase(this.props.productSubtitle)}</SubTitle>
                        <Paragraph>{shortenString(this.props.productDescription, 100) + '..'}</Paragraph>
                        <BottomusAlignus>
                            <Button
                                text={this.props.lang === 'sr' ? 'Saznajte više' : 'Learn more'}
                                onClick={() => window.location.replace('/#/products/' + this.props.index)}
                                whiteMode={false}
                            />
                        </BottomusAlignus>
                    </TextSection>
                </FlexContainer>
            </Container>
        );
    }
}

export default ProductCard;