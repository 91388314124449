

import axios from 'axios';
import Config from '../../../config';

const Login = async (username, password) => {
    return async (dispatch, getState) => {
        try {

            axios.post(Config.Api.login, {
                data: { username, password },
            })
                .then((response) => {
                    let data = response.data
                    if (data !== 'false') {
                        dispatch({ type: 'LOGIN_SUCCESS', payload: data });
                    }
                    else {
                        dispatch({ type: 'LOGIN_FAILED', payload: '' });
                    }
                });
        }
        catch (err) {
            alert(err);
        }
    }
}

async function validateToken(token) {
    try {
        axios.post(Config.Api.validate_token, {
            data: { token },
        })
            .then((response) => {
                let data = response.data;
                data = data + '';
                if (data === 'true') {
                    // return response.data;
                    // window.location.replace('/#/admin/blog/1');
                    return true;
                }
                else {
                    window.location.replace('/#/admin/login');
                    return false;
                }
            }).catch(err => {
                alert(err);
            })
    }
    catch (err) {
        alert(err);
    }

}


const Auth = { Login, validateToken };

export default Auth;