import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: ${ props => { if (props.extended === true) { return '600px'; } else { return '500px'; } }};
background-color: #005B30;
display: flex;
flex-direction: row;
flex-wrap: wrap;
@media only screen and (max-width: 800px) {
flex-direction: column;
height: auto;
justify-content: center;
flex-wrap: wrap;
}
`;

export const LeftArrow = styled.div`
visibility: ${ props => { if (props.extended === true) { return 'hidden'; } else { return 'visible'; } }};
display: flex;
align-self: flex-start;
width: 10%;
height: 100%;
flex-direction: row;
@media only screen and (max-width: 1225px) {
    visibility: hidden;
width: 5%;
}
@media only screen and (max-width: 905px) {
width: 3%;
}
`;

export const RightArrow = styled.div`
visibility: ${ props => { if (props.extended === true) { return 'hidden'; } else { return 'visible'; } }};
display: flex;
flex-direction: row;
align-self: flex-end;
width: 10%;
height: 100%;
flex-direction: row;
@media only screen and (max-width: 1225px) {
visibility: hidden;
width: 5%;
/* width: 0%; */
}
@media only screen and (max-width: 905px) {
width: 3%;
}
`;
export const ImageHolder = styled.div`
width: 100%;
height: auto;
display: flex;
align-self: center;
flex-direction: column;
/* background-color: red; */
@media only screen and (max-width: 1025px) {

}
`;
export const ArrowImage = styled.img`
height: 50px;
width: 25px;
display: flex;
align-self: flex-end;
`;
export const ArrowImageRight = styled.img`
height: 50px;
width: 25px;
display: flex;
align-self: flex-start;
`;
export const Section1 = styled.div`
width: 50%;
/* height: 100%; */
display: flex;
flex-direction: column;
/* overflow: scroll; */
height: auto;
@media only screen and (max-width: 800px) {
align-self: center;
}
`;

export const Section2 = styled.div`
width: 30%;
height: 100%;
display: flex;
flex-direction: row;
@media only screen and (max-width: 1025px) {
margin-left: 100px;
}
@media only screen and (max-width: 800px) {
margin-left: 0px;
align-self: center;
width: 50%;
}
`;

export const TextSection = styled.div`
width: 650px;
/* margin-left: 100px; */
height: auto;
align-self: center;
@media only screen and (max-width: 1500px) {
width: 550px;
}
@media only screen and (max-width: 1225px) {
width: 450px;
}
@media only screen and (max-width: 905px) {
width: 400px;
}
@media only screen and (max-width: 800px) {
width: 100%;
}
`;
export const AnotherHolder = styled.div`
width: auto;
height: 100%;
display: flex;
flex-direction: row;
align-self: center;
`;

export const RightSectionHolder = styled.div`
display: flex;
flex-direction: column;
align-self: center;
width: 100%;
height: 85%;
@media only screen and (max-width: 1500px) {
height: 55%;
}
@media only screen and (max-width: 905px) {
height: 50%;
}
`;

export const WeedImage = styled.img`
border-radius: 50%;
display: flex;
align-self: center;
height: 100%;
width: auto;
@media only screen and (max-width: 800px) {
margin-top: 30px;
width: 80%;
height: auto;
}
`;
