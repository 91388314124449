import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import Jumbotron from '../../components/Jumbotron';
import MainSection from '../../components/MainSection';
import AboutSection from '../../components/AboutSection';
import AltAboutSection from '../../components/AboutSection/AltAboutSection';
import {
    PatrickHandLarge,
    GrayParagraph,
    GreenText,
    GreenOpenSansFlexCenter
} from '../../components/_components';
import Reviews from '../../components/Reviews';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import FixedSpacing from '../../components/FixedSpacing';
import styled from 'styled-components';
import ScrollToTop from '../../components/ScrollToTop';

const TopFlexContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;
const InnerFlexContaner = styled.div`
text-align: center; 
width: 70%;
align-self: center; 
@media only screen and (max-width: 1300px) {
    width: 85%;
}
@media only screen and (max-width: 850px) {
    width: 95%;
}
`;

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class About extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }

    filterProducts() {
        let data = []
        for (let i = 0; i < Config.localdata.products.length; i++) {
            if (Config.localdata.products[i].large_image !== 0) {
                data.push(Config.localdata.products[i]);
            }
        }
        return data;
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }
    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <Jumbotron
                    background={Config.images.about.background}
                    backgroundMobile={Config.images.about.background}
                />
                <MainSection title={'HERBA SVET'}>
                    <TopFlexContainer>
                        <InnerFlexContaner>
                            <PatrickHandLarge>{'KOMPANIJA KOJA POŠTUJE SVOJE NASLEĐE'}</PatrickHandLarge>
                            <GrayParagraph>
                                Herba Svet je moderna kompanija koja traje već 24 godine, sa preko 20 zaposlenih stručnjaka i spoljnih saradnika iz oblasti farmacije i fitoterapije. Proizvodimo kvalitetne dijetetske dodatke koji doprinose očuvanju zdravlja i prevenciji mnogih bolesti kod žena i muškaraca. Kompanija je dobitnik mnogobrojnih priznanja i sertifikata, što je ujedno i garancija kvaliteta svih naših proizvoda.
                        </GrayParagraph>
                            <GreenText>
                                Više od dve decenije sažimamo mudrost prirode u biljne formule i pomoću nauke i tradicije stvaramo originalne recepture koje u svojoj osnovi nose čistoću, snagu i moć
                        </GreenText>
                        </InnerFlexContaner>
                    </TopFlexContainer>
                </MainSection>
                <AboutSection
                    roundImage={Config.images.about.image1}
                    title={'Šta je naša ideja vodilja?'}
                    words={'Danas smo u neprekidnoj žurbi, preopterećeni obavezama i stalno pod stresom. Nemamo vremena da se posvetimo sebi i svom zdravlju. Baš zato osećamo potrebu da svoju često oskudnu ishranu dopunimo dijetetskim preparatima koji će povećati unos vitamina i minerala, i sve smo svesniji vrednosti lekovitog bilja. Upravo tu leži snaga Herba Sveta koji kroz svoje proizvode pruža najbolje iz prirode – fitoterapeutske preparate vrhunskog kvaliteta, bez veštačkih hormona, konzervanasa i aditiva.'}
                />
                {/* mod */}
                <AboutSection
                    reverse={true}
                    roundImage={Config.images.about.image2}
                    title={'Odajemo počast svojim korenima'}
                    words={'U našem narodu lekovito bilje se koristi od pamtiveka, a naše podneblje obiluje samoniklom vegetacijom, čistim proplancima i livadama na kojima su se vekovima skupljale biljke za razne tegobe. Ova znanja prenosila su se s kolena na koleno, a povremeno bi se pojavili nadareni vidari i lekari koji bi prikupljali ta znanja i zapisivali ih. Tako naši čuveni narodni lekari, Vasa Pelagić i Jovan Tucakov, posebno cene hajdučku travu koju, kako Pelagić kaže, ’zna gotovo svaki seljak bez sviju botanika i botaničara’, a u riznici Tucakova posebno mesto zauzimaju i crveni zdravac, virak, rusomača i neven. Herba Svet je čuvar korena: sva znanja i predanja o lekovitom bilju s našeg podneblja ozbiljno se razmatraju, ali im se pristupa na moderan način, u najsavremenijoj laboratoriji.'}
                />
                <AboutSection
                    roundImage={Config.images.about.image3}
                    title={'Verujemo da je bolje sprečiti nego lečiti '}
                    words={'Kompanija Herba Svet godinama unazad radi na podizanju svesti kod žena o važnosti prevencije kada je u pitanju rak grlića materice i koliko su neophodni redovni ginekološki pregledi. Često angažujemo stručna lica koja kroz različite medije na pristupačan način informišu o ginekološkim problemima i daju savete kako ih sprečiti. Neke od emisija koje se bave ovim temama možete pogledati i na našem Youtube kanalu.'}
                />
                <AboutSection
                    reverse={true}
                    roundImage={Config.images.about.image4}
                    title={'Razumemo sinergiju biljaka'}
                    words={'Lečenje lekovitim biljem je holističko, uvek ima u vidu uzajamno delovanje raznih biljaka i samog organizma. Biljne esencije često u organizmu podstiču sinteze koje stvaraju zdrave ćelije ili potrebne enzime. Tinkture, esencije i ekstrakti su najjača osnova biljnih preparata jer je u njima i najveća koncentracija lekovitih materija. Ponekad u jednoj tinkturi imamo koren jedne biljke, list druge, cvet treće... Kada bi se sve to stavilo u jednu čajnu mešavinu, ne bi bilo pravog efekta jer svaka od tih biljaka zahteva sopstveni način pripreme. Neke se prokuvavaju, neke potapaju preko noći, neke samo prelivaju ključalom vodom... Zato naši korisnici ne moraju da brinu o načinu pripreme. Dovoljno je da koriste Herba Svet preparate prema datom uputstvu i željeni rezultati će sigurno doći.'}
                />
                <AboutSection
                    roundImage={Config.images.about.image5}
                    title={'Sejemo danas za buduću žetvu'}
                    words={'Među petnaestak potpuno prirodnih proizvoda vrhunskog kvaliteta, posebno mesto zauzima Femisan A, već čuveni brend koji godinama ubire nagrade za kvalitet, a poznat je i van granica Srbije i Balkana. Ali ono što ga najviše ističe su brojna pozitivna iskustva korisnica koje su pomoću Femisana A ne samo uspešno rešile poremećaje reproduktivnog sistema i hormonalnog disbalansa, već su uspele da prevaziđu problem steriliteta, i pored beznadežnih lekarskih nalaza. Deca su naše najveće blago, a u vreme kada je sterilitet poprimio oblike epidemije, možemo slobodno reći da su bebe koje se rode zahvaljujući Femisanu A naša najveća nagrada i uspeh, i da sijaju blistavije od bilo kog pehara. Femisan A je naš ulog u budućnost.'}
                />
                <AboutSection
                    reverse={true}
                    roundImage={Config.images.about.image6}
                    title={'Volimo prirodu i brinemo o njoj'}
                    words={'Istraživati lekovitu moć bilja a ne brinuti o prirodi kao izvoru te moći za firmu poput Herba Sveta bio bi paradoks. Zbog toga Herba Svet od samog početka vodi računa o reciklaži svojih staklenih bočica, kao i plastičnih zatvarača. Sve se predaje na reciklažu bez otkupne nadoknade. Herba Svet kompanija je u ovom trenutku u procesu osmišljavanja postupka u kome bi se lekovito bilje nakon ekstrakcije koristilo kao pogonsko gorivo ili kao đubrivo. Sama proizvodnja ne stvara otpad koji bi se morao posebno odlagati, što se jasno vidi u godišnjim izveštajima o otpadu. Kao društveno odgovorna kompanija, Herba Svet pozdravlja uvođenje izveštaja o otpadu kao obaveze za sve proizvođače.'}
                />
                <GreenOpenSansFlexCenter>{this.props.text.about.partners_title}</GreenOpenSansFlexCenter>
                <Reviews
                    data={this.props.text.about.partners_content}
                    title={this.props.text.about.partners_title}
                    lang={this.props.text.lang} />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);