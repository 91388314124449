import React from 'react';
import { Container, Section, Image, Title, Padding, Header, Section2, Subtitle, Paragraph2, ImageSpecial } from './styled';
import Config from '../../config';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        nothing: state.language.about
    }
}

class DescriptionNew extends React.Component {
    render() {
        return (
            <Container >
                <Section2 first>
                    {this.props.special === true ?
                        <ImageSpecial src={this.props.windowImage} stretchImage={this.props.stretchImage} special={this.props.special} />
                        :
                        <Image src={this.props.windowImage} stretchImage={this.props.stretchImage} special={this.props.special} />
                    }
                </Section2>
                <Section graySpace={true}>
                    <Padding>
                        <Header>
                            <Title>{this.props.title}<br /></Title>
                            <Subtitle>{this.props.subtitle}</Subtitle>
                        </Header>
                        <Paragraph2 small={true}>{this.props.text}</Paragraph2>
                    </Padding>
                </Section>
            </Container>

        );
    }
}

export default connect(mapStateToProps, null)(DescriptionNew);