import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LargeHeight = '85px';

export const Container = styled.div`
z-index:5;
position: fixed;
/* top: 25px; */
width: 100%;
margin-top: 25px;
background-color: #005B30;
/* border-top: 7px solid #64A70A; */
height: ${LargeHeight};
display: flex;
flex-direction: column;
white-space: nowrap;
/* position: fixed; */
/* z-index: 4; */
@media only screen and (max-width: 950px) {
    height: 45px;
}
`;
export const PhoneText = styled.a`
text-decoration-style: none; 
text-decoration: none;
display: flex;
font-size: 13px;
font-family:"Open Sans";
margin-right: 15px;
color: white;
margin-top: 2px;
`;
export const PreContainer = styled.div`
width: 100%;
height: 25px;
position: fixed;
background-color: #64A70A;
z-index: 5;
display: flex;
flex-direction: row;
justify-content: flex-end;
`;

export const Logo = styled.img`
display: inline-block;
width: auto;
height: auto;
/* max-width: 220px; */
margin-top: 5px;
margin-left: 40px;
/* background-color: white; */
@media only screen and (max-width: 800px) {
    height: 100%;
    width: 25px;
}
@media only screen and (max-width: 500px) {
    /* max-width: 175px; */
    /* margin-top: 15px; */
}
@media only screen and (max-width: 375px) {
    /* max-width: 145px; */
    /* margin-top: 18px; */
}
`;

export const Inline = styled.div`
display: inline;
white-space: nowrap;
`;

export const Content = styled.div`
height: 100%;
margin-right: 15%;
float: right;
display: block;
width: auto;
white-space: nowrap;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}

`;
export const Element = styled(Link)`
text-decoration: none;
height: 100%;
width: auto;
position: absolute;
right: 55px;
top: 1px;
margin-left: 100px;
display: inline-block;
white-space: nowrap;

`;
export const Label = styled.p`
white-space: nowrap;
font-weight: bold;
font-family: "Open Sans";
color: #FFFFFF;
margin-top: 13px;
font-size: 15px;

:hover{
    cursor: pointer;
}
`;

export const Arrow = styled.img`
width: 15px;
height: 10px;
margin-left: 5px;
margin-top: 19px;
`;

export const MobileMenu = styled.img`
width: 20px;
height: 20px;
position: absolute;
right: 15px;
top: 12px;
`;

export const DropDown = styled.div`
display: ${ props => { if (props.show === true) { return 'flex'; } else { return 'none'; } }};
flex-direction: column;
position: fixed;
width: 120px;
    height: 150px;
right: 0;

/* background-color: rgba(86, 95, 110,0.5); */
background-color: #64A70A;
border-top: 0px solid gray;
border: 1px solid rgba(86, 95, 110 , 0.3);
text-align: left;
top: 70px;
z-index: 3;
`;

export const ListItem = styled(Link)`
font-size: ${ props => props.static === true ? '22px' : '19px'};
font-weight: ${ props => props.static === true ? 'bold' : 'normal'};
color: #FFFFFF;
padding-left: 20px;
text-decoration: none;
text-decoration-style: none;
margin-top: 10px;
margin-bottom: 10px;
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 14px;
}
:hover{
    cursor: pointer;
}
`;
export const LinkItem = styled(Link)`
text-decoration: none;
color: #FFFFFF;
`;

export const MenuItem = styled.p`
text-decoration: none;
font-size: 17px;
color: #FFFFFF;
padding-left: 20px;
margin-bottom: 5px;
font-family: 'Open Sans';
font-weight: normal;
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 19px;
}
:hover{
    cursor: pointer;
}
`;
export const SubContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
`;

export const SideDrawer = styled.div`
position: fixed;
display: flex;
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
flex-direction: column;
width: 150px;
overflow-y: scroll;
height: 100%;
z-index: 4;
right: 0;
top: 0px;
padding-top: 64px;
transform: ${ props => { if (props.open === true) { return 'translateX(0)'; } else { return 'translateX(100%)'; } }};
transition: transform 0.2s ease-in;
/* background-color: rgba(86, 95, 110,0.6); */
background-color: rgba(100, 167, 10, 1);
border-top: 0px solid gray;
/* border: 1px solid rgba(86, 95, 110 , 0.3); */
text-align: left;
padding-bottom: 60px;

`;


export const Row = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
height: auto;
width: 100%;

`;
export const SubRow = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
height: auto;
width: 100%;

`;
export const Line = styled.div`
width: 100%;
border-top: 1px solid #005B30; 
margin-bottom: 4px;
`;
export const Question = styled.h2`
font-family: "Open Sans"; 
font-size: 17px;
padding-left: 20px;
margin-bottom: 5px;
font-weight: normal;
/* border-bottom: 1px solid gray; */

color: #FFFFFF;
display: flex;
align-self: flex-start;
cursor: pointer;

-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const Answer = styled.p`
font-family: "Open Sans";
white-space: pre-line;
font-size: 18px;
color: #FFFFFF;
padding-left: 20px;
margin-bottom: 5px;
font-weight: normal;
/* border-bottom: 1px solid gray; */
color: #FFFFFF;
display: flex;
align-self: flex-start;

`;
export const RowReveal = styled.div`
text-align: start;
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 100%;
margin-bottom: ${ props => props.active ? '55px' : '7px'};
margin-top: ${ props => props.active ? '15px' : '5px'};
height: ${ props => props.active ? 'auto' : '0px'};
height: 0;
/* max-height: 300px; */
/* height: auto; */
opacity: 1;
overflow: hidden;
/* transform:scaleY(0); */
/* transform-origin:top; */
  /* transition:  0.3s ease-in; */
  ${Row}:hover & {
    opacity: 1;
    height: ${ props => props.active ? 'auto' : '0px'};
    /* transform: ${ props => props.active ? 'scaleY(1)' : 'scaleY(0)'};  */
  }
`;

export const Arrow2 = styled.img`
width: 20px;
margin-top: 17px;
height: 10px;
display: flex;
margin-left: 10px;
-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  cursor: pointer;
`;