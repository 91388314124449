import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import axios from 'axios';
import Config from '../../config';
import { Paragraph, RedText } from '../../styles';
import ReCAPTCHA from 'react-google-recaptcha';
import EmailValidator from 'email-validator';
import { connect } from 'react-redux';


const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
`;

export const InputField = styled.input.attrs({
    placeholderTextColor: "red"
})`
font-size: 16px;
width:96%;
padding-left: 15px;
font-family: 'Open Sans';
border-radius: 3px;
background-color: rgba(115, 114, 115, 0.0);
border: 1px solid rgba(90,90,90, 1);
color: #005B30;
height: 36px;
margin-top: 20px;
::-webkit-input-placeholder {
    color: #666666; 
}
`

export const TextArea = styled.textarea`
font-family: 'Open Sans';

font-size: 16px;
width:93%;
padding-top: 15px;
padding-right: 15px;
padding-left: 15px;
border-radius: 3px;
background-color: rgba(115, 114, 115, 0.0);
border: 1px solid rgba(90,90,90, 1);
color: #005B30;
height: 150px;
margin-top: 20px;
@media only screen and (max-width: 850px) {
    width: 96%;
}
@media only screen and (max-width: 400px) {
    width: 95%;
}
::-webkit-input-placeholder {
    color: #666666;}
`


export const Content = styled.div`
width: 100%;
text-align: left;
word-wrap: break-word ;
/* overflow: hidden; */
white-space: pre-line;
margin-top: 5px;
height: auto;
`;


export const Title = styled.h1`
align-self: flex-start; 
display: flex;
flex-direction: row;
color: #005B30;
font-size: 21px;
white-space: nowrap;
font-family: "Open Sans";
display: block;
width: auto;
font-weight: bold;

@media only screen and (max-width: 850px) {
 font-size: 16px;   
}
@media only screen and (max-width: 510px) {
  font-size: 15px;
}
@media only screen and (max-width: 400px) {
    font-size: 13px;
}
`;

const mapStateToProps = (state) => {
    return {
        text: state.language.contact
    }
}

class ContactMini extends React.PureComponent {
    state = {
        fullName: '',
        email: '',
        text: '',
        validEmail: true,
        validName: true,
        validText: true,
        recaptcha: false,

    }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.submitCaptcha = this.submitCaptcha.bind(this);
        this.emailRef = React.createRef();
        this.formRef = React.createRef();
        this.submit = this.submit.bind(this);
    }
    handleChange(whereTo, e) {
        if (whereTo === 'fullName') {
            this.setState({ fullName: e.target.value });
        }
        if (whereTo === 'email') {
            this.setState({ email: e.target.value });
        }
        if (whereTo === 'text') {
            this.setState({ text: e.target.value });
        }
    }
    submitCaptcha(response) {
        if (response) {
            this.setState({ ...this.state, recaptcha: true });
        }
    }
    onExpiredCaptcha() {
        this.setState({ ...this.state, recaptcha: false });
    }
    validateFunction() {
        let adjustments = {};
        // if (id === 'Email') {
        let valid = EmailValidator.validate(this.state.email);
        let finalValid = true;
        if (valid === true) {
            adjustments = { ...adjustments, validEmail: true };
        }
        else {
            adjustments = { ...adjustments, validEmail: false };
            finalValid = false;
        }
        // }
        // if (id === 'Name') {
        if (this.state.fullName.length > 2) {
            adjustments = { ...adjustments, validName: true };
        }
        else {
            adjustments = { ...adjustments, validName: false };
            finalValid = false;
        }
        // }
        // if (id === 'Text') {
        if (this.state.text.length > 50) {
            adjustments = { ...adjustments, validText: true };
        }
        else {
            adjustments = { ...adjustments, validText: false };
            finalValid = false;
        }
        // }

        this.setState({ ...this.state, ...adjustments });
        return finalValid;
    }
    submit() {
        if (this.validateFunction() === true) {
            try {
                const data = {
                    name: this.state.fullName,
                    email: this.state.email,
                    text: this.state.text,
                    proizvod: 'Kontakt strane'
                }
                axios.post(Config.Api.contact, {
                    data,
                })
                    .then((response) => {
                        alert('Poruka je uspešno poslata!')
                        this.setState({
                            ...this.state,
                            fullName: '',
                            email: '',
                            text: '',
                        })
                    });
            }
            catch (err) {
                // alert('');
            }
        }
        else {
        }
    }
    render() {
        return (
            <Container>
                <Title>KONTAKTIRAJTE NAS</Title>
                <Content>
                    {'Sve što vas zanima u vezi ovog proizvoda možete da nas pitate. Neko od naših zaposlenih će Vam odgovoriti u najkraćem mogućem roku'}
                </Content>
                <InputField type='text' name='name' oninvalid='invalid' placeholder={'Ime i prezime'} required onChange={e => this.handleChange('fullName', e)} id="Name" value={this.state.fullName} />
                {/* <Paragraph>Invalid email address</Paragraph> */}
                <InputField type='text' name='email' oninvalid='invalid 2' placeholder={'Email'} required onChange={e => this.handleChange('email', e)} id="Email" value={this.state.email} /> {/*value={this.state.email} onChange={this.onChange}*/}
                {/* <Paragraph>Invalid email address</Paragraph> */}
                <TextArea type='text' name='message' oninvalid='invalid 3' placeholder={'Poruka'} required onChange={e => this.handleChange('text', e)} id="Text" value={this.state.text} />
                <Paragraph><RedText>{!this.state.validName ? this.props.text.nameError : ''} </RedText></Paragraph>
                <Paragraph><RedText>{!this.state.validEmail ? this.props.text.emailError : ''}</RedText></Paragraph>
                <Paragraph><RedText>{!this.state.validText ? this.props.text.textError : ''}</RedText></Paragraph>
                {
                    this.state.error !== 1 && this.state.recaptcha ?
                        // true ?
                        <div style={{ width: '75px' }}>
                            <Button text={this.props.text.send} onClick={() => this.submit()} type='button' />
                        </div>

                        :
                        <></>
                }
                {
                    !this.state.recaptcha ?
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom: '30px', marginTop: '20px' }}>
                            <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row' }}>
                                <ReCAPTCHA
                                    render="explicit"
                                    sitekey="6LcmprQZAAAAAGdULX4jtfXkfRDyRdAeV7IzLjwc"
                                    onVerifyCallBack={this.submitCaptcha}
                                    onChange={this.submitCaptcha}
                                    onExpired={this.onExpiredCaptcha}
                                />
                            </div>
                        </div>
                        :
                        <></>
                }
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(ContactMini);