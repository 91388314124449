import styled from 'styled-components';

export const Content = styled.div`
align-self: center;
/* margin-top:100px; */
/* font-family: "Montserrat"; */
font-style: normal;
font-weight: normal;
width: 740px;
/* color: rgba(255, 255, 255, 0.8); */
/* overflow-y: auto; */
 /* min-height: 100vh;  */
font-size:16px;
@media only screen and ( max-width: 1112px ){
    width: 663px;
    font-size: 14px;
};
@media only screen and ( max-width: 834px ){
    font-size: 14px;
    width: 92%;
};
@media only screen and ( max-width: 414px ){
    font-size: 13px;
};
`
export const Paragraph = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 29px;
white-space: pre-line;
margin-bottom: 30px;
color: #000000;
`;

export const SubSection = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
/* identical to box height */

color: #005B30;


/* overflow-y: auto; */
font-size:26px;
/* margin-bottom: 20px; */
@media only screen and ( max-width: 1500px ){
    font-size:23px;
};
@media only screen and ( max-width: 1112px ){
    font-size: 20px;
};
@media only screen and ( max-width: 834px ){
    font-size: 17px;
};
@media only screen and ( max-width: 414px ){
    font-size: 15px;
};
`;

export const Image = styled.img`
align-self: flex-start;
flex-direction: row;
max-width: 100%;
/* max-height: 550px; */
width: 100%;
height: auto;
margin-bottom: 20px;

@media only screen and ( max-width: 1400px ){
    /* max-height: 450px; */
};
@media only screen and ( max-width: 1100px ){
    /* max-height: 400px; */
};
@media only screen and ( max-width: 780px ){
    /* max-height: 280px; */
};
`;
export const ImageContainer = styled.div`
width: 100%;
text-align: center;
display: flex;
flex-direction: column;
flex-wrap: wrap;
@media only screen and ( max-width: 780px ){
    width: 90%;
};
`;