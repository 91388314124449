import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        text: state.language.trademark
    }
};

const Container = styled.div`
width: 100%;
background-color: #005B30; 
display: flex;
flex-direction: column;
height: 78px;
white-space: nowrap;
text-align: center;
@media only screen and (max-width: 1250px) {
    height: 65px;
}
`;

export const SubContainer = styled.div`
width: 100%;
background-color: #64A70A;
height: 25px;
flex-direction: column;
display: flex;
white-space: nowrap;
text-align: center;
@media only screen and (max-width: 1264px) {
    height: 25px;
}
`;

const Label = styled(Link)`
text-decoration: none;
vertical-align: center;
text-align: center;
display: flex;
align-self: center;
font-size: 14px;
color: #FFFFFF;
margin-top: 25px;
white-space: pre-line;
@media only screen and (max-width: 1250px) {
    margin-top: 18px;
}
`;
const Label2 = styled(Link)`
text-decoration: none;
vertical-align: center;
text-align: center;
display: flex;
align-self: center;
word-spacing: 15px;
font-size: 14px;
color: #FFFFFF;
margin-top: 2px;
white-space: pre-line;
@media only screen and (max-width: 1250px) {
    /* margin-top: 15px; */
}
`;

class Footer extends React.Component {
    render() {
        return (
            <div>
                <SubContainer>
                    <Label2>{this.props.text.sponsors}</Label2>
                </SubContainer>
                <Container>
                    <Label to='/copyright' onClick={() => window.scrollTo(0, 0)}>{this.props.text ? this.props.text.text : ''}</Label>
                </Container>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(Footer);