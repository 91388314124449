import React from 'react';
import { Container, Section, Image2, Padding, Header, Section3, TitleLesser, Paragraph2 } from './styled';
import DescriptionNew from './DescriptionNew';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        nothing: state.language.about
    }
}

class DescriptionReversedNew extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            windowWidth: 0,
            userName: ''
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }
    render() {
        if (this.state.windowWidth > 850) {
            return (

                <Container reversed={true}>
                    <Section graySpace={true}>
                        <Padding>
                            <Header>
                                <TitleLesser>{this.props.title}<br /></TitleLesser>
                            </Header>
                            <Paragraph2 small={true}>{this.props.text}</Paragraph2>
                        </Padding>
                    </Section>
                    <Section3 first>
                        <Image2 src={this.props.windowImage} />
                    </Section3>
                </Container>

            );
        }
        else {
            return <DescriptionNew title={this.props.title} text={this.props.text} windowImage={this.props.windowImage} stretchImage={true} />
        }

    }
}

export default connect(mapStateToProps, null)(DescriptionReversedNew);