import React from 'react';
import {
    Container,
    Label,
    Content,
    Inline,
    Element,
    Logo,
    DropDown,
    ListItem,
    SubContainer,
    Arrow,
    ElementSmall,
    TopLabel
} from './styled';

import LogoImage from '../../../assets/logo/logo.png';

import { connect } from 'react-redux';
import Actions from '../../../store/Actions';

import axios from 'axios';
import Config from '../../../config';

const mapStateToProps = (state) => {
    return {
        lang: state.language
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        languageSetSerbian: () => dispatch(Actions.changeLanguage.serbian()),
        languageSetEnglish: () => dispatch(Actions.changeLanguage.english()),
        languageSetGerman: () => dispatch(Actions.changeLanguage.german()),
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        loadAllPages: () => dispatch(Actions.Blog.loadPagesId()),
    }
}

class Desktop extends React.Component {
    state = {
        loading: false,
        dropdown: false,
        selectedIndex: -1,
    }
    handleLanguage(lang) {
        if (lang === 'en') {
            this.props.languageSetEnglish();
        }
        if (lang === 'sr') {
            this.props.languageSetSerbian();
        }
        this.setState({ ...this.state, dropdown: false });
    }
    showLanguage() {
        this.setState({ ...this.state, dropdown: !this.state.dropdown });
    }
    componentDidMount() {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    switch (data.country_name) {

                        case 'United Kingdom':
                            this.props.languageSetEnglish();
                            break;
                        case 'Serbia':
                            this.props.languageSetSerbian();
                            break;
                        default:
                            this.props.languageSetEnglish();

                    }

                }).catch((error) => {
                    console.log(error);
                });
                this.setState({ ...this.state, loading: false });
            }
            else {
                this.setState({ ...this.state, loading: false });
            }
            // this.props.loadAllPages(); TO BE FIXED
        }
        catch (err) {
            alert(err)
            this.props.languageSetEnglish();
            this.setState({ ...this.state, loading: false });
        }
    }
    handleHoverIn(index) {
        if (index === 3) {
            this.setState({ ...this.state, selectedIndex: index, dropdown: true })
        }
        else {
            this.setState({ ...this.state, selectedIndex: index, dropdown: false })
        }
    }
    handleHoverOut(index) {
        this.setState({ ...this.state, selectedIndex: -1 });
    }
    hoverOutDropdown() {
        this.setState({ ...this.state, dropdown: false });
    }
    render() {
        return (
            <>
                {
                    !this.state.loading && this.props.lang ?
                        <div style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
                            <SubContainer>
                                <Content>
                                    <Inline>
                                        {/* <Arrow src={Config.images.navigation.PhoneIcon} /> */}
                                        <ElementSmall><i class="fas fa-phone" style={{ color: 'white', marginRight: '-30px' }}></i></ElementSmall>
                                        <ElementSmall ><TopLabel>{this.props.lang.navigation.phone}</TopLabel></ElementSmall>
                                        <ElementSmall onClick={() => this.handleLanguage('en')}><TopLabel>{this.props.lang.navigation.english}</TopLabel></ElementSmall>
                                        <ElementSmall onClick={() => this.handleLanguage('sr')}><TopLabel>{this.props.lang.navigation.serbian}</TopLabel></ElementSmall>
                                    </Inline>
                                </Content>
                            </SubContainer>
                            <Container>
                                <Inline>
                                    <Logo src={LogoImage} />
                                    <Content>
                                        <Inline>
                                            <Element to='/' onMouseEnter={() => this.handleHoverIn(0)} onMouseLeave={() => this.handleHoverOut(0)}><Label isSelected={this.state.selectedIndex === 0 ? true : false} >{this.props.lang.navigation.home}</Label></Element>
                                            <Element to='/about' onMouseEnter={() => this.handleHoverIn(1)} onMouseLeave={() => this.handleHoverOut(1)} onClick={() => window.scrollTo(0, 0)}><Label isSelected={this.state.selectedIndex === 1 ? true : false}>{this.props.lang.navigation.about}</Label></Element>
                                            <Element to='/herbs' onMouseEnter={() => this.handleHoverIn(2)} onMouseLeave={() => this.handleHoverOut(2)} ><Label isSelected={this.state.selectedIndex === 2 ? true : false}>{this.props.lang.navigation.plants}</Label></Element>
                                            <Element to='/products' onMouseEnter={() => this.handleHoverIn(3)} onMouseLeave={() => this.handleHoverOut(3)} ><Label isSelected={this.state.selectedIndex === 3 ? true : false}>{this.props.lang.navigation.products}</Label></Element>
                                            <Element to='/certificate' onMouseEnter={() => this.handleHoverIn(4)} onMouseLeave={() => this.handleHoverOut(4)} ><Label isSelected={this.state.selectedIndex === 4 ? true : false}>{this.props.lang.navigation.certificate}</Label></Element>
                                            <Element to='/questions' onMouseEnter={() => this.handleHoverIn(5)} onMouseLeave={() => this.handleHoverOut(5)} ><Label isSelected={this.state.selectedIndex === 5 ? true : false}>{this.props.lang.navigation.questions}</Label></Element>
                                            <Element to='/blog/1' onMouseEnter={() => this.handleHoverIn(6)} onMouseLeave={() => this.handleHoverOut(6)} ><Label isSelected={this.state.selectedIndex === 6 ? true : false}>{this.props.lang.navigation.blog}</Label></Element>
                                            <Element to='/contact' onMouseEnter={() => this.handleHoverIn(7)} onMouseLeave={() => this.handleHoverOut(7)} ><Label isSelected={this.state.selectedIndex === 7 ? true : false}>{this.props.lang.navigation.contact}</Label></Element>
                                        </Inline>
                                    </Content>
                                </Inline>
                            </Container>
                            <SubContainer>
                                {/* <DropDown show={this.state.dropdown}> */}
                                <DropDown show={this.state.dropdown} onMouseLeave={() => this.hoverOutDropdown()}>
                                    <ListItem onClick={() => console.log()} static={true}>KAPI</ListItem>
                                    <ListItem to='/products/0'>Femisan A</ListItem>
                                    <ListItem to='/products/1'>Femisan B</ListItem>
                                    <ListItem to='/products/4'>Nefrovit</ListItem>
                                    <ListItem to='/products/5' onClick={() => console.log()}>Leocardin</ListItem>
                                    <ListItem to='/products/6' onClick={() => console.log()}>Hipoprostat</ListItem>
                                    <ListItem to='/products/7' onClick={() => console.log()}>Equigal</ListItem>
                                    <ListItem to='/products/8' onClick={() => console.log()}>Optima Forma</ListItem>
                                    <ListItem to='/products/9' onClick={() => console.log()}>Disan</ListItem>
                                    <ListItem onClick={() => console.log()} static={true}>KAPSULE</ListItem>
                                    <ListItem to='/products/2' onClick={() => console.log()}>Femisan A plus zlatna maca</ListItem>
                                    <ListItem to='/products/3' onClick={() => console.log()}>Alfa aktiv</ListItem>
                                    <ListItem onClick={() => console.log()} static={true}>HS KOZMETIKA</ListItem>
                                    <ListItem to='/products/10' onClick={() => console.log()}>za negu peta</ListItem>
                                    <ListItem to='/products/11' onClick={() => console.log()}>za regeneraciju kože</ListItem>
                                    <ListItem to='/products/13' onClick={() => console.log()}>za dekolte</ListItem>
                                    <ListItem to='/products/12' onClick={() => console.log()}>protiv virusnih bradavica</ListItem>
                                </DropDown>
                            </SubContainer>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
