import React from 'react';
import './App.css';
import 'core-js/stable';
import 'react-app-polyfill/ie11';
import { AppContainer } from './styles';
import Main from './Main'
import store from './store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store} >
      <AppContainer>
        <Main />
      </AppContainer>
    </Provider>
  );
}

export default App;
