import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import ProductCard from '../../components/ProductCard';
import Config from '../../config';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import ProductContent from '../../components/ProductContent';
import FixedSpacing from '../../components/FixedSpacing';
import ScrollToTop from '../../components/ScrollToTop';

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Products extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }
    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <ProductContent lang={this.props.text.lang} />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);