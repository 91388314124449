import styled from 'styled-components';

export const Container = styled.div`
display: ${props => { if (props.show === true) { return; } else { return 'none'; } }};
position: absolute;
top: 25%;
margin-left: 30%;
background-color: rgba(15, 15, 14 , 1);
border: 1px solid rgba(156, 156, 146 , 0.8);
border-radius: 3px;
z-index: 1;
width: 43%; /*width: 60%; */


@media only screen and ( max-width: 450px ){
    left: 13%;
    width: 75%;
}
`;
export const Border = styled.div`
background-color: red;
border-top: 25px solid rgba(0, 91, 48, 0.0);
`;
export const Title = styled.p`
color: #005B30;
font-family: "Open Sans";
font-style: normal;
margin-top: 0;
padding-top: 20px;
font-size: 32px;
padding-left: 20px;
`;

export const InputField = styled.input`
padding-top: 10px;
padding-bottom: 10px;
padding-left: 10px;
margin-left: 20px;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
width:70%;
background-color: rgba(115, 114, 115, 0.2);
border: none;
color: #f0eee9;
`

export const TextArea = styled.textarea`
padding-top: 10px;
padding-bottom: 10px;
padding-left: 10px;
width:92%;
background-color: rgba(115, 114, 115, 0.2);
border: none;
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
resize: none;
margin-left: 2.5%;
min-height: 200px;
color: #f0eee9;
`
export const Options = styled.div`
display: flex;
flex-direction: column;
display: inline;
justify-content: flex-start;

margin-left: 20px;
`;
export const Hack = styled.div`
width: 100%;
margin-top: 50px;
margin-bottom: 50px;
`;
export const Element = styled.div`
display: inline-block;
width: 80px;
:hover{
    cursor: pointer;
}
`;

export const DropDownContainer = styled.div`
width: 50%;
padding-left:22px;
`;
