import styled from 'styled-components';

export const MarginTop = styled.div`
margin-top: 145px;
margin-bottom: 30px;
`;
export const Element = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
overflow: hidden;
`;
export const TextElement = styled.div`
margin-top: 20px;
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
overflow: hidden;
margin-bottom: 0;
`;

export const Section = styled.div`
width: 70%;
margin-left: 15%;
@media only screen and (max-width: 850px) {
    width: 95%;
    margin-left: 2.5%;
    /* text-align: left; */
}
@media only screen and (max-width: 520px) {
    display: block;
    text-align: center;
}
`;
export const Text = styled.p`
font-family: "Open Sans";
font-size: 14px; 
color: #57695a;
display: block; 
/* display: flex; */
float: ${ props => { switch (props.align) { case 'left': { return 'left'; } case 'right': { return 'right'; } default: { return 'left'; } } }};
transition: color 0.2s;
:hover{
    color: #64A70A;
    cursor: pointer;
}
@media only screen and (max-width: 520px) {
    font-size: 14px;
    float: left;
    display: inline-block;
    width: 100%;
}
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
align-self: center;
flex-wrap: nowrap;
width: 640px;
@media only screen and (max-width: 850px) {
    width: 400px;
    /* text-align: left; */
}
@media only screen and (max-width: 510px) {
    width: 350px;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;

export const SubContainer = styled.div`
display: flex;
flex-direction: column;
align-self: center;
`;
export const Inline = styled.div`
display: flex;
flex-direction: row;
align-self: center;
justify-content: space-between;
height: 75px;
@media only screen and (max-width: 850px) {
    height: auto;
}
`;
export const FacebookIcon = styled.img`
display: inline-block;
flex-direction: row;
margin-right: 30px;
width: 50px;
align-self: center;
:hover{
    cursor: pointer;
}
`;
export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;
export const AwsomeFont = styled.p`
font-size: 55px;
color: #64A70A;
align-self: center;
margin-bottom: 0px;
margin-top: 5px;
transition: font-size 0.3s;
:hover{
    cursor: pointer;
    font-size: 58px;
}
`;
export const LazySpacing = styled.div`
width: 25px;
`;