import React from 'react';
import { Container, AddElementButton, Inline, Label } from './styled';
// import Placeholder from '../../assets/Background/CardPlaceholder.png';

class DropDown extends React.Component {

    state = { expanded: false };

    handleClick(type) {
        let object = 0;
        let id = Math.floor(Math.random() * 1000000);
        switch (type) {
            case -1:
                object = { text: this.props.description, type, id };
                break;
            case -2:
                object = { text: this.props.url, type, id };
                break;
            case -3:
                object = { text: this.props.category, type, id };
                break;
            case -4:
                object = { text: this.props.value, type, id };
                break;
            case -5:
                object = { text: this.props.date, type, id };
                break;
        }
        this.props.handleClick(object);
    }

    expand() {
        this.setState({ expanded: !this.state.expanded });
    }
    //
    render() {
        return (
            <Container
                embeded={this.props.embeded}
                active={this.props.hoverMagic ? this.props.hoverMagic.id === this.props.hoverMagic.hoverId : false}
                onClick={() => this.expand()}
                clicked={this.state.expanded}
            >
                <Label>✎ : Podešavanje stranice</Label>
                <Inline>
                    <AddElementButton onClick={() => this.handleClick(-1)}>Opis</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(-2)}>Naslovna slika</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(-3)}>Kategorija</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(-4)}>Jezik</AddElementButton>
                    <AddElementButton onClick={() => this.handleClick(-5)}>Datum</AddElementButton>
                </Inline>
            </Container>
        );
    }
}

export default DropDown;