import styled from 'styled-components';

export const Container = styled.div`
width: 1400px;
height: auto;
display: flex;
margin-top: 50px;
flex-direction: row;
/* background-color: green; */
overflow: hidden;
align-self: center;
min-height: 100vh;
flex-wrap: wrap;

justify-content: center ;
/* flex-wrap: nowrap; */
display: -webkit-flex;
    display: -moz-flex;
    /* white-space: nowrap; */
@media only screen and (max-width: 1500px) {
        width: 1000px;
}
@media only screen and (max-width: 1250px) {
    width: 750px;
}
@media only screen and (max-width: 850px) {
    width: 100%;
    margin-top: 60px;
}
@media only screen and (max-width: 510px) {
    /* width: 400px; */
    width: 100%;
}
@media only screen and (max-width: 400px) {
    /* width: 275px; */
    width: 100%;
}
`;

export const FuckingImage = styled.img`
width: 100%;
height: auto;
/* border: 4px solid rgba(0,0,0,0); */
border-radius: 100%;
margin-top: 0%;
transition: width 0.4s, margin-left 0.4s , margin-top 0.4s;
/* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.0); */
/* background-color: rgba(100, 167, 10, 1); */
:hover{
    /* border: 4px solid rgba(100, 167, 10, 0.7); */
    /* border-radius: 100%; */
    margin-top: 4.5%;
    width: 91%;
    margin-left: 4.5%;
}
`;
export const ImageContainer = styled.div`
width: 170px;
margin-left: 24px;
margin-right: 24px;
/* margin-left: 25px; */
/* background-color: red; */
/* background-image: url(${ props => props.image}); */
/* align-self: flex-start; ex*/
align-self: flex-start; 
height: auto;
margin-bottom: -4px;
display: flex;
flex-direction: row;
:hover{
    /* background-color: green; */
}
/* overflow: hidden; */
@media only screen and (max-width: 1500px) {
    width: 150px;
}
@media only screen and (max-width: 1250px) {
    width: 140px;/
}
@media only screen and (max-width: 850px) {
     /* width: 50%;  */
    /* height: 350px;
    background-size: 100% 100%;
    background-repeat: no-repeat; */ 
}
@media only screen and (max-width: 710px) {
/* height: 290px; */
}
@media only screen and (max-width: 565px) {
    /* background-size: auto 100%; */
    /* height: 250px; */
}
@media only screen and (max-width: 400px) {
    /* height: 220px; */
}
`;
export const ImageView = styled.div`
margin-top: 10px;
/* display: flex; */
flex-direction: column;
overflow: visible;
width:100%;
position: relative;
justify-content: center;
height: 100%;
transition: background-color 2s;
display: ${ props => { if (props.show === true) { return "block" } else { return "block"; } }};
/* overflow: hidden; */
:hover{
    cursor: pointer;
}
`;
export const TextContainer = styled.div`
width: 100%;
display: flex;
text-align: center;
flex-direction: column;

font-family: "Open Sans";
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 25px;
text-align: center;

color: #005B30;
margin-top: 5px;
margin-bottom: 40px;
`;
export const Cmon = styled.div`
width: auto;
height: auto;
display: inline-block;
position: relative;
background-color: rgba(227, 131, 14, 0.8);
`;
export const Cmon2 = styled.div`
width: 100%;
height: 100%;
position: absolute;
background-color: rgba(227, 131, 14, 0.8);
`;
// export const Inline = styled.div`
// display: inline;
// `;
export const MagnifierImage = styled.img`
display: flex;
flex-direction: row;
align-self: center;
vertical-align: center;
position: absolute;
margin-top: 130px;
/* background-color: red; */
margin-left: 46%;
width: 45px;
height: 45px;
z-index: 1;
@media only screen and (max-width: 1250px) {
    margin-top: 110px;
}
`;