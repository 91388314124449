import React from 'react';

import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Navigation from '../components/Navigation';
import Home from '../pages/Home';
import About from '../pages/About';
import Herbs from '../pages/Herbs';
import Products from '../pages/Products';
import Product from '../pages/Product';
import Herb from '../pages/Herb';
import Certificate from '../pages/Certificate';
import QA from '../pages/QA';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import Article from '../pages/Article';
import ArticleEditor from '../pages/ArticleEditor';
import Legal from '../pages/Legal';
import Footer from '../components/Footer';
import Login from '../pages/Login';
import { PrivateRoute } from '../utils/index';
import { connect } from 'react-redux';
import CookieNotification from '../components/CookieNotification';

const mapStateToProps = (state) => {
    return {
        token: state.adminToken,
    }
};

class Main extends React.Component {

    render() {
        return (
            <Router >
                <div style={
                    {
                        width: "100vw",
                        height: "100vh"
                    }
                }>
                    <Navigation />
                    <Switch>
                        <Route exact path="/"
                            component={Home} />
                        <Route exact path="/about"
                            component={About} />

                        <Route exact path="/herbs"
                            component={Herbs} />
                        <Route exact path="/products"
                            component={Products} />
                        <Route exact path="/products/:id"
                            component={Product} />
                        <Route exact path="/herbs/:id"
                            component={Herb} />
                        <Route exact path="/certificate"
                            component={Certificate} />
                        <Route exact path="/questions"
                            component={QA} />
                        <Route exact path="/blog/:id"
                            component={Blog} />
                        <Route exact path="/blog/article/:id"
                            component={Article} />
                        <Route exact path="/contact"
                            component={Contact} />
                        <Route exact path="/privacy"
                            component={Legal} />
                        <Route exact path="/cookies"
                            component={Legal} />
                        {/* privatne rute */}
                        <PrivateRoute
                            path='/admin/blog/:id'
                            exact={true}
                            component={Blog}
                            token={this.props.token}
                            handleChildFunc={this.handleChildFunc}
                        />
                        <PrivateRoute
                            path='/admin/blog/article/:id'
                            exact={true}
                            token={this.props.token}
                            component={ArticleEditor}
                            handleChildFunc={this.handleChildFunc}
                        />
                        <Route exact path="/admin/login"
                            component={Login} />
                    </Switch>
                    <CookieNotification />
                    <Footer />
                </div>
            </Router>
        );
    }

}

export default connect(mapStateToProps, null)(Main);