import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import Jumbotron from '../../components/Jumbotron';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import MainSection from '../../components/MainSection';
import Trophies from '../../components/Trophies';
import ScrollToTop from '../../components/ScrollToTop';

import {
    GrayParagraphLoreSmallThatIsActuallyNotWhite,
    GrayParagraphLoreSmallThatIsActuallyNotWhiteButTurnedOutToBeGreen,
    GreenText,
    InlineHolder,
    GrayParagraphLoreSmallThatIsActuallyNotWhiteAndthisTimeReallySmall
} from '../../components/_components';
import GreenSection from '../../components/GreenSection';
import CertificateList from '../../components/CertificateList';
import FixedSpacing from '../../components/FixedSpacing';

const mapStateToProps = (state) => {
    return {
        text: state.language.certificate,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Certificate extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <Jumbotron
                    background={Config.images.certificate.background}
                    mobileBackground={Config.images.certificate.background}
                />
                <MainSection title={'SERTIFIKATI'} font={'Sans'}>
                    <div style={{ display: 'flex', width: '80%', marginLeft: '10%' }}>
                        <GrayParagraphLoreSmallThatIsActuallyNotWhite>
                            {"Kompanija Herba Svet je u potpunosti posvećena politici kvaliteta, koja je usmerena na zadovoljstvo krajnjeg potrošača, najviši kvalitet i bezbednost svih naših proizvoda, što se odražava u Politici kvaliteta koje se držimo od samog osnivanja. U skladu s tim, svake godine prolazimo kroz stroge kontrole sertifikacionih kuća i Ministarstva zdravlja, u skladu sa standardima i normativima Evropske unije i pod krovnim nadzorom našeg domaćeg Akreditacionog tela Srbije. Takođe, pojedini proizvodi su već registrovani po najstrožem svetskom standardu – FDA, američke Agencije za hranu i lekove"}
                        </GrayParagraphLoreSmallThatIsActuallyNotWhite>
                    </div>
                </MainSection>
                <MainSection title={''} font={'Sans'}>
                    <GreenText>
                        {'STANDARDI KOJIH SE PRIDRŽAVAMO I PO KOJIMA\n SMO SERTIFIKOVANI SU:'}
                    </GreenText>
                    <GrayParagraphLoreSmallThatIsActuallyNotWhite>
                        {" ISO 9001:2015\n\nISO 22716:2011\n\n HACCP\n\n cGMP (Dobra proizvođačka praksa)\n\nHALAL – Rijaset Muslimanske zajednice Srbije\n\n FDA – registrovani kao proizvođač u bazi podataka"}
                    </GrayParagraphLoreSmallThatIsActuallyNotWhite>
                </MainSection>
                <CertificateList />
                <MainSection title={'NAGRADE'} font={'Sans'}>
                    <GrayParagraphLoreSmallThatIsActuallyNotWhite>
                        {"Drago nam je što ste brzo prepoznali našu posvećenost vrhunskom kvalitetu – do danas je Herba Svet dobio 27 zlatnih\n medalja, specijalnu povelju za visok kvalitet proizvoda (2007.), kao i 3 zlatna pehara za kvalitet proizvoda (2009, 2010. i 2011.)\n na Sajmu ekologije u Novom Sadu. Ta priznanja nam puno znače – ali još više nas raduju pohvale naših korisnika,\n njihovi saveti i poverenje."}
                    </GrayParagraphLoreSmallThatIsActuallyNotWhite>
                </MainSection>
                <MainSection title={''} font={'Sans'}>
                    <div style={{ width: '70%', marginLeft: '15%' }}>
                        {
                            this.props.text ?
                                this.props.text.chronology_new.map((element) => {
                                    return (
                                        <InlineHolder>
                                            <GrayParagraphLoreSmallThatIsActuallyNotWhiteButTurnedOutToBeGreen>
                                                {element.year}
                                            </GrayParagraphLoreSmallThatIsActuallyNotWhiteButTurnedOutToBeGreen>
                                            <GrayParagraphLoreSmallThatIsActuallyNotWhiteAndthisTimeReallySmall>
                                                {element.text}
                                            </GrayParagraphLoreSmallThatIsActuallyNotWhiteAndthisTimeReallySmall>
                                        </InlineHolder>
                                    );
                                })
                                :
                                <></>
                        }
                    </div>
                </MainSection>
                <Trophies />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer >
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);