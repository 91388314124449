import images from '../../images';

const herbs = [
    {
        id: 1,
        herbIcon: images.localdata.herbs.icon.virak,
        name_sr: 'VIRAK', // ime na srpskom i engleskom
        name_en: 'VIRAK',
        name_latin: 'Alchemilla vulgaris',
        description_sr: 'Virak, kod nas poznat i kao vrkuta, gospin plašt, plahtica, rosnik, svoje naučno ime Alchemilla vulgaris dobio je po verovanju alhemičara da, pomoću rose koja se skuplja na njegovim listićima, mogu pronaći kamen mudrosti koji ima moć da metal pretvori u zlato. Virak je lepa biljka, i na njegovim listovima se tokom celog dana mogu videti kapljice koje sijaju kao biseri. Verovatno je svojom lepotom prvo i privukao ljudsko oko, ali ubrzo su naši preci shvatili da je najveća vrednost virka u njegovim lekovitim svojstvima. U to su se naročito uverile žene.\n\n\ Virak kao prirodni balanser hormona pomaže kod neredovnih i obilnih menstruacija, cista, policističnih jajnika, mioma, endometrioze, ali je poznat i po tome što olakšava porođaj, ubrzava zarastanje rana i oporavak nakon porođaja. Tanini i adstringenti sprečavaju pojačana krvarenja i smanjuju menstrualne bolove. Može pomoći i kod gljivica i bakterijskog vaginitisa. Istraživanja su pokazala da je virak koristan kod poremećaja uzrokovanih humanim papiloma virusom, a da agrimonin kog sadrži sprečava pojavu raka dojke.',
        description_en: '', // opis srpski i engleski
        products: [1],
        blogLocation: "348541",
    },
    {
        id: 2,
        herbIcon: images.localdata.herbs.icon.hajducka_trava,
        name_sr: 'HAJDUČKA TRAVA',
        name_en: 'HAJDUČKA TRAVA',
        name_latin: 'Achillea millefolium',
        description_sr: 'Legenda kaže da je grčka vojska prvo stigla do Mizije na putu ka Troji. Ubeđeni da su stigli u Troju, krenuli su da se pustoše grad, sve dok se nije pojavio Telef, Heraklov sin. Ahil ga je pogodio kopljem u butinu, nakon što se Telef spotakao o čokot vinove loze. Telefova rana nikako nije htela da zaceli i on se prerušio u skitnicu kako bi mu Ahil izvidao ranu uz pomoć biljaka...\n\nHajdučka trava blagotvorno deluje na jetru, bubrege, reproduktivni sistem, organe za varenje, nervni sistem, cirkulaciju, snižava šećer u krvi, ublažava grip i prehladu, reguliše krvni pritisak, leči hemoroide. Ova biljka zaustavlja krvarenje i pospešuje zarastanje rana. Često je deo biljnih mešavina za ’ženske probleme’ jer sprečava obilne i neredovne menstruacije.',
        description_en: '',
        products: [1, 2],
        blogLocation: "887353",
    },
    {
        id: 3,
        herbIcon: images.localdata.herbs.icon.crveni_zdravac,
        name_sr: 'CRVENI ZDRAVAC',
        name_en: 'CRVENI ZDRAVAC',
        name_latin: 'Geranium robertianum',
        description_sr: 'Dvostruki Nobelovac Dr Oto Varburg rekao je da je glavni uzrok nastanka karcinoma nedostatak kiseonika u ćelijama, nakon što je otkrio da ćelije raka ne mogu da opstanu u sredini u kojoj ima dosta kiseonika, već samo u anaerobnom stanju. Upravo je germanijum taj koji ćelije obasipa kiseonikom. On je taj koji nam donosi energiju, vitalnost, imunitet, i vraća balans kada se stanje poremeti. Germanijum se nalazi u prirodi, u nekim pečurkama kao što su šiitake, u žen-šenu, belom luku i u jednoj izuzetnoj lekovitoj biljci – crvenom zdravcu.\n\nU moru lekovitih biljaka, crveni zdravac se često zaboravlja, što je šteta jer njime možemo sprečiti pojavu i razvoj karcinoma. Njegove izuzetne antibiotske i antibakterijske moći pomažu kod otvorenih rana, raspuklih čireva, kod unutrašnjeg krvarenja i krvarenja iz nosa, zubobolje, a najbolji je prijatelj žena. Zdravac je sjajan za regulisanje menstrualnog ciklusa, sprečavanje upale jajnika, i odličan je borac protiv steriliteta kod oba pola.',
        description_en: '',
        products: [1],
        blogLocation: "235157",
    },
    {
        id: 4,
        herbIcon: images.localdata.herbs.icon.bela_imela,
        name_sr: 'BELA IMELA',
        name_en: 'BELA IMELA',
        name_latin: 'Visci albi herba',
        description_sr: 'Da bi imela živela, neophodno je da ptica pojede zrelu bobicu, tako što će kljunom istisnuti meku opnu, a potom kljun sa lepljivim semenom  obrisati o koru drveta. Kada se lepljivi sadržaj osuši i stvrdne, seme se čvrsto zakači za drvo i proklija, otimajući od svog domaćina hranljive materije i vodu. Stari Kelti su verovali da imela, osim nutrijenata, od drveta preuzima i njegov duh, i na taj način ostaje zelena i tokom zime, kada je veći deo vegetacije uspavan. Sposobnost da crpi energiju svog domaćina, sagledaćemo u novom svetlu kada saznamo da ta izuzetna biljka ima moć da uništi ćelije tumora.\n\nPored suzbijanja malignih ćelija, imela ima moć da reguliše krvni pritisak i spreči aritmiju. Ona sprečava vrtoglavicu i grčeve, reguliše hormone, poboljšava metabolizam... Imela sprečava dijabetes, pospešuje rad limfnog sistema, otklanja valunge i razdražljivost. Imela povoljno deluje na žlezde sa unutrašnjim lučenjem.',
        description_en: '',
        products: [2, 6, 10],
        blogLocation: "559895",
    },
    {
        id: 5,
        herbIcon: images.localdata.herbs.icon.maticnjak,
        name_sr: 'MATIČNJAK',
        name_en: 'MATIČNJAK',
        name_latin: 'Melissa officinalis',
        description_sr: 'Na jugu Evrope i severu Afrike poznat je više od dva milenijuma. Matičnjak raste uz ograde, po šumarcima i uz naselja, često ga ima u blizini pčelinjaka jer pčele vole njegove aromatične cvetiće kojih ima tokom celog leta. Po njima je i dobio latinski naziv. Arapi su ga prvi koristili kao lek i od njega pravili čaj protiv napetosti. U srednjem veku je bio naročito popularan, a opatice karmelićanke osmislile su čuvenu recepturu za karmelićansku vodicu koja, navodno, donosi dugovečnost.\n\nŠta sve može matičnjak? Iskustvo kaže da matičnjak ublažava stres, napetost, uznemirenost, depresiju i nesanicu. Ova biljka pomaže obolelima od Alchajmerove bolesti i demencije, poboljšava pamćenje i koncentraciju. Matičnjak ima antivirusna svojstva, ubrzava ozdravljenje i deluje preventivno, sprečava i zaustavlja bakterijske infekcije, uključujući i kandidu, smanjuje glavobolje i osećaj tenzije u glavi.',
        description_en: '',
        products: [2, 9, 6, 10],
        blogLocation: "656382",
    },
    {
        id: 6,
        herbIcon: images.localdata.herbs.icon.neven,
        name_sr: 'NEVEN',
        name_en: 'NEVEN',
        name_latin: 'Calendula officinalis',
        description_sr: 'Tokom indijskih praznika i venčanja, sve se žuti od dugih nizova cveta nevena kog su ovde doneli Portugalci, a praznični venci se kače oko vrata i svuda po kući, ne bi li prizvali blagostanje. Ranohrišćani su ga koristili prilikom proslava posvećenih Bogorodici Mariji, otud i engleski naziv, marigold, Mary’s gold – Marijino zlato. Naučni naziv, Calendula officinalis, asocira na nebeski lek. Nije ni čudo, jer njegova lekovita svojstva su brojna.\n\nEstrogensko dejstvo nevena pomaže ženama tako što uravnotežava menstrualni ciklus, smanjuje pojačani sekret, kandidu, štiti matericu, ublažava simptome menopauze, a dojiljama smiruje bol u bradavicama i mastitis. Sjajan je u dermatologiji: smiruje upaljenu kožu, koprivnjaču, ekcem, psorijazu, seboreju, herpes, osip kod beba, čireve, koži daje vlažnost i sprečava perutanje, ublažava svrab kod ujeda insekata, sprečava infekcije i dekubitis kod bolesnika koji dugo leže, štiti opekotinu i pomaže njeno zarastanje, a dobar je i za sluzokožu i kod upale desni.',
        description_en: '',
        products: [1, 2, 8, 12, 11, 13],
        blogLocation: "240905",
    },
    {
        id: 7,
        herbIcon: images.localdata.herbs.icon.srdacica,
        name_sr: 'SRDAČICA',
        name_en: 'SRDAČICA',
        name_latin: 'Leonurus cardiaca',
        description_sr: 'Kao što samo ime kaže, srdačica ima veze sa srcem. U vreme kraljice Viktorije svaki cvet imao je simbolično značenje, a srdačica je bila simbol prikrivene ljubavi. Ali ova biljka nije samo lek za emocije, već deluje kod angine pektoris, post-infarktnih stanja, smiruje i uravnotežava srčani ritam, reguliše holesterol i poboljšava cirkulaciju. Prva zvanična kineska farmakopeja Tang Peng Ts’ao iz 659. godine naše ere spominje da je srdačica efikasna kod ’izbacivanja mrtvog fetusa i zaostale placente’. Kasnije je zaista dokazano da biljka izaziva kontrakcije materice i može izazvati pobačaj, pa se ne preporučuje trudnicama.\n\nŠta je to što je lekovito u srdačici? Leonurin, alkaloid iz ove biljke, pokazao se kao uspešan inhibitor vaskularnog tonusa, jednostavnim jezikom rečeno, opušta mišiće. Tanini imaju bakteriostatičko i antioksidantno dejstvo. Acetil-holin uravnotežava krvni pritisak. Holin razlaže masnoće i usporava njihovo taloženje, a minerali poboljšavaju cirkulaciju i usporavaju starenje krvnih sudova. Ursolna kiselina čuva nerve, memoriju, stimuliše rad mišića, štiti od karcinoma i sprečava oštećenja izazvana srčanim udarom.',
        description_en: '',
        products: [6],
        blogLocation: "883455",
    },
    {
        id: 8,
        herbIcon: images.localdata.herbs.icon.cestoslavica,
        name_sr: 'ČESTOSLAVICA',
        name_en: 'ČESTOSLAVICA',
        name_latin: 'Veronica officinalis',
        description_sr: 'U Engleskoj se zove speedwell, od arhaičnog speed u značenju bujati, napredovati, a naučno ime veronica dobila je pre pola veka, i to po svetici koja je presrela Hrista dok je nosio krst na putu ka Golgoti, i svojom maramom mu obrisala znoj i krv sa lica. Veronikina marama je u tom trenutku na sebi zadržala obrise Hristovog lika – vera iconica – pravi, istinski lik, i ta scena je čest hrišćanski motiv na bezbrojnim slikama i ikonama. Nemci, kod kojih se čestoslavica koristi od davnina, je zovu ’nagradom časti’, biljkom koja briše zaborav, pa su u Srednjem veku vitezovi često nosili venčiće u koje su upleteni njeni cvetići kao spomen na velike viteške poduhvate.\n\nČestoslavica je sjajna kod problema s nervima, umiruje organizam i psihu, uveče opušta i olakšava ulazak u san, otklanja depresiju. Sadrži manitol koji poboljšava memoriju, povoljno utiče na centralni nervni sistem i otklanja nesvesticu. Ona pomaže rad bubrega i izbacivanje peska, leči infekcije i podstiče drenažu. Dobar je čistač pluća, koristi se za iskašljavanje i izbacivanje sluzi iz pluća kod niza respiratornih oboljenja, olakšava bol u grudima, i pomaže pušačima kod pušačkog kašlja.',
        description_en: '',
        products: [5, 6, 10],
        blogLocation: "388255",
    },
    {
        id: 9,
        herbIcon: images.localdata.herbs.icon.koren_maslacka,
        name_sr: 'MASLAČAK',
        name_en: 'MASLAČAK',
        name_latin: 'Taraxacum oficinale Weber',
        description_sr: 'U Kini se koren maslačka vekovima koristi kao lek protiv nekih vrsta karcinoma, a najnovija istraživanja sprovedena 2011. godine na univerzitetu Vindzor u Kanadi dokazala su njegovu sposobnost da selektivno uništi maligne ćelije melanoma, a da pritom ne ošteti zdrave ćelije. Rađene su i studije o karcinomu pankreasa i jetre gde se maslačak pokazao jednako delotvornim. Supstanca koja ima tu moć je luteolin, flavonoid sa izrazito jakim antioksidantskim, protivupalnim i protivtumornim dejstvom.\n\nMaslačak ima diuretsko dejstvo, podstiče izlučivanje žuči, oslobađa jetru od toksina, ali pomaže i kod alergija, umiruje upale, bol u zglobovima i mišićima, podiže energiju, smanjuje holesterol, snižava krvni pritisak i nivo šećera u krvi. Pošto reguliše varenje, sprečava zatvor i pokreće metabolizam, dobar je i za mršavljenje, a kako ima antibakterijska svojstva, pomaže kod kožnih infekcija i akni. Čak i ako nemamo ni jedan od ovih problema, maslačak nam može ojačati imunitet i poboljšati opšte stanje organizma.',
        description_en: '',
        products: [5, 7, 10],
        blogLocation: "704170",
    },
    {
        id: 10,
        herbIcon: images.localdata.herbs.icon.rastavic,
        name_sr: 'RASTAVIĆ',
        name_en: 'RASTAVIĆ',
        name_latin: 'Equiseti herba',
        description_sr: 'Stari narodi koristili su ga za razne svrhe, a pošto je abrazivan, Indijanci su njime glancali strele, i tokom vekova koristio se za poliranje posuđa od kalaja. Danas znamo da je silicijumska kiselina ta koja je doprinosila da posuda zasija. I baš je ona ta koja ima izuzetna lekovita svojstva. Smatra se da je, tokom paleozoika, najstarije ere planete Zemlje, rastavić bio džinovska biljka, najdominantnija, da je rastao petnaestak metara u visinu. Danas je ovo višegodišnja biljka koja dostiže do pola metra.\n\nRastavić je diuretik, podstiče izbacivanje nagomilane tečnosti iz organizma i prečišćava ga. Zato je sjajan kod celulita i naslaga na bokovima. Diuretsko dejstvo ove biljke pomaže kod oticanja nogu i edema nastalih usled povreda ili šećerne bolesti. Rastavić štiti mokraćne puteve i čitav urinarni trakt jer sprečava nastanak kamenca. Kod već postojećih kamenčića, u stanju je da ih smanji i pospeši njihovo izbacivanje. Veoma je lekovit kod upale mokraćnih puteva i krvarenja, olakšava bolno mokrenje i sprečava upale bubrega i prostate.',
        description_en: '',
        products: [5, 6, 7, 8],
        blogLocation: "544745",
    },
    {
        id: 11,
        herbIcon: images.localdata.herbs.icon.bokvica,
        name_sr: 'BOKVICA',
        name_en: 'BOKVICA',
        name_latin: 'Plantago major',
        description_sr: 'Po mitologiji, nastala je od devojke koja je stajala uz put i čekala… toliko dugo da je pustila koren i pretvorila se u biljku krajputašicu. Kasnije je u Hrišćanstvu postala simbol staze koja vodi do Hrista. Stari Rimljani su je privijali na rane, a američki Indijanci koristili kod ujeda zmija i insekata. Plinije je pominje kao lek za čireve, stari Sasi su je smatrali jednom od devet svetih biljaka, Asteci su je redovno jeli, Galen je zapisao da list ove biljke hladi upaljeno tkivo i izvlači gnoj, kod Kineza se koristi preko 2000 godina, a u našem narodu od pamtiveka.\n\nJedna od najčešćih upotreba bokvice je za lečenje disajnih organa. Bokvica i med su dobitna kombinacija za suzbijanje kašlja, bronihtisa i astme. Pored toga, odlična je za zaustavljanje krvarenja i zarastanje rana, kod nateklih žlezda, desni koje krvare, zubobolje, lečenja kožnih bolesti, opekotina, modrica, promrzlina, akni, rozacee, plikova i žuljeva na tabanima i rukama, hemoroida, menstrualnih odliva, čira, visoke temperature, a ima i veoma blagotvorno dejstvo na stomak. Može se i jesti – sveža, u salati, barena uz ostalo zeleno lisnato povrće, ili se može izmiksati i piti za detoksifikaciju organizma.',
        description_en: '',
        products: [10],
        blogLocation: "22204",
    },
    {
        id: 12,
        herbIcon: images.localdata.herbs.icon.kopriva,
        name_sr: 'KOPRIVA',
        name_en: 'KOPRIVA',
        name_latin: 'Urtica dioica',
        description_sr: 'Kada naiđemo na nju u prirodi i kad nas ožari obično se ljutimo, ali ova biljka je toliko zdrava i blagotvorna da bi trebalo da joj se zahvalimo čak i kada nam ostavi plikove na koži. Pritom je svima i svuda dostupna, i ako je jedemo par puta nedeljno ili pijemo čaj od njenog korena, sigurno će nam osetno poboljšati zdravlje. Kopriva se koristi od pamtiveka. Ima svoju naučnu potporu, jer kopriva je poznati analgetik i deluje protivupalno.\n\nŠta je to u koprivi što je toliko lekovito? Prvo skup minerala: gvožđa, fosfora, magnezijuma, kalcijuma, pa vitamina B2 i B5, A, C i K. Kvercetini iz koprive smanjuju alergijske reakcije. Beta-sitosterol reguliše rad prostate i sprečava njeno uvećanje. Njeno diuretičko dejstvo pomaže i kod visokog krvnog pritiska, a protiv-upalno dejstvo kod osteoartritisa. Koren koprive sadrži polisaharide, lektine, kumarine, triterpene, masne kiseline…',
        description_en: '',
        products: [10, 7],
        blogLocation: "717747",
    },
    {
        id: 13,
        herbIcon: images.localdata.herbs.icon.valerijana,
        name_sr: 'VALERIJANA',
        name_en: 'VALERIJANA',
        name_latin: 'Valeriana officinalis',
        description_sr: 'Valerijana je samonikla biljka koja voli visinu i sunce. U starom Rimu često je koristio Dioskurid iz Anzaraba, vojni lekar u službi Neronove vojske i vrsni poznavalac lekovitog bilja. U svom delu, De materia medica, opisao je načine primene preko 1000 prirodnih supstanci, a valerijanu je svrstao u grupu aromatičnog bilja. Ova zbirka smatra se fundamentom svih modernih farmakopeja. Dioskurid je poznat i po preteči anestetika, posebnom vinu koje je pravio od opijuma i mandragore kako bi uspavao pacijente pre operacije. Za to stanje nesvesti koristio je grčku reč anestezija, što je značilo ’bez osećaja’.\n\nValerijana ima sedativno, anksiolitičko, antidepresivno, antikonvulzivno i spazmolitičko dejstvo. Jednostavno rečeno, ublažava agresiju, tenzionu glavobolju, grčenje mišića, depresiju i teskobu. Valerijana je dobra i kod srčane aritmije i osećaja teskobe u grudima. Pomaže kod odvikavanja od pušenja, kao i kod išijasa, neuralgije, periferne neuropatije, nervoznog creva, grčeva u stomaku, menstrualnih bolova, osećaja uznemirenosti u menopauzi, pa čak i kod hipohondrije.',
        description_en: '',
        products: [2, 9, 6],
        blogLocation: "994658",
    },
    {
        id: 14,
        herbIcon: images.localdata.herbs.icon.andjelika,
        name_sr: 'ANDJELIKA',
        name_en: 'ANDJELIKA',
        name_latin: 'Angelica archangelica',
        description_sr: 'Kuga je Kinom harala 1330-ih godina, a u Evropu je stigla oktobra 1347. kada je 12 đenovljanskih brodova prispelo u Mesinu na Siciliji. Tokom narednih pet godina od ove bolesti stradalo je preko 20 miliona Evropljana i populacija se smanjila za čak trećinu. Jednom monahu u snu se javlja Arhangel Mihajlo i upire prstom na biljku koja ima moć da izleči ovu surovu bolest. Biljka se pokazuje kao delotvorna, i postaje deo kraljevske recepture Čarlsa II. Po njemu je i dobila ime – Angelica archangelica, kod nas poznata kao anđelika, kravojac, zubača, šiviz, divlja pirevina, koren svetog Duha…\n\nAnđelika sadrži kiseline, smole, fitosterole i angelicin. Njena eterična ulja i ekstrakti imaju izuzetno visoke količine kumarina, koji ima blago sedativno dejstvo. Anđelika je sjajna za oporavak bolesnih, starih i anoreksičnih, daje snagu, jača mišiće, čisti krv i eliminiše toksine iz organizma. Ona je sjajan stimulans, mnogo bolji od kafe i energetskih napitaka jer daje energiju bez negativnih efekata, nesanice i razdražljivosti. Jača memoriju i izoštrava sluh i vid. Njene tinkture pomažu iskašljavanje, leče laringitis, bronhitis i upalu pluća. Koristi se kod lošeg varenja i nadimanja. Koren angelike sadrži fitoestrogene, pa se posebno preporučuje protiv bolnih menstruacija i simptoma menopauze.',
        description_en: '',
        products: [8, 10],
        blogLocation: "922689",
    },
    {
        id: 15,
        herbIcon: images.localdata.herbs.icon.zlatna_maka,
        name_sr: 'ZLATNA MAKA',
        name_en: 'ZLATNA MAKA',
        name_latin: 'Lepidium Meyenii',
        description_sr: 'Zlatna maka je korenasta biljka koja se preko 3000 godina uzgaja na Andima. Koren koji liči na našu repu se bere, suši i melje. Šta sadrži maka? Preko 20 amino kiselina, od toga 8 esencijalnih 20 masnih kiselina, uključujući laurinsku, oleinsku, linoleinsku, palmitinsku i stearinsku kiselinu vitamine B1, B2, C i E minerale: kalcijum, magnezijum, kalijum, bakar, cink, mangan, fosfor, selen, sumpor, natrijum i gvožđe.\n\nMaka balansira hormone, pospešuje plodnost i poboljšava muško i žensko reproduktivno zdravlje. Pomaže u smanjenju neprijatnih simptoma PMS-a i kod sindroma policističnih jajnika jer balansira nivo estrogena u telu. Maka pospešuje regeneraciju i brzo nas oporavlja od umora. Odlična je pomoć u menopauzi kao i kod usporenog rada štitne žlezde jer podiže energiju i smanjuje depresiju. Studije su pokazale da maka uspešno rešava seksualnu disfunkciju i povećava libido kako kod muškaraca tako i kod žena.',
        description_en: '',
        products: [3],
        blogLocation: "845568",
    },
    {
        id: 16,
        herbIcon: images.localdata.herbs.icon.damjana,
        name_sr: 'DAMJANA',
        name_en: 'DAMJANA',
        name_latin: 'Turnera diffusa',
        description_sr: 'Stare Maje i Asteci su koristili, a njihovi potomci i dalje naširoko koriste lišće i stabljiku ove žbunaste biljke, ne samo kao afrodizijak po čemu je najpopularnija, već i za opuštanje, poboljšanje varenja, popravljanje raspoloženja, ili da jednostavno uživaju u njenom ukusu kao što mi uživamo u kafi. Smatra se da je bila deo prvobitnog recepta za margaritu, a ’damiana margarita’ je i dalje veoma popularan koktel u Meksiku. Kada su Evropljani uvideli lekovita svojstva ove biljke, ubrzo je uvrštena u farmakopeje, u kojima je okarakterisana kao biljka koja smanjuje anksioznost, neuroze, potištenost koja umanjuje seksualne performanse.\n\nTradicionalna medicina širom sveta, od srednjeameričke do kineske, koristi damjanu kao veoma moćan terapeutski agens koji daje energiju, poboljšava prokrvljenost tako što dovodi kiseonik u ćelije, deluje kao mentalni stimulans i otklanja depresiju, stimuliše lučenje testosterona koji igra najvažniju ulogu u formiranju i sazrevanju sperme, pomaže u očuvanju i obnavljanju muških reproduktivnih organa, pospešuje cirkulaciju telesnih tečnosti u genitalijama, a pritom je i adaptogen koji štiti čitav organizam od infekcija i pored genitalnog čuva i urinarni trakt.',
        description_en: '',
        products: [4],
        blogLocation: "662459",
    },
    {
        id: 17,
        herbIcon: images.localdata.herbs.icon.cileanska_ruza,
        name_sr: 'ČILEANSKA RUŽA',
        name_en: 'ČILEANSKA RUŽA',
        name_latin: 'Rosa mosqueta',
        description_sr: 'Rosa mosqueta ili čileanska ruža pripada porodici divljih ruža koja broji preko 100 vrsta. Nastanjuje divlje padine Anda, i još jedan je dokaz kako nešto najlepše može procvetati i u surovim uslovima. Pored lepote, ovo je biljka sa mnoštvom korisnih svojstava. U Čileu se vekovima koristi za negu i lečenje kože, i poznata je kao ‘plod večne mladosti’, baš zato što ima izuzetnu moć da kožu zaceli i regeneriše. Čileanska ruža jak je antioksidant, štiti organizam od oboljenja, pomaže mu da se izbori sa infekcijama, jača imuni sistem, poboljšava varenje, ima laksativno i diuretičko dejstvo. \n\nUlje čileanske ruže otklanja ožiljke – od rana, akni, opekotina… Ovo ulje povećava elastičnost kože i podstiče stvaranje kolagena, sprečava nastanak i ublažava postojeće strije, smanjuje bore, čak i one duboke. Ulje čileanske ruže ubrzava zaceljivanje opekotina, uključujući i one od sunca, oporavlja kožu beba nakon ojeda, skuplja pore i vraća koži mladalački izgled, takođe štiti kožu od infekcija i razvoja malignih ćelija',
        description_en: '',
        products: [11, 12, 13, 15, 14],
        blogLocation: "404015",
    },
    {
        id: 18,
        herbIcon: images.localdata.herbs.icon.avokado,
        name_sr: 'AVOKADO',
        name_en: 'AVOKADO',
        name_latin: 'Persea Americana',
        description_sr: 'Ako bismo pravili rang-listu najzdravijih plodova prirode, avokado bi se sigurno našao na samom vrhu. Avokado je izuzetno bogat vitaminima K, C, B5, B6, E, folatima, kalijumom, magnezijumom, manganom, bakrom, grožđem, cinkom, i nešto manjim količinama vitamina A, B1, B2 i B3. U 100 grama ovog ploda ima 15 grama izuzetno zdravih masti, i samo 160 kalorija. Avokado ima nizak procenat ugljenih hidrata, a nema ni malo holesterola, natrijuma ili zasićenih masti. Zato je idealan sastojak raznih dijeta.\n\nOzbiljne kliničke studije dokazale su da avokado može značajno da smanji nivo ukupnog holesterola, trigliceride u krvi i da poveća nivo dobrih holesterola. Oleinska kiselina iz avokada smajuje upale i rast tumora, i umanjuje negativne efekte hemoterapije. Dokazano je da avokado sprečava rast ćelija raka prostate. Esencijalne masne kiseline ulja avokada rengenerišu i podmlađjuju kožu. Ulje ima antibakterijsko svojstvo, pa pomaže zaceljivanju rana, akni i dermatitisa. Kod ekcema i psorijaze će ublažiti svrab.',
        description_en: '',
        products: [11, 12, 13, 15, 14],
        blogLocation: "984481",
    },
    {
        id: 19,
        herbIcon: images.localdata.herbs.icon.jojoba,
        name_sr: 'JOJOBA',
        name_en: 'JOJOBA',
        name_latin: 'Simmondsia chinensis',
        description_sr: 'Indijanci koji su naseljavali polupustinje Arizone, Baha Kalifornije i Meksika koristili su je vekovima, i prenosili s kolena na koleno znanja o njoj. Pomagala im je oduvek da zaštite kožu od jakog sunca, da leče rane i smire bolove u mišićima i kostima, ali i da omekšaju kožuhe prilikom štavljenja. Beli čovek se nije mnogo interesovao za ovaj čudni plod sve dok 1971. nije uvedena zabrana lova na kitove ulješure i trgovina kitovom masti. Tada je krenula potraga za nečim što ima sličan sastav i upotrebu. I tako je počelo interesovanje za nju – jojobu.\n\nUlje (ili vosak) jojobe je prebogato vitaminima i mineralima, pre svega vitaminom E koji je poznat kao zaštitnik kože, i kompleksom B vitamina, kao i hromom, cinkom i jodom. Zato je idealno za tretiranje suve kože, naročito iritacija nakon izlaganja suncu. Sastav ovog ulja ima snažno antibakterijsko dejstvo pa pomaže i kod poremećaja kože, a naročito kod akni jer stabilizuje lučenje sebuma. Jojoba snažno deluje kod poremećaja kao što su dermatitis, ekcem, psorijaza, seboreja, perut, rozacea i razne upale. ',
        description_en: '',
        products: [12, 11, 13],
        blogLocation: "579047",
    },
    {
        id: 20,
        herbIcon: images.localdata.herbs.icon.ravensara,
        name_sr: 'RAVENSARA',
        name_en: 'RAVENSARA',
        name_latin: 'Ravensara aromatica',
        description_sr: 'Madagaskar je ostrvo četvrto po veličini na svetu, a nalazi se u Indijskom okeanu, istočno od Mozambika u Africi. Zbog njegove posebnosti, ekolozi ga zovu osmim kontinentom. Oko 80% biljaka i životinja sa Madagaskara živi samo tu i nigde više na planeti. Ovo ostrvo je dom skoro 12000 biljnih vrsta koje samo tu rastu. Mnoge od njih su lekovite, i sadrže alkaloide kao što su vinblastin i vinkristin koji pomažu kod limfoma, leukemije i drugih vrsta karcinoma. U tom obilju šarolikog života, sa Madagaskara nam dolazi jedna biljka izuzetnih svojstava – ravensara.\n\nRavensara je odličan analgetik i ublažava razne vrste bola, kao što su glavobolja, zubobolja, bol u mišićima i zglobovima, bol u uhu. Sjajna je kod alergija, i ne samo da će ukloniti njene posledice, već će izgraditi otpornost organizma da se ubuduće nosi sa alergenima. Ovo ulje uništava bakterije, sprečava njihovo širenje, leči stare infekcije i ne dopušta novima da se šire. Ulje uništava i gljivice, sprečava njihov rast i ubija spore. Zato je idealan lek kod gljivičnih infekcija uha, nosa, kože i noktiju. Ravensara ubija i viruse. Zato je odlična kod prehlade, gripa, boginja i zauški.',
        description_en: '',
        products: [13],
        blogLocation: "446095",
    },
    {
        id: 21,
        herbIcon: images.localdata.herbs.icon.cajevac,
        name_sr: 'ČAJEVAC',
        name_en: 'ČAJEVAC',
        name_latin: 'Melaleuca alternifolia',
        description_sr: 'Lepa princeza Eelemani morala je jednog dana da krene na put i ostavi za sobom svog dragana, svoj narod Bundjalung i divlje obale istočne Australije. Put je bio dug i naporan, kroz nepoznate krajeve, i Eelemani nije bila sigurna da će kasnije umeti da nađe put nazad, ka kući. Zato se obratila Bogu Zemlje i planeta, a on je čuo i posejao u vlažnu i plodnu zemlju semenje, duž celog  puta kojim je princeza prolazila. Iz njih je nicalo drveće belog stabla i sočnog lišća. Princezi je ova biljka, melaleuka, pomogla da se vrati kući, a njenom narodu vekovima potom da prebrodi mnoge bolesti.\n\nUlje čajevca ima karakterističan, jak miris, a sadrži terpinen koji je veoma snažan fungicid, i ima moć da spreči rast preko deset vrsta gljivica i bakterija, uključujući i stafilokoke, ešerihiju, klebsielu i kandidu. Zato je čajevac veoma delotvoran kod oboljenja kože, kao što su akne, herpes, upale usne duplje, gljivične infekcije nokta, atletsko stopalo, perut, a kao što su znali i stari Aboridžini, odličan je kod ujeda insekata. Neke studije su čak dokazale da ima moć da ubija kancerogene ćelije kože.',
        description_en: '',
        products: [11, 13],
        blogLocation: "605878",
    },
    {
        id: 22,
        herbIcon: images.localdata.herbs.icon.hmelj,
        name_sr: 'HMELJ',
        name_en: 'HMELJ',
        name_latin: 'Humulus lupulus',
        description_sr: 'Tokom istorije se hmelj ređe koristio kao lek. Paracelzus ga je u 16. veku propagirao kao digestiv, a Matiolus kao diuretik. U par priručnika se pominje da se koristio za pročišćavanje krvi, jetre i pankreasa, a njegovo umirujuće dejstvo je otkriveno tek u 19. veku. Verovatno najpoznatija ličnost koja je obilno koristila ovu biljku, i to ne samo u pivu, bio je engleski kralj Džordž III, koji je spavao na jastucima ispunjenim šišarkama hmelja, zbog ’slabih nerava’.\n\nFrancuski apotekar Planš bio je prvi koji je 1813. izolovao lupulin, finu smolastu supstancu iz ženskih cvetova – šišarki hmelja. Preporučivao ga je zbog narkotičkog dejstva svima koji su patili od nesanice, tvrdeći da olakšava ulazak u san i smiruje nerve, bez negativnih dejstava. U to vreme je bila popularna terapija opijumom koji je, osim zavisnosti, izazivao i zatvor. Pored toga što je smirivao nerve, hmelj se koristio i za ’balansiranje’ libida – primećeno je da je povećavao seksualnu želju tamo gde je nema, a da je smirivao kod onih koji nisu mogli da je kontrolišu.',
        description_en: '',
        products: [14],
        blogLocation: "602275",
    },
    {
        id: 23,
        herbIcon: images.localdata.herbs.icon.maline,
        name_sr: 'LIST MALINE',
        name_en: 'List maline',
        name_latin: 'Rubus idaeus',
        description_sr: 'Plod maline je baršunast i nežan, ali njeno korenje je jako. Međutim, ono što je još dragocenije od njenog ukusnog ploda je list. Žene koje su tokom istorije, još od pećinskog doba, najčešće odlazile u prirodu da skupljaju plodove, vrlo rano su shvatile da list maline može da im pomogne. Pored brojnih rituala, amajlija, magija i čini, list maline se pokazao kao idealno rešenje za ženske tegobe. Od davnina se koristi kod bolnih menstruacija, za jačanje materice i kao pomoć dojiljama.\n\nList maline je bogat magnezijumom, kalijumom, manganom, selenom, kalcijumom, fosforom, cinkom, a naročito gvožđem. Pomaže kod mučnine i grčeva u nogama. Odličan je astringent, skuplja krvne sudove i zauzdava obilno krvarenje. Koristan je za kožu, kod opekotina, ekcema, osipa a pomaže i kod oboljenja desni. Vitamini B, C i E jačaju imunitet i daju energiju. Efikasan je za čišćenje organizma, a ima moć da ’ispere’ hormone kojima smo izloženi bilo kroz hranu ili okolinu, pa može pomoći i ženama i muškarcima da zadrže dobar hormonalni balans.',
        description_en: '',
        products: [0],
        blogLocation: "582438",
    },
    {
        id: 24,
        herbIcon: images.localdata.herbs.icon.zlatnica,
        name_sr: 'ZLATNICA',
        name_en: 'Zlatnica',
        name_latin: 'Solidago virgaurea',
        description_sr: 'Zevsova kćer Astreja, očajna zbog opšteg pada morala i ljudske izopačenosti, reši da se povuče na nebo. Smestila se u sazvežđe Virgo, Devica, koje i danas treperi u vedrim noćima. A moralni posrtaj je kažnjen potopom, i nakon što se voda povukla i ostavila blato, Astrejina suza samilosti je zalila seme iz kog je nikla biljka zlatnih cvetova, simbol vere u bolja vremena: zlatnica. Solidago virgaurea herba.\n\nZlatna šiba, čelebi-grana, štapika, zlatna rutvica, štap Svetog Petra… sama činjenica da u našim krajevima za zlatnicu postoji toliko naziva govori koliko je uvažena. Trojno dejstvo ove biljke – protivupalno, diuretičko i spazmolitičko, razlog je što često ulazi u sastav biljnih mešavina za čišćenje mokraćnih puteva, a koristi se i kao pomoćna terapija uz antibiotike.  Pored toga pomaže kod niza bolesti, od upale krajnika do bronhitisa i depresije, ona je i  vrlo korisna kod svih oboljenja i poremećaja koje otoci dodatno komplikuju: reume, gihta, visokog krvnog pritiska.',
        description_en: '',
        products: [5],
        blogLocation: "216161",
    },
]

export default herbs;