import styled, { keyframes } from 'styled-components';

export const AppContainer = styled.div`
width: 100%;
height: 100%;
/* background-repeat: no-repeat;
background-size: 100% 100%;
background-attachment: fixed; */
background-color: white;

overflow: scroll;
&::-webkit-scrollbar { 
  display: none;
}
  -ms-overflow-style: none;

overflow-x: hidden;
`;

export const Jumbotron = styled.img`
/* position: relative; */
/* width: auto; */
width: 100%;
/* height: auto; */
/* height: 780px; */
height: auto;
max-width: 100%;
min-width: 100%;
pointer-events: none;
cursor: none;
@media only screen and (max-width: 800px ){
height: auto;
}
`;

export const PageContainer = styled.div`
width: 100%;
height: auto;
min-height: 95%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
/* ${ props => { if (props.keepLine === true) { return 'minheight: pre-wrap;'; } }}; */
`;

export const Paragraph = styled.p`
font-size: 15px;
color: rgb(101,101,101);              
white-space: pre-line;
font-family: "Ubuntu";
line-height: 24px;
${ props => { if (props.keepLine === true) { return 'white-space: pre-wrap;'; } }};
@media only screen and (max-width: 1252px){
    ${ props => { if (props.small === true) { return 'font-size:11px; line-height:21px'; } }};
}
@media only screen and (max-width: 850px){
    font-size: 15px;
}
`;
export const Paragraph2 = styled.p`
font-size: 15px;
color: #666666;              
white-space: pre-line;
font-family: "Open Sans";
line-height: 24px;
${ props => { if (props.keepLine === true) { return 'white-space: pre-wrap;'; } }};
@media only screen and (max-width: 1252px){
    ${ props => { if (props.small === true) { return 'font-size:11px; line-height:21px'; } }};
}
@media only screen and (max-width: 850px){
    font-size: 15px;
}
`;
export const BlueText = styled.b`
font-weight: normal;
color: rgb(56,130,195);
`;
export const RedText = styled.b`
font-family: 'Open Sans';
font-weight: normal;
color: rgb(242, 12, 12);
`;

export const Inline = styled.div`
display: inline;
`;

const fadeIn = keyframes`
  from { 
   transform: translateX(-100%);
  }
  to{
    transform: translateX( 0%);
  }
`;

const fadeOut = keyframes`
  from { 
   opacity: 1;
  }
  to{
    opacity: 0;
  }
`;

const fadeIn2 = keyframes`
  from { 
   opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

const fadeOut2 = keyframes`

  from { 
    opacity: 1;
  }
  to{
    opacity: 0;
  }
`;

export const Fade = styled.div`
  display: block;
  align-self: center;
  visibility: ${props => props.out ? 'hidden' : 'visible'};
  animation: ${props => {
    if (props.outDirection === 'left') {
      if (props.out === true) { return fadeOut; } else { return fadeIn; }
    }
    if (props.outDirection === 'right') {
      if (props.out === true) { return fadeOut2; } else { return fadeIn2; }
    }

  }}
      0.5s linear;
  transition: visibility 0.3s linear;
`;