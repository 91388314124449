import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LargeHeight = '85px';

export const Container = styled.div`
position: fixed;
z-index: 999;
width: 100%;
background-color: #005B30;
height: ${LargeHeight};
white-space: nowrap;
@media only screen and (max-width: 1263px) {
    height: 65px;
}
`;

export const SubContainer = styled.div`
/* position: fixed; */
z-index: 999;
width: 100%;
background-color: #64A70A;
height: 35px;
white-space: nowrap;
@media only screen and (max-width: 1264px) {
    height: 30px;
}
`;


export const Inline = styled.div`
display: inline;
white-space: nowrap;
`;

export const Content = styled.div`
height: 100%;
margin-right: 15%;
float: right;
display: block;
width: auto;
white-space: nowrap;
text-decoration: none;
@media only screen and (max-width: 1800px) {
    margin-right: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-right: 6%;
}

`;
export const Element = styled(Link)`
text-decoration: none;
height: 100%;
width: auto;
margin-left: 25px;
display: inline-block;
white-space: nowrap;
/* background-color: red; */
@media only screen and (max-width: 1263px) {
    margin-left: 20px;
}
@media only screen and (max-width: 873px) {
    margin-left: 13px;
}
`;
export const ElementSmall = styled.div`
text-decoration: none;
height: 100%;
width: auto;
margin-left: 25px;
display: inline-block;
white-space: nowrap;
@media only screen and (max-width: 1263px) {
    margin-left: 20px;
}
`;
export const Label = styled.p`
white-space: nowrap;
font-weight: bold;
font-family: "Open Sans";
color: ${ props => props.isSelected === true ? '#64A70A' : '#FFFFFF'};
transition: color 0.2s;
margin-top: 42px;
text-decoration: none;
font-size: 15px;
@media only screen and (max-width: 1263px) {
    font-size: 11px;
    margin-top: 36px;
}
@media only screen and (max-width: 950px) {
    font-size: 10px;
    margin-top: 37px;
    font-weight: normal;
}
:hover{
    cursor: pointer;
}
`;

export const TopLabel = styled.p`
white-space: nowrap;
font-weight: bold;
font-family: "Open Sans";
color: #FFFFFF;
margin-top: 8px;
font-size: 15px;
@media only screen and (max-width: 1263px) {
    font-size: 11px;
    margin-top: 8px;
}
@media only screen and (max-width: 950px) {
    margin-top: 8px;
    font-weight: normal;
}
:hover{
    cursor: pointer;
}
`;

export const Arrow = styled.img`
width: 15px;
height: 10px;
margin-right: -10px;
`;
export const Logo = styled.img`
display: inline-block;
width: 300px;
height: auto;
margin-left: 15%;
margin-top: 20px;
@media only screen and (max-width: 1800px) {
    margin-left: 10%;
}
@media only screen and (max-width: 1500px) {
    margin-left: 6%;
    width: 300px;
    height: auto;
    margin-top: 19px;
}
@media only screen and (max-width: 1263px) {
    max-width: 300px;
    margin-top: 15px;
    width: 230px;
}
@media only screen and (max-width: 950px) {
    max-width: 190px;
    margin-top: 23px;
    margin-left: 5%;
}
`;

export const DropDown = styled.div`
/* display: ${ props => { if (props.show === true) { return 'auto'; } else { return 'none'; } }}; */
display: ${ props => { if (props.show === true) { return 'flex'; } else { return 'none'; } }};
flex-direction: column;
position: absolute;
width: 230px;
height: 480px;
right: 320px;
margin-right: 15%;
top: 120px;
/* background-color: rgba(86, 95, 110,0.3); */
background-color: #005B30;
z-index: 3;
border-top: 0px solid gray;
border: 1px solid rgba(86, 95, 110 , 0.3);
text-align: left;
@media only screen and (max-width: 1800px) {
    right: 275px;
}
@media only screen and (max-width: 1500px) {
    right:230px;
}
@media only screen and (max-width: 1250px) {
    height: 430px;
    width: 170px;
    right: 150px;
    top: 90px;
}
@media only screen and (max-width: 950px) {
     right: 150px;
}
`;

export const ListItem = styled(Link)`
text-decoration: none;
font-family: "Open Sans";
font-size: ${ props => props.static === true ? '15px' : '13px'};
font-weight: ${ props => props.static === true ? 'bold' : 'normal'};
color: #FFFFFF;
z-index: 20;
word-break: break-word;
white-space: pre-line;
padding-left: 20px;
margin-bottom: 2px;
margin-top: ${ props => props.static === true ? '12px' : '5px'};
/* font-weight: bold; */
/* border-bottom: 1px solid gray; */
@media only screen and (max-width: 1250px) {
    font-size: 11px;
}
@media only screen and (max-width: 950px) {
    font-size: 10px;
}
transition: color 0.1s;
:hover{
    cursor: ${ props => props.static === true ? 'default' : 'pointer'}; 
    color: ${ props => props.static === true ? '#FFFFFF' : '#64A70A'};   
}
`;
