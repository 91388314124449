import styled from 'styled-components';

export const Container = styled.div`
margin-top: 50px;
display: flex;
margin-bottom: 50px;
flex-wrap: nowrap;
align-self: center;
justify-content: space-between;
width: 80%;
word-break: break-word;
/* border: 1px solid green; */
flex-direction: ${ props => props.reverse === true ? 'row-reverse' : 'row'};
white-space: pre-line;
height: 400px;
overflow: visible;
@media only screen and (max-width: 1250px) {
    width: 90%;
}
@media only screen and (max-width: 1100px) {
    margin-bottom: 0px;
}
@media only screen and (max-width: 850px) {
    height: auto;
    justify-content: flex-start;
    /* flex-direction: ${ props => props.reverse === true ? 'column-reverse' : 'column'}; */
    flex-direction: column;
}
`;

export const Section = styled.div` 
display: flex;
vertical-align: top;
flex-direction: column;
justify-content: center;
vertical-align: center;
/* background-color: red; */
width: 50%;
word-break: break-word;
height: 350px;
/* border: 1px solid green; */

@media only screen and (max-width: 1250px) {
}
@media only screen and (max-width: 1100px) {
    /* width: 70%; */
    width: 400px;
}
@media only screen and (max-width: 850px) {
    /* height: 100%; */
    /* height: 300px; */
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-left: 0%;
    display: block;
    margin-bottom: 5px;
    ${ props => {
        if (props.graySpace === true) {
            return 'margin-top: 20px; margin-bottom: 45px;';
        }
    }
    }
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;

export const Section2 = styled.div` 
display: flex;
align-self: flex-start;
flex-direction: row;
justify-content: ${ props => props.reverse === true ? 'flex-end' : 'flex-start'};
/* border: 1px solid blue; */
overflow:hidden;
width: 35%;
height: 100%;
@media only screen and (max-width: 1400px) {
    width: 50%;
}
@media only screen and (max-width: 1100px) {
    width: auto;
}
@media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 5px;
    height: auto;
    ${ props => {
        if (props.graySpace === true) {
            return 'margin-top: 20px; margin-bottom: 45px;';
        }
    }
    }
}
@media only screen and (max-width: 400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}
`;
export const Hack = styled.div`
width: 7.5%;
height: 100%;
@media only screen and (max-width: 1400px) {
    width: 0%;
}
`;
export const Inline = styled.div`
display: inline;
@media only screen and (max-width: 850px) {
    display: inline-block;
}
`;

export const InlineIcon = styled.div`
margin-top: 45px;
@media only screen and (max-width: 1250px) {
    margin-top: 0px;
}
@media only screen and (max-width: 850px) {
    margin-top: 10px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;

export const Image = styled.img`
width: auto;
margin-top: 0%;
height: 100%;
border-radius: 100%;
display: flex;
/* border: 1px solid pink; */
align-self: flex-start;
vertical-align: center;
@media only screen and (max-width: 1400px) {
    height: 100%;
}
@media only screen and (max-width: 1250px) {
    min-height: 310px;
}
@media only screen and (max-width: 1100px) {
    height: 250px;
    min-height: 250px;
    width: auto;
}
@media only screen and (max-width: 850px) {
    width: auto;
    height: 200px;
    min-height: 200px;;
    margin-top: 0px;
    /* min-height: 100%; */
}
`;

export const Content = styled.div`
width: 100%;
height: 150px;
`;
export const Header = styled.div`
width: 100%;
/* border: 1px solid green; */
text-align: left;
display: flex;
flex-direction: row;

margin-bottom: 20px;
@media only screen and (max-width: 850px) {
    text-align: center;
    display: flex;
    justify-content: center;
}
`;
export const Center = styled.div`

`;
export const Title = styled.h2`
/* display: inline-block; */
display: flex;
white-space: wrap;
width: auto;
word-break: break-word;
font-family: 'Open Sans';
font-style: normal;
font-weight: bold;
font-size: 29px;
/* align-self: ${ props => props.reverse === true ? 'flex-end' : 'flex-start'}; */
align-self: flex-end;
color: #005B30;

@media only screen and (max-width: 850px) {
  align-self: center; 
 font-size: 27px;   
 text-align: center;
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;
export const Paragraph = styled.p`
font-size: 18px;
color: rgb(101,101,101);        

display: flex;

white-space: pre-line;
font-family: "Open Sans";
line-height: 24px;
${ props => { if (props.keepLine === true) { return 'white-space: pre-wrap;'; } }};
@media only screen and (max-width: 1252px){
    ${ props => { if (props.small === true) { return 'font-size:11px; line-height:21px'; } }};
}
@media only screen and (max-width: 850px){
    font-size: 15px;
}
`;

export const Padding = styled.div`
width: 100%;
margin-left: 0%;
margin-top: 5%;
display: flex;
flex-direction: column;
align-self: center;
flex-wrap: wrap;
justify-content: c;
@media only screen and (max-width: 850px) {
  margin-left: 0px; 
  text-align: center;
  display: flex;
  justify-content: center; 
  flex-direction: column;
  width: 100%;  

}
`;

export const Icon = styled.img`
width: 100px;
height: 100px;
border-radius: 25px;
display: inline-block;
margin-right: 20px;
@media only screen and (max-width: 1400px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 850px) {
width: 85px;
height: 85px;
}
@media only screen and (max-width: 510px) {
}
@media only screen and (max-width: 400px) {
}
`;