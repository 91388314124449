import React from 'react';
import {
    Container,
    Label,
    Content,
    Inline,
    Element,
    Logo,
    DropDown,
    ListItem,
    SubContainer,
    MobileMenu,
    SideDrawer,
    MenuItem,
    LinkItem,
    Arrow,
    PreContainer,
    PhoneText,
    Line,
    Row,
    Question,
    RowReveal,
    Answer,
    Arrow2,
    SubRow
} from './styled';

// import LogoImage from '../../../assets/logo/logo.png';

import { connect } from 'react-redux';
import Actions from '../../../store/Actions';
import Config from '../../../config';

import axios from 'axios';

const mapStateToProps = (state) => {
    return {
        lang: state.language
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        languageSetSerbian: () => dispatch(Actions.changeLanguage.serbian()),
        languageSetEnglish: () => dispatch(Actions.changeLanguage.english()),
        languageSetGerman: () => dispatch(Actions.changeLanguage.german()),
        startScroll: () => dispatch(Actions.scroll.startScroll()),
    }
}

class Mobile extends React.Component {
    state = {
        loading: true,
        dropdown: false,
        menu: false,
        productMenu: false
    }
    handleClick() {
        this.setState({ ...this.state, productMenu: !this.state.productMenu });
    }
    handleLanguage(lang) {
        if (lang === 'en') {
            this.props.languageSetEnglish();
        }
        if (lang === 'sr') {
            this.props.languageSetSerbian();
        }
        if (lang === 'de') {
            this.props.languageSetGerman();
        }
        this.setState({ ...this.state, dropdown: false });
    }
    showLanguage() {
        this.setState({ ...this.state, dropdown: !this.state.dropdown });
    }
    showMenu() {
        this.setState({ ...this.state, menu: !this.state.menu });
    }
    componentDidMount() {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    switch (data.country_name) {

                        case 'United Kingdom':
                            this.props.languageSetEnglish();
                            break;
                        case 'Serbia':
                            this.props.languageSetSerbian();
                            break;
                        case 'Germany':
                            this.props.languageSetGerman();
                            break;
                        case 'Austria':
                            this.props.languageSetGerman();
                            break;
                        default:
                            this.props.languageSetEnglish();

                    }

                }).catch((error) => {
                    console.log(error);
                });
                this.setState({ ...this.state, loading: false });
            }
            else {
                this.setState({ ...this.state, loading: false });
            }
        }
        catch (err) {
            this.props.languageSetEnglish();
            this.setState({ ...this.state, loading: false });
        }
    }
    render() {
        return (
            <>
                {
                    this.props.lang && !this.state.loading ?
                        <div stlye={{ display: 'flex', flexDirection: 'column', position: 'fixed' }}>
                            <SubContainer>
                                <PreContainer>
                                    <PhoneText href="tel:+381-11-239-00-86 ">
                                        +381 69 233 75 47
                                </PhoneText>
                                </PreContainer>
                            </SubContainer>
                            <Container>
                                <SubContainer>
                                    <Inline>
                                        {/* <Logo src={LogoImage} /> */}
                                        <Content>
                                            <Logo src={Config.images.home.mobile.logo} />
                                            {/* <Element onClick={() => this.showLanguage()}> <Label>{'Lang'} */}
                                            {/* <Arrow src={Config.images.general.DownArrowMenu} /> */}
                                            {/* </Label></Element> */}
                                        </Content>
                                        <MobileMenu src={Config.images.icon.Mobile.MobileMenu} onClick={() => this.showMenu()} />
                                    </Inline>
                                </SubContainer>
                            </Container>
                            <SubContainer>
                                <DropDown show={this.state.dropdown}>
                                    <ListItem style={{ marginTop: '30px' }} onClick={() => this.handleLanguage('en')}>{'EN'}</ListItem>
                                    <ListItem onClick={() => this.handleLanguage('sr')}>{'SR'}</ListItem>
                                </DropDown>
                                <SideDrawer open={this.state.menu}>
                                    <MenuItem ><LinkItem to='/' onClick={() => this.showMenu()}>{this.props.lang.navigation.home}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/about' onClick={() => this.showMenu()}>{this.props.lang.navigation.about}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/herbs' onClick={() => this.showMenu()}>{this.props.lang.navigation.plants}</LinkItem></MenuItem>
                                    {/* <MenuItem ><LinkItem to='/products' onClick={() => this.showMenu()}>{this.props.lang.navigation.products}</LinkItem></MenuItem> */}
                                    <Row>
                                        <SubRow onClick={() => this.handleClick()} className='row_selector'>
                                            <Question>{this.props.lang.navigation.products}</Question>
                                            <Arrow src={this.state.productMenu === true ? Config.images.icon.ArrowTopGreen : Config.images.icon.ArrowBottomGreen} />
                                        </SubRow>
                                        <RowReveal active={this.state.productMenu}>
                                            {/* <Answer>Popuni me</Answer> */}
                                            <ListItem onClick={() => console.log()} static={true}>KAPI</ListItem>
                                            <ListItem to='/products/0'>Femisan A</ListItem>
                                            <ListItem to='/products/1'>Femisan B</ListItem>
                                            <ListItem to='/products/4'>Nefrovit</ListItem>
                                            <ListItem to='/products/5' onClick={() => console.log()}>Leocardin</ListItem>
                                            <ListItem to='/products/6' onClick={() => console.log()}>Hipoprostat</ListItem>
                                            <ListItem to='/products/7' onClick={() => console.log()}>Equigal</ListItem>
                                            <ListItem to='/products/8' onClick={() => console.log()}>Optima Forma</ListItem>
                                            <ListItem to='/products/9' onClick={() => console.log()}>Disan</ListItem>
                                            <ListItem onClick={() => console.log()} static={true}>KAPSULE</ListItem>
                                            <ListItem to='/products/2' onClick={() => console.log()}>Femisan A plus zlatna maca</ListItem>
                                            <ListItem to='/products/3' onClick={() => console.log()}>Alfa aktiv</ListItem>
                                            <ListItem onClick={() => console.log()} static={true}>HS KOZMETIKA</ListItem>
                                            <ListItem to='/products/10' onClick={() => console.log()}>za negu peta</ListItem>
                                            <ListItem to='/products/11' onClick={() => console.log()}>za regeneraciju kože</ListItem>
                                            <ListItem to='/products/13' onClick={() => console.log()}>za dekolte</ListItem>
                                            <ListItem to='/products/12' onClick={() => console.log()}>protiv virusnih bradavica</ListItem>
                                        </RowReveal>
                                    </Row>
                                    <MenuItem ><LinkItem to='/certificate' onClick={() => this.showMenu()}>{this.props.lang.navigation.certificate}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/questions' onClick={() => this.showMenu()}>{this.props.lang.navigation.questions}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/blog/1' onClick={() => this.showMenu()}>{this.props.lang.navigation.blog}</LinkItem></MenuItem>
                                    <MenuItem ><LinkItem to='/contact' onClick={() => this.showMenu()}>{this.props.lang.navigation.contact}</LinkItem></MenuItem>
                                </SideDrawer>
                            </SubContainer>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
