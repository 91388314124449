import React from 'react';
import {
    Container,
    LeftArrow,
    RightArrow,
    ImageHolder,
    ArrowImage,
    ArrowImageRight,
    Section1,
    Section2,
    TextSection,
    WeedImage,
    RightSectionHolder,
    AnotherHolder,
} from './styled';
import Config from '../../config';
import { GrayParagraphLoreSmall } from '../_components';
import Button from '../Button';

class GreenSection extends React.Component {
    findNext(direction) {
        if (direction === "right") {
            if (parseInt(this.props.id) === (Config.localdata.herbs.length - 1)) {
                window.location.replace('/#/herbs/0');
            }
            else {
                window.location.replace('/#/herbs/' + (parseInt(this.props.id) + 1));
            }
        }
        else {
            if (parseInt(this.props.id) !== 0) {
                window.location.replace('/#/herbs/' + (parseInt(this.props.id) - 1));
            }
            else {
                window.location.replace('/#/herbs/' + (Config.localdata.herbs.length - 1));
            }
        }
    }
    changePage(location) {
        window.location.replace('/#/blog/article/' + location);
    }
    render() {
        return (
            <Container extended={this.props.extended} flexible={this.props.flexible ? this.props.flexible : false}>
                <LeftArrow extended={this.props.extended} onClick={() => this.findNext('left')}>
                    <ImageHolder>
                        <ArrowImage src={Config.images.icon.ArrowLeft} />
                    </ImageHolder>
                </LeftArrow>
                <Section1>
                    <AnotherHolder>
                        <TextSection>
                            <GrayParagraphLoreSmall>
                                {
                                    this.props.text
                                }
                            </GrayParagraphLoreSmall>
                            {
                                this.props.button === true ?
                                    <Button text={'Saznajte više'} margin={'0px'} special1={true} onClick={() => this.changePage(Config.localdata.herbs[this.props.id].blogLocation)} />
                                    :
                                    <></>
                            }
                        </TextSection>
                    </AnotherHolder>
                </Section1>
                <Section2>
                    <RightSectionHolder>
                        <WeedImage src={this.props.roundImage} />
                    </RightSectionHolder>
                </Section2>
                <RightArrow extended={this.props.extended} onClick={() => this.findNext('right')}>
                    <ImageHolder>
                        <ArrowImageRight src={Config.images.icon.ArrowRight} />
                    </ImageHolder>
                </RightArrow>
            </Container>
        )
    }
}

export default GreenSection;