import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: auto;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
`;
export const Row = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
height: auto;
width: 100%;

`;
export const SubRow = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
height: auto;
width: 100%;

`;
export const Line = styled.div`
width: 100%;
border-top: 1px solid #005B30; 
margin-bottom: 4px;
`;
export const Question = styled.h2`
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 19px;
color: #005B30;
display: flex;
align-self: flex-start;
cursor: pointer;

-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const Answer = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
white-space: pre-line;
margin-top: 7px;
font-size: 15px;
line-height: 19px;
color: #000000;
display: flex;
align-self: flex-start;

`;
export const RowReveal = styled.div`
text-align: start;
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
margin-bottom: ${ props => props.active ? '55px' : '7px'};
margin-top: ${ props => props.active ? '15px' : '5px'};
height: ${ props => props.active ? 'auto' : '0px'};
height: 0;
/* max-height: 300px; */
/* height: auto; */
opacity: 1;
overflow: hidden;
/* transform:scaleY(0); */
/* transform-origin:top; */
  /* transition:  0.3s ease-in; */
  ${Row}:hover & {
    opacity: 1;
    height: ${ props => props.active ? 'auto' : '0px'};
    /* transform: ${ props => props.active ? 'scaleY(1)' : 'scaleY(0)'};  */
  }
`;

export const Arrow = styled.img`
width: 20px;
margin-top: 17px;
height: 10px;
display: flex;
margin-left: 10px;
-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  cursor: pointer;
`;