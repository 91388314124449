import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Jumbotron from '../../components/Jumbotron';
import MainSection from '../../components/MainSection';
import QASection from '../../components/QASection';
import FixedSpacing from '../../components/FixedSpacing';
import styled from 'styled-components';
import ScrollToTop from '../../components/ScrollToTop';

const QAContainer = styled.div`
width: 50%;
display: flex;
align-self: center;
margin-top: 70px;
@media only screen and (max-width: 1400px) {
    width: 75%;
}
`;

const mapStateToProps = (state) => {
    return {
        text: state.language.QA,
        scroll: state.scroll,
        firstTimeVar: state.firstTime,
        lang: state.language.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class QA extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <Jumbotron
                    background={Config.images.QA.background}
                    backgroundMobile={Config.images.QA.background}
                />
                <MainSection title={'Česta pitanja'} font={'Sans'}>
                </MainSection>
                <QAContainer >
                    <QASection questions={this.props.text.questions} lang={this.props.lang} />
                </QAContainer>
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QA);