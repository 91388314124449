import React from 'react';
import { Container, Section, Section2, Image, Column, Title, Padding, Header, WrapperWrapper } from './styled2';
import { Inline, Paragraph } from '../../styles';
import Config from '../../config';
import AboutSection from './AboutSection';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        text: state.language.about
    }
}

class AltAboutSection extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: 0,
            userName: ''
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        if (this.state.windowWidth > 850) {
            return (

                <Container>
                    {
                        this.props.text ?
                            <>
                                <Column>
                                    <Inline>
                                        <Section graySpace={true}>
                                            <Padding>
                                                <Header>
                                                    <Title>{this.props.title}</Title>
                                                </Header>
                                                <Paragraph small={true}>{this.props.words}</Paragraph>
                                            </Padding>
                                        </Section>
                                        <Section2 first>
                                            <Image src={this.props.roundImage} />
                                        </Section2>
                                    </Inline>
                                </Column>
                            </>
                            :
                            <>
                            </>
                    }
                </Container>

            );
        }
        else {
            return <AboutSection title={this.props.title} words={this.props.words} roundImage={this.props.roundImage} />
        }
    }
}

export default connect(mapStateToProps, null)(AltAboutSection);