import styled from 'styled-components';

export const PatrickHand = styled.h1`
font-weight: normal;
font-size: 25px;
align-self: center;
font-family: 'Opens Sans';
color: #666666;
`;

export const PatrickHandLarge = styled.h1`
font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 49px;
align-self: center;

margin-bottom: 50px;
color: #666666;
`;

export const GrayParagraph = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 19px;
color: #666666;
`;
export const GrayParagraphFlexCenter = styled.p`
align-self: center;
text-align: center;
font-family: "Lora";
font-style: normal;
font-weight: normal;
word-break: break-all;
white-space: pre-line;
font-size: 19px;
color: #666666;
`;
export const GreenOpenSans = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 30px;
font-weight: bold;
color: #005B30;
`;

export const GreenOpenSansFlexCenter = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
font-size: 25px;
/* line-height: 38px; */
text-align: center;
display: flex;
align-self: center;
color: #005B30;
margin-bottom: 40px;

`;

export const GrayParagraphLoreSmall = styled.p`
font-family: "Lora";
font-style: normal;
font-weight: normal;
font-size: 16px;
color: #FFFFFF;
white-space: pre-line;
`;
export const GrayParagraphLoreSmallThatIsActuallyNotWhite = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 18px;
/* white-space: pre-line; */
word-break: break-word;
line-height: 25px;
color: #666666;
white-space:pre-line;
`;

export const GrayParagraphLoreSmallThatIsActuallyNotWhiteAndthisTimeReallySmall = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: normal;
font-size: 15px;
white-space: pre-line;
word-break: break-word;
margin-left: 10px;
line-height: 25px;
color: #666666;
white-space:pre-line;
text-align: left;
align-self: flex-start;
display: flex;
`;

export const GrayParagraphLoreSmallThatIsActuallyNotWhiteButTurnedOutToBeGreen = styled.p`
font-family: "Open Sans";
font-style: normal;
font-weight: bold;
font-size: 15px;
white-space: pre-line;
word-break: normal;
line-height: 25px;
color: #005B30;
white-space:pre-line;
`;

export const InlineHolder = styled.div`
display: flex;
flex-direction: row;
align-self: center;
`;
export const GreenText = styled.p`
font-family: 'Lora';
font-style: normal;
font-weight: normal;
font-size: 30px;
color: #005B30;
white-space: pre-line;
word-break: break-word;

@media only screen and (max-width: 1500px) {
font-size: 25px;
}
@media only screen and (max-width: 1000px) {
font-size: 19px;
}
`;

export const GreenTextResponsive = styled.p`
font-family: 'Lora';
font-style: normal;
font-weight: normal;
font-size: 30px;
color: #005B30;
`;

export const GreenLabel = styled.p`
background-color:#005B30;
margin-top: 40px;
width: 70%;
padding-left: 50px;
padding-right: 50px;
padding-top: 50px;
padding-bottom: 50px;
font-family: "Open Sans";
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 49px;
/* font-style: normal; */
/* font-weight: normal; */
/* font-size: 29px; */
text-align: center;
color: #FFFFFF;
align-self: center;
white-space: pre-wrap;

@media only screen and (max-width: 1740px) {
font-size: 29px;
}
@media only screen and (max-width: 1410px) {
font-size: 23px;
}
@media only screen and (max-width: 1115px) {
font-size: 18px;
}
`;
export const GreenLabelHolder = styled.div`
width: 100%;
display: flex;
flex-direction: column;
flex-wrap: wrap;
`;