import React from 'react';

import {
    Container,
    ImageContainer,
    Inline,
    ImageView,
    FuckingImage,
    TextContainer
} from './styled';

import Config from '../../config';

class HerbGallery extends React.Component {
    state = {
        hoverId: -1,
        selectedImage: '',
        showFull: false,
        category: 'all',
        windowWidth: 0,
        selectedIndex: 0,
    }
    ////////////////////////////////////////
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        return (
            <Container>
                {/* <Inline> */}
                {
                    Config.localdata.herbs.map((element, index) => {
                        return (
                            <ImageContainer
                                image={element.herbIcon}
                                onClick={() => window.location.replace('/#/herbs/' + index)}
                            >
                                <ImageView show={element.id === this.state.hoverId}>
                                    <FuckingImage src={element.herbIcon} />
                                    <TextContainer>
                                        <p>{element.name_sr}</p>
                                    </TextContainer>
                                </ImageView>
                            </ImageContainer>
                        )
                    })

                }
                {/* </Inline> */}
            </Container>
        );
    }
}

export default HerbGallery;