import React from 'react';
import styled from 'styled-components';
import Config from '../../config';

const Container = styled.div`
margin-top: 135px;
display: flex;
flex-direction: column;
align-self: center;
width: 90%;
/* background-color: red; */
text-align: center;
@media only screen and (max-width: 1300px) {
    width: 50%;
}
`;
const SubContainer = styled.div`
display: flex;
align-self: center;
flex-direction: row;
`;
const Item = styled.p`
padding-left: 20px;
padding-right: 20px;
/* background-color: red; */
${
    props => {
        if (props.selected === true) {
            return 'font-size: 36px; color: #FFFFFF; margin-top: 7px; padding-top: 0px;';
        }
        else {
            return 'font-size: 12px;';
        }
    }
    }
font-weight: bold;
display: flex;
align-self: center;
white-space: pre-line;
@media only screen and (max-width: 1500px) {
    ${
    props => {
        if (props.selected === true) {
            return 'font-size: 33px; color: #FFFFFF; margin-top: 9px; padding-top: 0px;';
        }
        else {
            return 'font-size: 10px;';
        }
    }
    }
    padding-left: 15px;
    padding-right: 15px;
}
`;
const Element = styled.div`
color: #005B30;
width: 125px;
height: 55px;
margin-right: 20px;
margin-top: 20px;
border: 2px solid #005B30;
border-radius: 4px;
display: inline-block;
align-self: center;
text-align: center;
flex-direction: row;
overflow: hidden;
transition: background-color 0.3s;
:hover{
    cursor: pointer;
    background-color: #005B30;
}
@media only screen and (max-width: 1500px) {
    width: 100px;
}
`;
export const Hack = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-self: center;
`;
const Inline = styled.div`
display: inline;
flex-direction: column;

/* text-align: center; */
`;

const catalogGoesByManyNames = [
    'KATALOG\nPROIZVODA',
    'PRODUCT\nCATALOG',
    'CATALOGO DE\nPRODUCTO',
    'КАТАЛОГ НА\nПРОДУКТИТЕ',
    'КАТАЛОГ НА\nПРОИЗВОДИ',
    'KATALOGU I\nPRODUKTEVE',
    'ÜRÜN\nKATALOĞU',
    'KATALOG DER\nPRODUKTE',
    'CATALOG IN\nARABIC',
]

class ProductCatalog extends React.Component {
    state = {
        hover: [false, false, false, false, false, false, false, false, false]
    }
    onHover(index) {
        let hover = this.state.hover;
        hover[index] = true;
        this.setState({
            hover
        });
    }
    noHover(index) {
        let hover = this.state.hover;
        hover[index] = false;
        this.setState({
            hover
        });
    }
    render() {
        return (
            <Container>
                <SubContainer>
                    <Inline>
                        {/* <a href={Config.pdf.home.katalog_proizvoda} download>
                            <Element onMouseEnter={() => this.onHover(0)} onMouseLeave={() => this.noHover(0)}><Hack><Item selected={this.state.hover[0]}><i class="fas fa-download" ></i></Item></Hack></Element>
                        </a> */}
                        <a href={Config.pdf.home.katalog_proizvoda} download>
                            <Element onMouseEnter={() => this.onHover(0)} onMouseLeave={() => this.noHover(0)}><Hack><Item selected={this.state.hover[0]}>{this.state.hover[0] === true ? <i class="fas fa-download" ></i> : catalogGoesByManyNames[0]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_engleski} download>
                            <Element onMouseEnter={() => this.onHover(1)} onMouseLeave={() => this.noHover(1)}><Hack><Item selected={this.state.hover[1]}>{this.state.hover[1] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[1]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_spanski} download>
                            <Element onMouseEnter={() => this.onHover(2)} onMouseLeave={() => this.noHover(2)}><Hack><Item selected={this.state.hover[2]}>{this.state.hover[2] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[2]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_bugarski} download>
                            <Element onMouseEnter={() => this.onHover(3)} onMouseLeave={() => this.noHover(3)}><Hack><Item selected={this.state.hover[3]}>{this.state.hover[3] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[3]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_makedonski} download>
                            <Element onMouseEnter={() => this.onHover(4)} onMouseLeave={() => this.noHover(4)}><Hack><Item selected={this.state.hover[4]}>{this.state.hover[4] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[4]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_sladoljed} download>
                            <Element onMouseEnter={() => this.onHover(5)} onMouseLeave={() => this.noHover(5)}><Hack><Item selected={this.state.hover[5]}>{this.state.hover[5] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[5]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_turski} download>
                            <Element onMouseEnter={() => this.onHover(6)} onMouseLeave={() => this.noHover(6)}><Hack><Item selected={this.state.hover[6]}>{this.state.hover[6] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[6]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_nemacki} download>
                            <Element onMouseEnter={() => this.onHover(7)} onMouseLeave={() => this.noHover(7)}><Hack><Item selected={this.state.hover[7]}>{this.state.hover[7] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[7]}</Item></Hack></Element>
                        </a>
                        <a href={Config.pdf.home.katalog_arapski} download>
                            <Element onMouseEnter={() => this.onHover(8)} onMouseLeave={() => this.noHover(8)}><Hack><Item selected={this.state.hover[8]}>{this.state.hover[8] === true ? <i class="fas fa-download"></i> : catalogGoesByManyNames[8]}</Item></Hack></Element>
                        </a>
                    </Inline>
                </SubContainer>
            </Container>
        )
    }
}

export default ProductCatalog;