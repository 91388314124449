import images from '../config';

const srb = {
    lang: 'sr',
    version: 2,
    navigation: {
        home: 'NASLOVNA',
        about: 'O NAMA',
        plants: 'NAŠE BILJKE',
        products: 'PROIZVODI',
        certificate: 'SERTIFIKATI',
        questions: 'ČESTA PITANJA',
        blog: 'BLOG',
        contact: 'KONTAKT',
        english: 'EN',
        serbian: 'SR',
        phone: '+381 11 239 00 86',
    },
    about: {
        partners_title: 'IZJAVE KORISNIKA',
        partners_content: [
            {
                name: 'Nikolina Crnogorac',
                text: "Do svoje 50 godine mesečni ciklusi su mi bili uredni, na 28 dana, kao po knjizi. Onda sam preko noći upala u menopauzu. Jajnici su jednostavno prestali da rade. Predložili su mi Femisan B. Ali ja sam htela da ostanem još malo mlada. Posle par meseci uzimanja Femisana A jajnik je proradio i ciklusi su se vratili.",
                image: images.images.about.people.nikolina_crnogorac,
            },
            {
                name: 'Marija',
                text: "Posle stresa i gubitka kilograma izgubila sam ciklus. Nisam imala nepuna 4 meseca. U međuvremenu sam počela da pijem Femisan A sa makom 2x2 kapsule. Posle dve nedelje mi se vratio ciklus, sada pijem 5 dana pre ciklusa. Zahvalna sam do neba.",
                image: images.images.about.people.marija,
            },
            {
                name: 'Snežana Števanov',
                text: "Pomoću Devi melema za regeneraciju brzo sam rešila problem alergije na sunce koja me je mučila godinama. Melem ne samo da je ublažio svrab i peckanje, već se i osip potpuno povukao. Rešila sam trajno problem za koji su mi svi pričali da će me mučiti doživotno.",
                image: images.images.about.people.snezana,
            },
            {
                name: 'Suzana Mančić',
                text: "Redovno kombinujem Femisan A kapsule, zbog zlatne make koje mi daju energiju i Femisan B, od koga se ne odvajam još od menopauze. Tada me je spasio od valunga, a sada ga i dalje koristim, jer od njega mnogo bolje spavam.",
                image: images.images.about.people.suzana,
            },
            {
                name: 'Sandra Manojlović',
                text: "Deset godina sam pokušavala da ostanem u drugom stanju i lečila se od steriliteta, nakon samo 3 bočice Femisana A u trideset devetoj godini sam ostala u drugom stanju i postala mama. Hvala Femisanu A i Herba Svetu!",
                image: images.images.about.people.sandra,
            },
            {
                name: 'Gordana Mišić',
                text: "Pre par godina mi je potvrđeno da imam zadebljanje endometrijuma i polip koji je povremeno krvario, pa je preporučena revizija. Nažalost, krvarenja su se nastavila, uz veliko zadebljavanje endometrijuma i doktor je savetovao operaciju, odstranjivanje svega. Rešila sam da sačekam i probam sa Femisanom A. Na kontrolama koje su sledile uočeno je značajno smanjivanje zadebljanja. Femisan  A me je spasao od operacije.",
                image: images.images.about.people.gordana,
            },
        ]
    },
    certificate: {
        awards: '',
        chronology: '1996. Osnovan „Herba Svet D.O.O.“ \n2004 - 2010. Osvojene ukupno 21 zlatne medalje za kvalitet proizvoda \n2004. FemiSan A je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja \n2005. Nefrovit je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja \n2006. Prelazak u novi proizvodni prostor n\ 2007. Specijalna povelja za visok kvalitet proizvoda (Sajam ekologije, NS) \n2009. Prvi zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2010. Veliki šampionski zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2011. Treći zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) \n2013. Sertifikacija Sistema upravljanja kvalitetom: ISO 9001:2008 i HACCP \n2014. Excellent SME sertifikat Privredne komore Srbije \n2014. Finalisti izbora „Najbolje iz Srbije“ PKS \n2014. Zahvalnica Humanitarne fondacije Novi Beograd „Budi human“ \n2015. „Vip BizPartner 2014“ za najbolju porodičnu firmu Privredna komora Srbije \n2015. Sertifikat A za izvrstan bonitet poslovanja - BISNODE SRBIJA \n2015, 2016, 2017, 2018, 2019. Sertifikat AAA za izvrstan bonitet poslovanja - BISNODE SRBIJA \n2015, 2016, 2017, 2018, 2019. Sertifikat Excellent SME za izvrsno poslovanje - Privredna komora Srbije \n2018. FEMISAN A - Balkan Awards za najbolji regionalni brend u 2018. godini \n2018. Beogradski Pobednik Herba Svetu kao uspešnoj kompaniji \n2018. Veliki zlatni pehar Novosadskog Sajma za kvalitet proizvoda',
        chronology_new: [
            {
                year: '2004 - 2010.',
                text: 'Osvojene ukupno 21 zlatne medalje za kvalitet proizvoda',
            },
            {
                year: '2004.',
                text: 'FemiSan A je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja',
            },
            {
                year: '2005.',
                text: 'Nefrovit je osvojio zlatnu medalju za kvalitet proizvoda na Novosadskom sajmu lekovitog bilja',
            },
            {
                year: '2006.',
                text: 'Prelazak u novi proizvodni prostor',
            },
            {
                year: '2007.',
                text: 'Specijalna povelja za visok kvalitet proizvoda (Sajam ekologije, NS)',
            },
            {
                year: '2009.',
                text: 'Prvi zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS) 2010. Veliki šampionski zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS)',
            },
            {
                year: '2010.',
                text: 'Veliki šampionski zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS)',
            },
            {
                year: '2011.',
                text: 'Treći zlatni pehar za kvalitet proizvoda (Sajam ekologije, NS)',
            },
            {
                year: '2013.',
                text: 'Sertifikacija Sistema upravljanja kvalitetom: ISO 9001:2008 i HACCP',
            },
            {
                year: '2014.',
                text: 'Excellent SME sertifikat Privredne komore Srbije',
            },
            {
                year: '2014.',
                text: 'Finalisti izbora „Najbolje iz Srbije“ PKS',
            },
            {
                year: '2014.',
                text: 'Zahvalnica Humanitarne fondacije Novi Beograd „Budi human“',
            },
            {
                year: '2015.',
                text: '„Vip BizPartner 2014“ za najbolju porodičnu firmu Privredna komora Srbije',
            },
            {
                year: '2015.',
                text: 'Sertifikat A za izvrstan bonitet poslovanja - BISNODE SRBIJA',
            },
            {
                year: '2015,2016,2017,2018,2019.',
                text: 'Sertifikat AAA za izvrstan bonitet poslovanja - BISNODE SRBIJA',
            },
            {
                year: '2015,2016,2017,2018,2019.',
                text: 'Sertifikat Excellent SME za izvrsno poslovanje - Privredna komora Srbije',
            },
            {
                year: '2018.',
                text: 'FEMISAN A - Balkan Awards za najbolji regionalni brend u 2018. godini',
            },
            {
                year: '2018.',
                text: 'Beogradski Pobednik Herba Svetu kao uspešnoj kompaniji',
            },
            {
                year: '2018.',
                text: 'Veliki zlatni pehar Novosadskog Sajma za kvalitet proizvoda ',
            },

            {
                year: '2019.',
                text: 'Oskar popularnosti FEMISAN A',
            },
            {
                year: '2020.',
                text: 'Balkan Awards FEMISAN A',
            },
        ],
        certificate: "",

    },
    QA: {
        questions: [
            {
                title_sr: "Koliko dugo smem da koristim vaše preparate?",
                title_en: "",
                text_sr: " Kada se koriste po preporučenom načinu upotrebe, naši preparati se mogu koristiti neograničeno dugo - tj dok se ne postignu zadovoljavajući rezultati.",
                text_en: ""
            },
            {
                title_sr: "Da li smem da koristim vaše preparate iako već koristim terapiju koju mi je lekar prepisao?",
                title_en: "",
                text_sr: "DA, naše preparate možete da koristite uz drugu terapiju jer nema kontraindikacija i nuspojava kada se koriste sa drugim lekovima ili pomoćnim lekovitim sredstvima. Terapiju (lekove) koje je doktor prepisao ne smete samoinicijativno menjati.",
                text_en: ""
            },
            {
                title_sr: "Da li vaše preparate mogu da koriste dijabetičari?",
                title_en: "",
                text_sr: "DA, naše preparate mogu da koriste dijabetičari, jer ne sadrže šećer niti bilo koje druge supstance koje mogu pogoršati stanje kod dijabetičara. Jedino proizvod DISAN sadrži med i iz tog razloga ga ne preporučujemo dijabetičarima.",
                text_en: ""
            },
            {
                title_sr: "Kada će se videti prvi rezultati?",
                title_en: "",
                text_sr: "Prvi rezultati su vidljivi već posle prve nedelje korišćenja.",
                text_en: ""
            },
            {
                title_sr: "Da li mogu da koristim terapiju preventivno?",
                title_en: "",
                text_sr: "Sve naše terapije se mogu koristiti i preventivno. Savetujemo korišćenje mesečne terapije jednom godišnje.",
                text_en: ""
            },
            {
                title_sr: "U kojim slučajevima možemo koristiti Femisan A?",
                title_en: "",
                text_sr: "Preparat možemo primenjivati kod:\n\nporemećaja menstrualnog ciklusa\ncista na jajnicima\nmioma na materici\nbolnih menstruacija, već od prve menstruacije\nendometrioze\nPCOS – sindroma policističnih jajnika\nfibrocističnih promena u dojkama\nženske neplodnosti – steriliteta\nranica na grliću materice\nPMS-a",
                text_en: ""
            },
            {
                title_sr: "U kom obliku postoji Femisan A?",
                title_en: "",
                text_sr: "Femisan A postoji u obliku kapi i kapsula.",
                text_en: ""
            },
            {
                title_sr: "Koje lekovito bilje ulazi u sastav Femisana A?",
                title_en: "",
                text_sr: "Virak, hajdučka trava, neven, crveni zdravac – u kapima i kapsulama; list maline, plod peršuna – ukapima, rusomača i zlatna maka – u kapsulama.",
                text_en: ""
            },
            {
                title_sr: "Da li postoji razlika u sastavu kapi i kapsula?",
                title_en: "",
                text_sr: "Da, kapsule dodatno sadrže ekstrakt zlatne make i cink. Zlatna maka, pored niza korisnih funkcija, dodatno balansira hormone i podstiče plodnost dok je cink mineral koji je neophodan za pravilnu reprodukciju.",
                text_en: ""
            },
            {
                title_sr: "Koja je terapijska doza Femisana A?",
                title_en: "",
                text_sr: "Terapijska doza kapi je po 60 kapi 2 puta na dan, najbolje pre obroka, u pola čaše vode. Kapi se koriste u dvomesečnim ili tromesečnim turama sa dvonedeljnim pauzama.\n\nTerapijska doza kapsula je po 2 kapsule 2 puta na dan, najbolje pre obroka. Kapsule se mogu direktno progutati ili se sadržaj može rastvoriti u čaši vode, toplog čaja ili soka. Mogu se koristiti tokom dužeg vremenskog perioda bez pauze, dok se ne postignu zadovoljavajući rezultati. Nakon 3 meseca upotrebe dovoljno je pauzirati 2 nedelje, a ako se kapsule koriste 6 i više meseci u kontinuitetu, treba pauzirati mesec dana.",
                text_en: ""
            },
            {
                title_sr: "Da li se na početku upotrebe mogu osetiti neke promene?",
                title_en: "",
                text_sr: "Pošto Femisan A podstiče mikrocirkulaciju, na početku se može osetiti blago probadanje u donjem delu stomaka. To je dobar znak da preparat deluje. Važno je nastaviti sa redovnom upotrebom preparata.",
                text_en: ""
            },
            {
                title_sr: "Da li preparat sadrži alkohol?",
                title_en: "",
                text_sr: "Femisan A kapi sadrže minimalnu dozu medicinskog alkohola biljnog porekla koja ne može izazvati nikakve negativne efekte. Femisan A kapsule ne sadrže alkohol.",
                text_en: ""
            },
            {
                title_sr: "Koliko dugo traje jedno pakovanje Femisana A?",
                title_en: "",
                text_sr: "Ako se koristi terapijska doza, jedna bočica Femisan A kapi traje 5-7 dana, dok 1 kutija kapsula traje 15 dana.",
                text_en: ""
            },
            {
                title_sr: "Kako Femisan A utiče na plodnost?",
                title_en: "",
                text_sr: "Femisan A deluje povoljno na funkcije jajnika, uspostavlja hormonalnu ravnotežu, stimuliše ovulaciju i jača zid materice. Preporučuju ga lekari i kao pripremu za začeće i tokom procesa vantelesne oplodnje.",
                text_en: ""
            },
            {
                title_sr: "Koliko brzo mogu da zatrudnim?",
                title_en: "",
                text_sr: "Rezultat je individualan i veoma zavisi od problema koji predstavlja prepreku za začeće. Ono što je bitno kod prirodnih preparata je redovna upotreba tokom dužeg vremenskog perioda. Parovima koji pokušavaju da ostvare trudnoću savetujemo i Alfa Aktiv za muškarce koji balansira nivo testosterona, povećava broj i pokretljivost spermatozoida.",
                text_en: ""
            },
            {
                title_sr: "Da li se Femisan A koristi i tokom menstruacije? Kada ga ne treba koristiti?",
                title_en: "",
                text_sr: "Da, preparat se koristi tokom celog ciklusa, a ne treba ga koristiti u trudnoći. Femisan A neće naneti štetu plodu, ali se ne koristi tokom trudnoće iz jednostavnog razloga što nema za njim potrebe. U trudnoći deluju drugačiji mehanizmi i nema potrebe dodavati ništa, osim ako samo i isključivo lekar koji vodi trudnoću nešto ne prepiše.",
                text_en: ""
            },
            {
                title_sr: "Kako Femisan A deluje u slučaju endometrioze?",
                title_en: "",
                text_sr: "Kad je u pitanju endometrioza, Femisan A deluje povoljno pre svega svojim protivupalnim dejstvom, kao i smanjenjem bolova koji prate ovaj poremećaj. Takođe postoje dokazi da flavoni mogu da smanje enzim koji pretvara androgene u estrogene, što dovodi do ublažavanja simptoma uzrokovanih endometriozom. Flavona između ostalog ima u peršunu koji se nalazi u Femisan A kapima.\n\n\nFemisan A sadrži u velikom procentu virak koji je adstrigens i kao takav se preporučije kod obilnih krvarenja. Takođe je koristan za ublažavanje menstrualnih bolova. Ekstrakt virka inhibira angiogenezu tj. rast novih krvnih sudova.",
                text_en: ""
            },
            {
                title_sr: "Kako Femisan A deluje na miome?",
                title_en: "",
                text_sr: "Već pomenuta angiogeneza je proces koji ubrzava razvoj nekih bolesti kao što su fibroidi (miomi) i endometrioza. Kao i kod endometrioze, ekstrakt virka ima sposobnost da spreči rast novih krvnih sudova pa tako sprečava nastanak novih mioma, stopira rast ili smanjuje već postojeće miome. Takođe, zbog prisustva ahileina iz hajdučke trave, kao i tanina iz virka i rusomače, ublažava produžena i obilna krvarenja koja miomi često izazivaju. Preporuka je da se koristi terapijska doza 2×2 kapsule u periodu od 3-6 meseci, nakon čega je potrebno uraditi kontrolni pregled i nakon pauze od 2-4 nedelje ponoviti terapiju. Ako se koriste kapi, potrebna doza je 2×60 kapi, a pauzu treba praviti na svaka 2-3 meseca u trajanju od dve nedelje.",
                text_en: ""
            },
            {
                title_sr: "Kako Femisan A deluje na ciste i sindrom policističnih jajnika (PCOS)?",
                title_en: "",
                text_sr: "Femisan A je vrlo efikasan kod cista i deluje prvenstveno tako što uspostavlja hormonalni balans, i prilično brzo izaziva pucanje ciste ili njenu resorpciju. Takođe stimuliše mikrocirkulaciju u oblasti jajnika i sprečava nastanak cista koje su posledica cirkulatornih problema. Kad je PCOS u pitanju, najbitnija svar je upornost. To je poremećaj koji se često vraća, pa je zato neophodno kapi koristiti redovno i tokom dužeg perioda, i kada deluje da je problem rešen, treba nastaviti neko vreme sa preventivnom dozom.",
                text_en: ""
            },
            {
                title_sr: "Da li Femisan A može pomoći kod zapušenih jajovoda?",
                title_en: "",
                text_sr: "Femisan A svojim antizapaljenskim dejstvom, kao i dejstvom virka na jačanje materice i materičnih ligamenata može doprineti rešavanju problema sa zapušenim jajovodima, ali samo kao pomoć. Preporuka je da se uradi HSG – snimanje jajovoda koje samo po sebi može i da poveća prohodnost jajovoda.",
                text_en: ""
            },
            {
                title_sr: "Da li Femisan A sadrži veštačke hormone?",
                title_en: "",
                text_sr: "Femisan A ne sadrži veštačke hormone ni konzervanse. To je potpuno prirodni preparat, bez ikakvih kontraindikacija.",
                text_en: ""
            },
            {
                title_sr: "Da li se Femisan A može koristiti tokom i nakon menopauze?",
                title_en: "",
                text_sr: "Najstarija pacijentkinja koja je koristila Femisan A je imala preko 90 godina. Preparat može pomoći kod cista koje se ponekad mogu javiti i nakon menopauze. U perimenopauzi savetujemo kombinaciju Femisana A i B: Femisan A doprinosi da ciklusi budu redovniji, deluje protivupalno i otklanja druge ginekološke probleme, dok Femisan B smanjuje prenadraženost nervnog sistema, otklanja valunge, nervozu i nesanicu. Femisan A i B se jednostavno kombinuju tako što se u pola čaše vode sipa po 30-40 kapi od svakog i popije dva puta na dan.",
                text_en: ""
            },
            {
                title_sr: "Da li se mogu kombinovati kapi i kapsule Femisan A?",
                title_en: "",
                text_sr: "Da, ako vam je jednostavnije da jednom u toku dana popijete kapsule a drugi put kapi, možete ih kombinovati. U tom slučaju se koriste 2 kapsule ujutru i 60 kapi uveče.",
                text_en: ""
            },
            {
                title_sr: "Da li se Femisan A može koristiti uz kontraceptivne pilule?",
                title_en: "",
                text_sr: "Femisan A se moze kombinovati sa kontracepcijom, ali tada preporučujemo malo nižu dozu, 2×30-40 kapi, odnosno 2×1 kapsulu.",
                text_en: ""
            },
            {
                title_sr: "Da li Femisan A mogu koristiti devojčice?",
                title_en: "",
                text_sr: "Da, preparat mogu koristiti devojčice već od prve menstruacije. Femisan A pomaže da se brže uspostavi redovan ciklus i hormonalni balans. Odličan je i kao prevencija od policističnih jajnika i endometrioze koji sve ranije pogađaju devojke.",
                text_en: ""
            },
            {
                title_sr: "Da li Femisan A pomaže kod akni?",
                title_en: "",
                text_sr: "Da, pošto akne često izaziva hormonalni disbalans. Redovnom upotrebom Femisana A i koža postaje čistija i reguliše se lučenje sebuma.",
                text_en: ""
            },
        ]
    },
    contact: {
        orangeBox: 'KAD ZATREBA\nTRANSPORT\n TU JE MEGGA',
        title: 'KONTAKTIRAJTE NAS',
        name: 'Ime i Prezime',
        email: 'Email',
        message: 'Poruka',
        send: 'POŠALJI',
        info: "Megga doo\nVojvode Mišića 7\nPožega 31210\n\nE-mail: office@megga.pro",
        telephone: "Tel. +381 31 715 690\n     +381 31 715 729",
        success: 'Vaša poruka je uspešno poslata!',
        close: 'Zatvori',
        failed: 'Neuspešno slanje poruke',
        captcha: 'Incorrect captcha',
        messageError: 'Message is to short',
        nameError: 'Ime ne sme biti prazno',
        emailError: 'Neispravna email adresa',
        textError: 'Tekst mora da sadži minimum 50 karaktera',
    },
    legal: {
        copyright: {
            title: 'COPYRIGHT',
            text: "Internet stranica kao i sav sadržaj na internet stranici su u vlasništvu . Sva autorska i druga prava su zadržana od strane vlasnika.\n\nPravo svojine se konkretno odnosi na:\n\n1. Internet stranicu ''www.megga.pro''\n\n2. Sav sadržaj i materijal na internet stranici megga.pro, koji uključuju: sve fotografije i video zapise na internet stranici, tekstove, programerski kod kao i sve drugo što se prema Zakonu o autorskim i srodnim pravima može smatrati autorskim delom.\n\nSva prava su zaštićena. Svaka upotreba, reprodukcija ili objavljivanje, bilo u celosti bilo u delovima, sadržaja internet stranice je strogo zabranjena. Zabranjeno je bez izričitog pisanog pristanka vlasnika koristiti u komercijalne svrhe i na bilo koji drugi način ekonomski iskorišćavati, bilo u celosti ili u delovima, internet stranicu ili sadržaj sa internet stranice.\n",
        },
        privacy: {
            title: 'POLITIKA PRIVATNOSTI',
            text_0: 'Pravila o privatnosti (Privacy Policy) opisuju zaštitu privatnosti pojedinaca koji koriste i koji se registruju na internet stranicama kompanije Herba Svet doo, kako su dalje formulisani (“Klijenti” ili “Vi”, “Vaš”). Ova Pravila o privatnosti opisuju pristup privatnim podacima od strane Herba Svet doo, a u vezi sa upotrebom njenih internet stranica i povezanih aplikacija i usluga koje Herba Svet doo nudi. Ova Pravila o privatnosti objašnjavaju i kako obrađujemo podatke koje prikupljamo kada posetite naše internet stranice. Takođe opisuje Vaše moguće izbore u pogledu korišćenja, pristupa i ispravki u vezi sa vašim privatnim informacijama. Koristeći naše internet stranice i naše servise, Vi razumete i prihvatate da Herba Svet doo skuplja, skladišti i procesuira informacije koje Vi generišete i neke informacije o Vama. Ako se ne složite sa uslovima korišćenja podataka objašnjenim u ovim Pravilima o privatnosti, ne biste trebali koristiti naše internet stranice ili naše servise. Pravila o privatnosti su izuzetno važna, zato Vas molimo da ih pažljivo pročitate.',
            subtitle_1: 'UPOTREBA INTERNET STRANICA KOMPANIJE HERBA SVET DOO',
            text_1: 'Informacije koje smo dobili kroz naše aplikacije i servise, bilo preko posetilaca naših internet stranica ili kada korisnici naših servisa unesu svoje informacije, skladištene su na serverima našeg pružaoca usluga. Ove informacije se dalje koriste za kontaktiranje posetilaca i korisnika, a koriste se i kao oblik izveštaja o njihovom interesovanju za usluge Herba Svet doo i interakciji sa njima. Mi obrađujemo informacije naših klijenata i čuvamo ih na serverima našeg pružalaca usluga, ali nemamo kontrolu nad njihovim sakupljanjem ili upravljanjem njima. Saglasni smo da naši klijenti imaju prava da pristupe svojim ličnim informacijama.\n',
            subtitle_2: 'PRIKUPLJENE INFORMACIJE',
            text_2: 'Vi ste slobodni da istražujete internet stranice kompanije Herba Svet doo, bez pružanja bilo kakvih privatnih, ličnih informacija o sebi. Kada se registrujete, mi vas zamolimo da nam dobrovoljno obezbedite Vaše lične podatke, kao što su Vaše ime, adresa, adresa Vaše elektronske pošte i slično.',
            text_3: 'Internet stranice kompanije Herba Svet doo mogu sadržati linkove ka drugim internet stranicama, našim ili koje pripadaju trećoj strani. Načini obrade podataka ili sadržaj drugih internet stranica regulišu se pomoću Pravila o privatnosti tih drugih internet stranica.',
            text_4: 'Ako se odlučite da povučete Vašu saglasnost za rukovanje Vašim ličnim podacima, možete to učiniti u svakome trenutku. Povlačenje Vaše saglasnosti neće uticati na zakonitost bilo koje obrade Vaših ličnih podataka koju smo obavili pre Vašeg povlačenja saglasnosti, niti će uticati na obradu Vaših ličnih informacija koje se sprovode, a koje se oslanjaju na zakonite osnove za obradu, pre nego na Vašu saglasnost.',
            text_5: 'Mi takođe možemo sakupiti podatke kroz korišćenje alata za prikupljanje podataka, kao što su internet-kolačići. Takvi podaci uključuju standardne podatke sa vašeg internet pretraživača (kao što je tip pretraživača i jezik pretraživača), vaša internet protokol (IP) adresa, kao i radnje koje obavljate na internet stranicama kompanije Herba Svet doo (kao što su delovi internet stranica koje ste pregledali i slični podaci). Podatke koristimo za održavanje kvaliteta naših usluga, kao i da bismo kreirali statističke izveštaje u vezi sa korišćenjem internet stranica naše kompanije. Više o tome pogledajte u Obaveštenju o internet-kolačićima („cookies“) na sajtovima HerbaSvet doo („Cookie Policy“).',
            subtitle_6: 'OBAVEŠTENJE O AUTORSKIM PRAVIMA',
            text_6: 'Internet stranice kompanije Herba Svet doo, kao i sav sadržaj koji se u okviru njih nalazi, u isključivom su vlasništvu kompanije Herba Svet doo ©2020 Herba Svet doo. Sva prava su zadržana.',
        },
        cookies: {
            title: 'OBAVEŠTENJE O KOLAČIĆIMA',
            text_1: "",
            subtitle_2: "SVE INTERNET STRANICE KOMPANIJE HERBA SVET DOO KORISTE INTERNET-KOLAČIĆE (COOKIES)",
            text_2: "Poštovani korisniče, ovim vas obaveštavamo da Herba Svet doo iz Beograda koristi \ninternet-kolačiće na svojim internet sajtovima van društvenih mreža. Molimo vas da pročitate ovo\nobaveštenje („Cookie Policy“) do kraja, pre pristupanja internet-stranicama kompanije HerbaSvet doo.",
            subtitle_3: "ŠTA SU INTERNET-KOLAČIĆI?",
            text_3: "Internet-kolačići su male računarske datoteke, koje se moraju uskladištiti na čvrstom disku\nvašeg računara. To skladištenje pokreće internet-server, pri poseti bilo kojoj internet-stranici.\nInternet-kolačići će identifikovati Vaš računar kada ponovo posetite stranicu koja je pokrenula\nnjihovo skladištenje na Vašem računaru. Svaki internet-kolačić je jedinstven – poreklom je sa\nodređene internet-stranice, inicira se samo posetom istoj i sadrži mali broj informacija, kao što\nsu ime internet-stranice, njen domen i tako dalje.",
            subtitle_4: "KOJE INTERNET-KOLAČIĆE KORISTE INTERNET-SAJTOVI KOMPANIJE HERBA SVET DOO?",
            text_4: "Analitičke internet–kolačiće\n\nOve datoteke omogućavaju nama kao kompaniji, ali trećim licima i njihovim servisima da\nprikupljaju osnovne, skraćene podatke za statističke analize o tome kako se posetioci naših\ninternet stranica kreću po njima, koji su im podaci zanimljivi a koji ne. Ovi internet-kolačići ne\nsadrže lične podatke o korisnicima, kao što su njihova imena, prezimena ili email adrese, i\nkoriste se kako bi poboljšali korišćenje naše internet stranice. Ova internet stranica od internet-\nkolačića koristi Google Tag Manager (Google Analytics) i Facebook Pixel.",
        },
        gdprWindow: {
            accept: 'Prihvatam',
            info: 'Više informacija'
        },
    },
    trademark: {
        text: '© 2020 HERBASVET DOO. All rights reserved.\n ',
        sponsors: 'www.femisan.info www.devi.rs www.alfaaktiv.rs ',
    },
    gdpr: {
        cookies: "OBAVEŠTENJE O KOLAČIĆIMA",
        privacy: "POLITIKA PRIVATNOSTI",
    },
}

export default srb;