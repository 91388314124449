import React from 'react';
import { Container, Element } from './styled';
import Config from '../../config';
import BlogCard from '../BlogCard';

class BlogContent extends React.Component {
    render() {
        return (
            <Container>
                {this.props.data ?
                    this.props.data.map((element, index) => {
                        return (
                            <Element>
                                <BlogCard
                                    productImage={element.url}
                                    productName={element.title}
                                    category={element.category}
                                    productDescription={element.description}
                                    index={element.id}
                                />
                            </Element>
                        );
                    })
                    :
                    <></>
                }
            </Container>
        )
    }
}

export default BlogContent;