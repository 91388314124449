import React from 'react';
import { Jumbotron as Jumbo } from '../../styles';

class Jumbotron extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: 0,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;

        this.setState({ windowWidth });
    }

    render() {
        if (this.state.windowWidth > 800)
            return <Jumbo src={this.props.background} />
        else
            return <Jumbo src={this.props.backgroundMobile} />
    }

}

export default Jumbotron;