import styled from 'styled-components';
import React from 'react';
import { GrayParagraphLoreSmallThatIsActuallyNotWhite } from '../_components';

const Container = styled.div`
width: 100%;
/* max-width: 300px; */
height: auto;
display: flex;
align-self: flex-start;
flex-direction: column;
margin-bottom: 75px;
`;
const Title = styled.h2`
margin-top: 5px;
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 22px;

color: #005B30;

`;

class ContactCard extends React.PureComponent {
    render() {
        return (
            <Container>
                <Title>{this.props.title}</Title>
                <GrayParagraphLoreSmallThatIsActuallyNotWhite>{this.props.text}</GrayParagraphLoreSmallThatIsActuallyNotWhite>
            </Container>
        )
    }
}

export default ContactCard;