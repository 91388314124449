import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
overflow: hidden;
border: 1px solid rgba(30, 145, 156, 0.95);
transition: height 0.8s;
color: 1px solid rgba( 168, 59, 227, 0.95 );
:hover{
height: ${ props => { if (props.clicked === true) { return '200px'; } else { return '30px' } }};
}
margin-bottom: ${ props => { if (props.embeded === true) { return '20px'; } else { return '20px'; } }};
height: ${ props => { if (props.embeded === true) { return '30px'; } else { return '30px'; } }};
display: ${ props => { if (props.embeded === true) { if (props.active === true) { return 'block'; } else { return 'none'; } } else { return 'block'; } }};
`;

export const Inline = styled.div`
display: inline; 
`;
export const AddElementButton = styled.p`
font-family: "Montserrat";
font-style: normal;

display: inline-block;

font-size: 16px;
color: rgba( 168, 59, 227, 0.95 );
border: 1px solid rgba( 168, 59, 227, 0.95 );

padding-top: 10px;
padding-bottom: 10px;
margin-left: 10px;
padding-right: 10px;

width: 150px;
text-align: center;
:hover{
    cursor: pointer;
}
`;
export const Label = styled.p`
margin-top: 2px;
font-family: "Montserrat";
font-style: normal;
font-weight: bold;
color: red;
display: block;
margin-left: 10px;

font-size: 17px;
color: rgba( 168, 59, 227, 0.95 );

`;