import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import MainSection from '../../components/MainSection';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import { PatrickHand, GreenTextResponsive } from '../../components/_components';
import GreenSection from '../../components/GreenSection';
import FilteredProductContent from '../../components/FilteredProductContent';
import FixedSpacing from '../../components/FixedSpacing';
import styled from 'styled-components';
import ScrollToTop from '../../components/ScrollToTop';

const Title = styled.h1`
align-self: center; 
display: flex;
flex-direction: row;
color: #005B30;
/* delete me plz  */
/* yes, this thing up here, thanks   */
color: ${ props => props.custom === true ? '#09311C' : '#005B30'};
font-size: 32px;
white-space: pre-line;
word-break: break-word;
font-family: ${ props => { if (props.font === 'Sans') { return 'Open sans' } else { return 'Open sans' } }};
font-weight: ${ props => { if (props.font === 'Sans') { if (props.custom === true) { return 'normal' } else { return 'bold' } } else { return 'normal' } }};
${ props => { if (props.weight === 'bold') { return 'font-weight: bold;' } }};
display: block;
width: auto;
@media only screen and (max-width: 850px) {
 font-size: 27px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;

const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Herb extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <MainSection title={Config.localdata.herbs[this.props.match.params.id].name_sr} custom={true} font={'Sans'} weight={'bold'}>

                    <PatrickHand>{'(' + Config.localdata.herbs[this.props.match.params.id].name_latin + ')'}</PatrickHand>
                </MainSection>
                <GreenSection
                    text={Config.localdata.herbs[this.props.match.params.id].description_sr}
                    roundImage={Config.localdata.herbs[this.props.match.params.id].herbIcon}
                    button={true}
                    id={this.props.match.params.id}
                />
                <MainSection>
                    <GreenTextResponsive>{'Biljku sadrže ovi proizvodi:'}</GreenTextResponsive>
                </MainSection>
                <FilteredProductContent contains={Config.localdata.herbs[this.props.match.params.id].products} lang={this.props.text.lang} />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Herb);