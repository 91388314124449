import React from 'react';
import { PageContainer } from '../../styles';
import MainSection from '../../components/MainSection';
import { TextElement, Container, Section, Inline, Text, Paragraph } from './styled';
import { connect } from 'react-redux';
import FixedSpacing from '../../components/FixedSpacing';
import PrivacyPolicy from '../../components/PrivacyPolicy';

const mapStateToProps = (state) => {
    return {
        legal: state.language.legal
    }
}


class Legal extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    state = {
        type: '',
        loading: true,
        rightButton: {
            linkTo: '',
            text: '',
        },
        leftButton: {
            linkTo: '',
            text: '',
        }
    }


    componentDidMount() {
        let type = '/';
        let leftButton = { text: this.props.legal.privacy.title, linkTo: '/privacy' };
        let rightButton = { text: this.props.legal.cookies.title, linkTo: '/cookies' };
        let url = window.location.toString();
        if (url.includes('cookies')) {
            type = 'cookies';
            // leftButton = { text: this.props.legal.copyright.title, linkTo: '/copyright' }
            // rightButton = { text: this.props.legal.privacy.title, linkTo: '/privacy' }
        }
        if (url.includes('privacy')) {
            type = 'privacy';
            // leftButton = { text: this.props.legal.cookies.title, linkTo: '/cookies' }
            // rightButton = { text: this.props.legal.copyright.title, linkTo: '/copyright' }
        }
        this.setState({ ...this.state, loading: false, type, leftButton, rightButton });
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }

    handleClick(event) {
        window.location.replace('/#' + event);
        window.location.reload();
    }

    returnContent(type) {
        switch (this.state.type) {
            case 'cookies':
                return (
                    <>
                        <FixedSpacing />
                        <MainSection title={this.props.legal.cookies.title}>
                            <div style={{ marginBottom: '50px' }}></div>

                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                {this.props.legal.cookies.subtitle_2}
                            </Paragraph>
                            <Paragraph keepLine={true} >
                                {this.props.legal.cookies.text_2}
                            </Paragraph>
                            <div style={{ marginBottom: '50px' }}></div>
                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                {this.props.legal.cookies.subtitle_3}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.cookies.text_3}
                            </Paragraph>
                            <div style={{ marginBottom: '50px' }}></div>
                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                {this.props.legal.cookies.subtitle_4}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.cookies.text_4}
                            </Paragraph>
                        </MainSection>
                        {/* <TextElement>
                            <Container>
                                <Section>
                                    <Inline>
                                        <Text onClick={() => this.handleClick(this.state.leftButton.linkTo)}>{this.state.leftButton.text}</Text>
                                        <Text onClick={() => this.handleClick(this.state.rightButton.linkTo)} align={'right'}>{this.state.rightButton.text}</Text>

                                    </Inline>
                                </Section>
                            </Container>
                        </TextElement> */}
                        <PrivacyPolicy />
                    </>
                );
            case 'privacy':
                return (
                    <>
                        <FixedSpacing />
                        <MainSection title={this.props.legal.privacy.title}>
                            <div style={{ marginBottom: '50px' }}></div>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_0}
                            </Paragraph>
                            <div style={{ marginBottom: '50px' }}></div>
                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                {this.props.legal.privacy.subtitle_1}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_1}
                            </Paragraph>
                            <div style={{ marginBottom: '50px' }}></div>

                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                {this.props.legal.privacy.subtitle_2}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_2}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_3}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_4}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_5}
                            </Paragraph>
                            <Paragraph keepLine={true} style={{ fontWeight: 'bold', fontSize: '21px', color: '#005B30' }}>
                                <div style={{ marginBottom: '50px' }}></div>

                                {this.props.legal.privacy.subtitle_6}
                            </Paragraph>
                            <Paragraph keepLine={true}>
                                {this.props.legal.privacy.text_6}
                            </Paragraph>
                        </MainSection>
                        {/* <TextElement>
                            <Container>
                                <Section>
                                    <Inline>
                                        <Text onClick={() => this.handleClick(this.state.leftButton.linkTo)}>{this.state.leftButton.text}</Text>
                                        <Text onClick={() => this.handleClick(this.state.rightButton.linkTo)} align={'right'}>{this.state.rightButton.text}</Text>
                                    </Inline>
                                </Section>
                            </Container>
                        </TextElement> */}
                        <PrivacyPolicy />

                    </>
                );
            default:
                return (<div>?</div>);
        }
    }

    render() {
        return (
            <PageContainer>
                <div ref={this.topRef}></div>
                {
                    this.props.legal && !this.loading ?
                        <>
                            {
                                this.returnContent()
                            }
                        </>
                        :
                        <></>
                }
            </PageContainer >
        )
    }
}

export default connect(mapStateToProps, null)(Legal);