import styled from 'styled-components';


export const Container = styled.div`
z-index: 2;
float: center;
/* width: 1150px; */
/* width: ${ props => props.stretch === true ? '100%' : '1150px'}; */
text-align: center;
margin-top: ${ props => { if (props.noMargin === true) { return '5px;'; } else { return '90px' } }};
align-self: center;
margin-bottom: ${ props => { if (props.noBottom === true) { return '0px' } else { return '30px' } }};
display: flex;
flex-direction: column;
@media only screen and (max-width: 1300px) {
    width: 900px;
}
@media only screen and (max-width: 937px) {
    width: 700px;
    margin-top: ${ props => { if (props.noMargin === true) { return '5px;'; } else { return '65px' } }};
    ${ props => { if (props.noBottom === true) { return 'margin-bottom: -60px;' } }};
    
}
@media only screen and (max-width: 706px) {
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
}
@media only screen and (max-width: 400px) {
    width: 275px;
}
`;
export const Wrapper = styled.div`
display: flex;
flex-direction: row;
width: 55px;
max-height: 3px;
margin-top: 8px;
border-bottom: 4px solid rgb(247,148,29);
/* experimental  */
color: black;

@media only screen and (max-width: 850px) {

}
`;
export const Title = styled.h1`
align-self: center; 
display: flex;
flex-direction: row;
color: #005B30;
/* delete me plz  */
/* yes, this thing up here, thanks   */
color: ${ props => props.custom === true ? '#09311C' : '#005B30'};
font-size: 32px;
white-space: pre-line;
word-break: break-word;
font-family: ${ props => { if (props.font === 'Sans') { return 'Open sans' } else { return 'Open sans' } }};
font-weight: ${ props => { if (props.font === 'Sans') { if (props.custom === true) { return 'normal' } else { return 'bold' } } else { return 'normal' } }};
${ props => { if (props.weight === 'bold') { return 'font-weight: bold;' } }};
display: block;
width: auto;
@media only screen and (max-width: 850px) {
 font-size: 27px;   
}
@media only screen and (max-width: 510px) {
  font-size: 21px;
}
@media only screen and (max-width: 400px) {
    font-size: 18px;
}
`;

export const Border = styled.div`
width: 100px;
border-bottom: 5px solid rgb(246 , 149 ,32);
`;
export const Content = styled.div`
width: 100%;
text-align: center;
`;

export const Trigger = styled.div`
z-index: 2;
width: 100%;
display: flex;
flex-direction: column;
background-color: rgba( 255 , 255, 255 , 1);
`;
