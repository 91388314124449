import React from 'react';
import {
    Container,
    Arrow,
    ContentFrame,
    Item,
    ArrowImage,
    Content,
} from './styled';
import Config from '../../config';

class Trophies extends React.Component {

    state = {
        inMove: true,
        eventActive: false,
        currentPosition: -80,
        direction: 'right',
        intervalSpeed: 50,
        incrementSpeed: 1,
    }

    startDirection(direction) {
        this.setState({ ...this.state, eventActive: true, direction });
    }
    clearDirection(direction) {
        this.setState({ ...this.state, eventActive: false, direction });
    }
    componentDidMount() {
        window.setInterval(() => {
            let newPosition = this.state.currentPosition;

            if (this.state.eventActive === true) {

                newPosition += this.state.direction !== 'right' ? this.state.incrementSpeed : (this.state.incrementSpeed * (-1));
            }
            if (newPosition === -379) {
                newPosition = -2;
            }
            if (newPosition === -1) {
                newPosition = -379;
            }
            this.setState({ ...this.state, currentPosition: newPosition });

        }, this.state.intervalSpeed);

    }

    render() {
        return (
            <Container>
                <Arrow>
                    <ArrowImage
                        src={Config.images.icon.ArrowLeftGreen}
                        onMouseDown={() => this.startDirection('left')}
                        onMouseUp={() => this.clearDirection('left')}
                    />
                </Arrow>
                <ContentFrame>
                    <Content pos={this.state.currentPosition}>
                        {
                            Config.images.certificate.trophy.map(((element, index) => {
                                let trophy = Config.images.certificate.trophy;
                                let lgth = Config.images.certificate.trophy.length;
                                if (index === 0) {
                                    return (
                                        <>
                                            <Item src={trophy[lgth - 4]} />
                                            <Item src={trophy[lgth - 3]} />
                                            <Item src={trophy[lgth - 2]} />
                                            <Item src={trophy[lgth - 1]} />

                                            <Item src={element} />
                                        </>
                                    )
                                }
                                if (index === lgth - 1) {
                                    return (
                                        <Item src={trophy[0]} />
                                    )
                                }
                                return (
                                    <Item src={element} />
                                );
                            }))
                        }
                    </Content>
                </ContentFrame>
                <Arrow>
                    <ArrowImage src={Config.images.icon.ArrowRightGreen}
                        onMouseDown={() => this.startDirection('right')}
                        onMouseUp={() => this.clearDirection('right')}
                    />

                </Arrow>
            </Container>
        );
    }
}

export default Trophies;