import React from 'react';
import styled from 'styled-components';
import ButtonFlex from '../ButtonFlex';

const Container = styled.div`
width: 100%;
height: 150px;
background-color: #005B30;
display: ${ props => props.hide === true ? 'none' : 'flex'};

border-top: 8px solid #64A70A;
position: fixed;
bottom: 0px;
flex-direction: row;
justify-content: flex-start;
flex-wrap: nowrap;
z-index: 777;
/* position: absolute; */
@media only screen and (max-width: 800px) {
    height: 300px;
    flex-direction: column;
}
`;
const Element = styled.div`
display: flex;
flex-direction: column;
width: 70%;
height: 100%;
justify-content: center;
@media only screen and (max-width: 1125px) {
    width: 60%;
}
@media only screen and (max-width: 850px) {
    width: 50%;
}
@media only screen and (max-width: 800px) {
    width: 80%;
    margin-left:10%;
}
`;

const Element2 = styled.div`
display: flex;
flex-direction: column;
width: 30%;
height: 100%;
justify-content: center;
vertical-align: center;
@media only screen and (max-width: 1125px) {
    width: 40%;
}
@media only screen and (max-width: 850px) {
    width: 50%;
}
@media only screen and (max-width: 800px) {
    width: 80%;
    margin-left:10%;
}
@media only screen and (max-width: 420px) {
    width: 100%;
    margin-left: 0%;
}
`;
const Inline = styled.div`
display: flex;
/* max-height: 50px; */
flex-direction: row;
/* justify-content: flex-start; */
align-self: flex-start;
padding-left: 15%;
/* padding-top: 30%; */
@media only screen and (max-width: 800px) {
    align-self: center;
}
@media only screen and (max-width: 420px) {
    align-self: 'flex-start';
}

`;
const Title = styled.h1`
align-self: center;
display: flex;
font-family: "Open Sans";
font-weight: bold;
font-size: 25px;
color: #FFFFFF;
padding-left: 15%;
/* padding-right: 15%; */
@media only screen and (max-width: 1400px) {
    font-size: 20px;
}
@media only screen and (max-width: 1125px) {
    font-size: 19px;
}
`;

class CookieNotification extends React.Component {
    saveState() {
        try {
            const serializedState = true;
            localStorage.setItem('Privacy', serializedState);
        } catch (err) {

        }
    }

    loadState() {
        try {
            const serializedState = localStorage.getItem('Privacy');
            if (serializedState === null) {
                return false;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return false;
        }
    }
    state = {
        agree: false
    }
    componentDidMount() {
        let agreed = this.loadState();
        // alert(agreed);
        this.setState({ agree: agreed });
    }
    agree() {
        this.saveState();
        this.setState({ agree: true });
    }
    readMore() {
        window.location.replace('/#/cookies');
        this.agree();
    }
    render() {
        return (
            <Container hide={this.state.agree}>
                <Element>
                    <Title>{'Ova web stranica koristi kolačiće. Ukoliko nastavite sa korišćenjem ovog sajta, saglasni ste sa našom upotrebom kolačića i politikom privatnosti'}</Title>
                </Element>
                <Element2>
                    <Inline>
                        <ButtonFlex margin={'0px'} text={'Saznajte više'} onClick={() => this.readMore()} />
                        <div style={{ width: '50px;', marginLeft: '50px' }}></div>
                        <ButtonFlex margin={'0px'} text={'Prihvatam'} onClick={() => this.agree()} />
                    </Inline>
                </Element2>
            </Container>
        )
    }
}

export default CookieNotification;