import React from 'react';
import styled from 'styled-components';

const Container = styled.button`
margin-top: 20px;
margin-bottom: ${ props => props.margin ? props.margin : '80px'};
width: auto;
background-color: ${ props => props.whiteMode === true ? 'white' : '#64A70A'};
border: none;
padding-top: 6px;
display: flex;
padding-bottom: 6px;
padding-left: 16px;
padding-right: 16px;
font-size: 16px;
font-weight: normal;
border: ${ props => props.whiteMode === true ? '1px solid #005B30' : ''};
font-family: 'Open Sans';
color: ${ props => props.whiteMode === true ? '#005B30;' : '#FFFFFF'};
text-align: center;
transition: background-color 0.6s;
:hover{
    cursor: pointer;
    background-color: ${ props => props.whiteMode === true ? '#005B30;' : '#005B30'};
    color: #FFFFFF;
    ${ props => { if (props.special1 === true) { return 'background-color: #FFFFFF; color: #005B30;' } }};
}
`;

function ButtonFlex(props) {
    return (<Container onClick={() => props.onClick ? props.onClick() : console.log()} margin={props.margin} type={props.type ? props.type : 'button'} whiteMode={props.whiteMode ? props.whiteMode : false} special1={props.special1 ? props.special1 : false}>{props.text}</Container>)
}

export default ButtonFlex;