import React from 'react';
import MainSection from '../MainSectionClassic';
import { InputField, Container } from './styled';
import { connect } from 'react-redux';
import Button from '../Button';
import { Paragraph, RedText } from '../../styles';
import { GreenOpenSans } from '../_components';
import EmailValidator from 'email-validator';
import Config from '../../config';
import Actions from '../../store/Actions';
import axios from 'axios';


const mapStateToProps = (state) => {
    return {
        text: state.language.contact
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        login: async (username, password) => dispatch(await Actions.Auth.Login(username, password)),
    }
}

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.emailRef = React.createRef();
        this.formRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    state = {
        email: '',
        fullName: '',
    }


    onChange(event) {
        let text = event.target.value
        let id = event.target.id;

        if (id === 'Email') {
            this.setState({ email: text });
        }
        if (id === 'Name') {
            this.setState({ fullName: text });

        }
    }




    submit() {
        this.props.login(this.state.email, this.state.fullName);
    }

    render() {
        return (
            <>
                {
                    this.props.text ?
                        <>

                            <MainSection title={''} noBottom={true} stretch={this.props.stretch}>
                                <Container>
                                    <GreenOpenSans>Login</GreenOpenSans>
                                    <form>
                                        <InputField type='text' name='email' oninvalid='invalid 2' placeholder={this.props.text.email} required onChange={this.onChange} id="Email" value={this.state.email} />
                                        <InputField type='password' name='name' oninvalid='invalid' placeholder={'Lozinka'} required onChange={this.onChange} id="Name" value={this.state.fullName} />

                                        <Paragraph style={{ marginTop: '5px' }}>{this.state.status ? this.state.status : ''}</Paragraph>
                                        <Paragraph><RedText>{!1 === 2 ? this.props.text.nameError : ''} </RedText></Paragraph>

                                        <Button text={'Potvrdi'} onClick={() => this.submit()} type='button' />

                                    </form>
                                </Container>
                            </MainSection>
                        </>
                        :
                        <></>
                }
            </>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);