import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Jumbotron from '../../components/Jumbotron'
import BlogContent from '../../components/BlogContent';
import axios from 'axios';
import AddButton from '../../components/AddPost';
import FixedSpacing from '../../components/FixedSpacing';
import PageNavigationArrow from '../../components/PageNavigationArrow';
import ScrollToTop from '../../components/ScrollToTop';


const mapStateToProps = (state) => {
    return {
        text: state.language,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Blog extends React.Component {

    state = {
        posts: '',
        adminMode: false,
        navigation: {
            nextPage: false,
            prevPage: false,
            maxPages: 20
        },
    }
    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    getPosts(ids) {
        let pageIndex = this.props.match.params.id;
        let navigation = {
            nextPage: false,
            prevPage: false,
            maxPages: this.state.navigation.maxPages,
        };
        let prevPage = false;
        let nextPage = false;
        let row = [];
        let column = [];
        let rowCounter = 0;
        let columnCounter = 0;
        let noResults = false;

        for (let i = 0; i < ids.length; i++) {
            row.push(ids[i]);
            if (rowCounter === (this.state.navigation.maxPages - 1)) {
                rowCounter = 0;
                column.push(row);
                row = [];
                columnCounter += 1;
            }
            else {
                rowCounter += 1;
            }
        }
        if (row.length !== 0) {
            columnCounter += 1;
            column.push(row); // leftovers  
        }
        if (pageIndex > columnCounter || pageIndex <= 0) {
            pageIndex = 1;
            // if page is non existend or below zero
            if (this.state.adminMode === true) {
                window.location.replace('/#/admin/blog/1');
            }
            else {
                window.location.replace('/#/blog/1');
            }
        }
        if (column[0].length === 0) {
            noResults = true; // if no ids have been returned
        }
        // display nav
        if (pageIndex > 1) {
            navigation.prevPage = true;
        }
        if (pageIndex < columnCounter) {
            navigation.nextPage = true;
        }
        let querry = column[pageIndex - 1];
        ids = querry;
        console.log(ids);
        if (noResults === false) {
            axios.post(Config.Api.blog_loadPages, {
                ids
            })
                .then((response) => {
                    this.setState({ ...this.state, posts: response.data.posts, pageIndex, noResults, navigation });
                    console.log(response);
                })
                .catch(err => {
                    this.setState({ ...this.state, noResults: true });
                });
        }
        else {
            // no results message 
        }
    }
    componentDidMount() {
        let url = window.location.href;
        axios.get(Config.Api.blog_loadPagesId)
            .then((response) => {
                this.getPosts(response.data.ids);
            })
            .catch(err => {
                console.log(err);
            });
        if (url.includes('admin')) {
            this.setState({ ...this.state, adminMode: true });
        }
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    async getPostIds() {
        return await Actions.Blog.loadPagesId();
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <Jumbotron
                    background={Config.images.blog.background}
                    backgroundMobile={Config.images.blog.background}
                />
                <div ref={this.topRef}></div>
                {this.state.adminMode ?
                    <AddButton />
                    :
                    <></>
                }
                {this.state.posts !== '' ?
                    <BlogContent data={this.state.posts} />
                    :
                    <>
                    </>
                }
                <PageNavigationArrow
                    prevPage={this.state.navigation.prevPage}
                    nextPage={this.state.navigation.nextPage}
                    pageIndex={this.state.pageIndex}
                    adminMode={this.state.adminMode}
                />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);