import React from 'react';
import styled from 'styled-components';
import Config from '../../config';

const Container = styled.div`
position: fixed;
width: 50px;
height: 50px;
/* background-color: green; */
top: 40%;
right: 25px;
border-radius: 100%;
transition: background-color 0.2s;
:hover{
    background-color: #64A70A;
}
`;
const Image = styled.img`
width: 100%;
height: 100%;
:hover{
    cursor: pointer;
}
`;
class AddPost extends React.Component {
    changePage() {
        window.location.replace('/#/admin/blog/article/new_article');
    }
    render() {
        return (
            <Container>
                <Image src={Config.images.icon.Plus} onClick={() => this.changePage()} />
            </Container>
        );
    }
}

export default AddPost;