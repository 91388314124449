import styled from 'styled-components';

export const Container = styled.div`
width: 480px;
position: relative;
height: 650px;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
color: #666666; 
background-color: #F9F9F9;
box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
margin-bottom: 80px;
@media only screen and (max-width: 1400px) {
    width: 350px;
    height: 570px;
}
@media only screen and (max-width: 600px) {
    width: 300px;
    height: 460px;
}
margin-top: 0px;
transition: margin-top 0.4s , box-shadow 0.5s , color 0.5s;
:hover{
margin-top: -10px;
/* box-shadow: 0px 4px 15px rgba(0, 91, 48, 0.8); */
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
/* color: #005B30;  */
}
`;

export const FlexContainer = styled.div`
display: flex; 
flex-direction: column;
flex-wrap: nowrap; 
width: 100%;
height:100%;
`;

export const ProductImage = styled.img`
margin-top: 20px;
display: flex;
align-self: center;
height: 100%;
/* margin-bottom: 40px; */
`;
export const ImageView = styled.div`
height: 350px;
width: 100%;
display: flex;
flex-direction: column;
@media only screen and (max-width: 1400px) {
    margin-top: 0px;
    height: 280px;
}
@media only screen and (max-width: 600px) {
    height: 200px;
}
`;
export const Title = styled.h1`
display: flex;
margin-top: 30px;
align-self: center;
font-family: "Lora"; 
line-height: 31px;
font-style: normal;
font-weight: normal;
font-size: 28px;
text-align: center;

/* color: #005B30; */
margin-bottom: 0px;
`;
export const SubTitle = styled.h2`
font-weight: bold;
display: flex;
align-self: flex-start;
font-family: "Open Sans"; 
font-style: normal;
font-size: 15px;
text-align: left;
/* color: #005B30; */
margin-top: 0px;
`;
export const BottomusAlignus = styled.div`
position: absolute;
width: 100%;
align-self: flex-end;
display: flex;
bottom: -60px;
margin-bottom: 40px;
/* height: 25px; */
@media only screen and (max-width: 1400px) {
    margin-bottom: 10px;
}
/* height: 60px; */
`;
export const Paragraph = styled.p`
font-weight: normal;
display: flex;
align-self: center;
font-family: "Open Sans"; 
font-style: normal;
font-size: 13px;
/* color: #005B30; */
margin-top: 25px;
`;
export const TextSection = styled.div`
padding-left: 90px;
padding-right: 90px;
@media only screen and (max-width: 1400px) {
    padding-left: 50px;
padding-right: 50px;
}
/* background-color: red; */

/* background-color: blue; */
`;
