import styled from 'styled-components';

export const Container = styled.div`
width: 500px;
height: 650px;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
background-color: #F1F1F1;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
margin-bottom: 40px;
border: none;
transition: border 0.2s;
padding-bottom: 10px;
overflow: hidden;
:hover{
    border: ${ props => props.admin === true ? '2px solid #eb7734' : 'none'};
    /* cursor: ${ props => props.admin === true ? 'pointer' : 'normal'}; */
    cursor: ${ props => props.admin === true ? 'pointer' : 'pointer'};

}
@media only screen and (max-width: 1400px) {
    width: 350px;
    height: 525px;
}
@media only screen and (max-width: 600px) {
    width: 300px;
    height: 460px;
}
`;

export const FlexContainer = styled.div`
/* display: flex;
flex-direction: row;
flex-wrap: nowrap; */
width: 100%;
height:95%;
margin-bottom:5%;
`;

export const ProductImage = styled.img`
display: flex;
align-self: center;
height: 100%;
width: 100%;
`;
export const ImageView = styled.div`
height: 350px;
width: 100%;
display: flex;
flex-direction: column;
@media only screen and (max-width: 1400px) {
    height: 280px;
}
@media only screen and (max-width: 600px) {
    height: 200px;
}
:hover{
    cursor: pointer;
}
`;
export const Title = styled.h1`
display: flex;
align-self: center;
font-family: "Lora"; 
line-height: 31px;
font-style: normal;
font-weight: normal;
font-size: 22px;
text-align: center;

color: #005B30;
margin-bottom: 0px;
`;
export const SubTitle = styled.h2`
font-weight: bold;
display: flex;
align-self: center;
font-family: "Open Sans"; 
font-style: normal;
font-size: 15px;
text-align: center;
color: #005B30;
margin-top: 10px;
`;
export const Paragraph = styled.p`
font-weight: normal;
display: flex;
align-self: center;
font-family: "Open Sans"; 
font-style: normal;
font-size: 13px;
color: #000000;
margin-top: 15px;
padding-bottom: 5px;
`;
export const TextSection = styled.div`
padding-left: 50px;
padding-right: 50px;
height: 100%;
/* background-color: blue; */
`;

export const Bottom = styled.div`
display: flex;
/* background-color: red; */
width: 100%;
align-self: flex-end;
/* height: 100px; */
height: auto;
/* align-self: center; */
`;
