import React from 'react';
import { Container, Element, FacebookIcon, TextElement, Text, Inline, Section, MarginTop, SubContainer, AwsomeFont, LazySpacing } from './styled';
import Config from '../../config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        text: state.language.gdpr
    }
};

class PrivacyPolicy extends React.Component {
    goToLink(toLink) {
        window.open(
            toLink + '',
            '_blank' // <- ;)
        );
    }
    render() {
        return (
            <MarginTop>
                <Element>
                    <Container>
                        <SubContainer>
                            <Inline>
                                {/* <FacebookIcon src={Config.images.icon.SocialMedia.Facebook} onClick={() => this.goToLink()} />
                                <FacebookIcon src={Config.images.icon.SocialMedia.Instagram} onClick={() => this.goToLink()} />
                                <FacebookIcon src={Config.images.icon.SocialMedia.Youtube} onClick={() => this.goToLink()} /> */}
                                <AwsomeFont><i class="fab fa-facebook-square" onClick={() => this.goToLink('https://www.facebook.com/Herbasvet')}></i></AwsomeFont>
                                <LazySpacing />
                                <AwsomeFont><i class="fab fa-youtube-square" onClick={() => this.goToLink('https://www.youtube.com/channel/UCYY0lXwF4zRdTlhwj7a8Sfg')}></i></AwsomeFont>
                                <LazySpacing />
                                <AwsomeFont><i class="fab fa-instagram-square" onClick={() => this.goToLink('https://www.instagram.com/herbasvet/')}></i></AwsomeFont>
                            </Inline>
                        </SubContainer>
                    </Container>
                </Element>
                <TextElement>
                    <Container>
                        <Section>
                            <Inline>
                                {
                                    this.props.text ?
                                        <>
                                            <Text onClick={() => { window.location.replace('/#/cookies'); window.location.reload(); }}>{this.props.text.cookies}</Text>
                                            <Text onClick={() => { window.location.replace('/#/privacy'); window.location.reload(); }} align={'right'}>{this.props.text.privacy}</Text>
                                        </>
                                        :
                                        <></>
                                }
                            </Inline>
                        </Section>
                    </Container>
                </TextElement>
            </MarginTop>
        )
    }
}

export default connect(mapStateToProps, null)(PrivacyPolicy);