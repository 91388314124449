import React from 'react';

import { PageContainer } from '../../styles';
import Actions from '../../store/Actions';
import { connect } from 'react-redux';
import Config from '../../config';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Jumbotron from '../../components/Jumbotron';
import MainSection from '../../components/MainSection';
import { GrayParagraphLoreSmallThatIsActuallyNotWhite, GreenLabel, GreenLabelHolder } from '../../components/_components';
import ContactForm from '../../components/ContactForm';
import ContactContent from '../../components/ContactContent';
import FixedSpacing from '../../components/FixedSpacing';
import ScrollToTop from '../../components/ScrollToTop';

const mapStateToProps = (state) => {
    return {
        text: state.language.QA,
        scroll: state.scroll,
        firstTimeVar: state.firstTime
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        startScroll: () => dispatch(Actions.scroll.startScroll()),
        endScroll: () => dispatch(Actions.scroll.endScroll()),
        firstTime: () => dispatch(Actions.firstTime())
    }
}
class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.topRef = React.createRef();
    }
    componentDidMount() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "instant",
                    block: "end"
                }
            )
        }
    }
    scrollStart() {
        if (this.topRef.current) {
            this.topRef.current.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                }
            )
        }
    }

    render() {
        return (
            <PageContainer>
                <FixedSpacing />
                <div ref={this.topRef}></div>
                <Jumbotron
                    background={Config.images.contact.background}
                    backgroundMobile={Config.images.contact.background}
                />
                <MainSection title={'PREDLOZI? SUGESTIJE? PITANJA?'} custom={true} font={'Sans'} weight={'bold'}>
                    <GrayParagraphLoreSmallThatIsActuallyNotWhite>Ukoliko imate predloge, sugestije ili želite da postavite neko pitanje o našim proizvodima, obratite nam se</GrayParagraphLoreSmallThatIsActuallyNotWhite>
                </MainSection>
                <ContactForm />
                <GreenLabelHolder>
                    <GreenLabel>{'NAŠE PROIZVODE POTRAŽITE U SVIM BOLJE SNABDEVENIM APOTEKAMA\nŠIROM SRBIJE, CRNE GORE, KOSOVA, MAKEDONIJE, HRVATSKE I BIH'}</GreenLabel>
                </GreenLabelHolder>
                <ContactContent />
                <ScrollToTop onClick={() => this.scrollStart()} />
                <PrivacyPolicy />
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);