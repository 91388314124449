
import katalog_proizvoda from '../assets/pdf/home/hs_rs.pdf';
import katalog_arapski from '../assets/pdf/home/hs_brochure_ar.pdf';
import katalog_nemacki from '../assets/pdf/home/hs_de.pdf';
import katalog_engleski from '../assets/pdf/home/hs_en.pdf';
import katalog_bugarski from '../assets/pdf/home/hs-katalog-proizvoda-bug.pdf';
import katalog_makedonski from '../assets/pdf/home/hs-katalog-proizvoda-mk.pdf';
import katalog_spanski from '../assets/pdf/home/hs-katalog-proizvoda-spa.pdf';
import katalog_turski from '../assets/pdf/home/hs-katalog-proizvoda-turski.pdf';
import katalog_sladoljed from '../assets/pdf/home/hs_sq.pdf';



const pdf = {
    home: {
        katalog_proizvoda,
        katalog_arapski,
        katalog_nemacki,
        katalog_engleski,
        katalog_bugarski,
        katalog_makedonski,
        katalog_spanski,
        katalog_turski,
        katalog_sladoljed
    }
}

export default pdf;