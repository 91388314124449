import styled from 'styled-components';

export const Container = styled.div`
display: flex;
align-self: center;
flex-direction: row;
/* justify-content: 'flex-' */
width: 1000px;
height: 150px;
margin-top: 60px;
justify-content: space-between;
`;

export const Arrow = styled.div`
display: flex;
width: 50px;
height: 100%;
flex-direction: column;
justify-content: center;
`;
export const ContentFrame = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
overflow-x: hidden;
overflow-y: hidden;
width: 100%;
`;
export const Item = styled.img`
width: 15%;
margin-left: 2.5%;
margin-right: 2.5%;
:hover{
    /* position: relative; */
    /* z-index: 2; */
    /* width: auto; */
    /* height: 150%; */
    /* position: absolute; */
}
`;
export const Content = styled.div`
/* overflow-y: visible; */
position: relative;
display: flex;
flex-direction: row;
left: ${ props => props.pos + '%'};
`;
export const ArrowImage = styled.img`
width: auto;
height: 33%;
display: flex;
align-self: center;
transition: height 0.2s;
:hover{
    height: 38%;
    cursor: pointer;
}
`;